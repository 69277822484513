import { css, DefaultTheme } from 'styled-components';

import { Color, StyledColorUtil } from './types';

export const getVisualizationColor = (theme: DefaultTheme, index: number): string =>
  theme.dataVisualizationPalette[index % theme.dataVisualizationPalette.length];

/**
 * @deprecated Use `color()` from `@css/csds-components` instead.
 */
export const color: StyledColorUtil =
  (c) =>
  ({ theme }) =>
    css`
      color: ${theme.colors[c as Color] || c};
    `;
color.bg =
  (c) =>
  ({ theme }) =>
    css`
      background-color: ${theme.colors[c as Color] || c};
    `;
color.border =
  (c) =>
  ({ theme }) =>
    css`
      border-color: ${theme.colors[c as Color] || c};
    `;
color.fill =
  (c) =>
  ({ theme }) =>
    css`
      fill: ${theme.colors[c as Color] || c};
    `;
