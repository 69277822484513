import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { routingPaths } from '../../common/constants';
import MoreWhiteIcon from '../../components/icon/MoreWhiteIcon';
import React from "react";

interface BillHeadCardProps {
  totalUnpaidAmount: number;
  totalBalanceAmount: number;
}

const BillHeadCard: React.FC<BillHeadCardProps> = ({ totalBalanceAmount, totalUnpaidAmount }) => {
  const history = useHistory();
  return (
    <StyledWrapper>
      <LeftWrapper>
        <TotalTitle>{'余额总和'}</TotalTitle>
        <AmountWrapper onClick={() => history.push(routingPaths.account)}>
          <TotalAmount>
            {'￥'}
            {totalBalanceAmount}
          </TotalAmount>
          <MoreWrapper>
            <MoreWhiteIcon />
          </MoreWrapper>
        </AmountWrapper>
      </LeftWrapper>
      <MiddleWrapper />
      <RightWrapper>
        <TotalTitle>{'一共未缴清'}</TotalTitle>
        <AmountWrapper>
          <TotalAmount>
            {'￥'}
            {totalUnpaidAmount}
          </TotalAmount>
        </AmountWrapper>
      </RightWrapper>
    </StyledWrapper>
  );
};

export default BillHeadCard;

const StyledWrapper = styled.div`
  margin-top: 57px;
  background: #8361fd;
  border-radius: 10px;
  height: 98px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

const TotalAmount = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  color: #ffffff;
`;

const TotalTitle = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #d6d6d6;
`;

const AmountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MoreWrapper = styled.div`
  margin-left: 14px;
`;

const LeftWrapper = styled.div`
  width: 49%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MiddleWrapper = styled.div`
  width: 23px;
  height: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.33);
  transform: rotate(90deg);
`;

const RightWrapper = styled.div`
  width: 49%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
