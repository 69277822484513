import Axios, { AxiosResponse } from 'axios';

import appConfig from '../config/config';

export interface FileUploadResult {
  fileId: string;
  fileName: string;
  uploadUrl: string;
  imgUrl: string;
}

export const uploadFile = async (file: File): Promise<FileUploadResult> => {
  return await uploadOssFile(file).then((res) => {
    if (res.success) {
      return {
        fileId: file.name,
        fileName: file.name,
        uploadUrl: res.obj,
        imgUrl: res.obj,
      };
    } else {
      return {
        fileId: file.name,
        fileName: file.name,
        uploadUrl: '',
        imgUrl: '',
      };
    }
  });
};

export const uploadOssFile = async (file: File): Promise<UploadFileResponse> => {
  const formData = new FormData();
  formData.append('multipartFile', file);
  const response: AxiosResponse<UploadFileResponse> = await Axios({
    url: appConfig.FMS_API_URL + '/fms_css/upload/ossFile',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
  return response.data;
};
export interface UploadFileResponse {
  success: boolean;
  returnMsg: string;
  returnCode: number;
  obj: string;
}
