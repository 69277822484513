export interface SimpleContract {
  contractId: string;
  code: string;
  facilityCode: string;
  facilityName: string;
  kitchenCode: string;
  storeName: string;
  contractStatus: number;
  contractStatusName: string;
  entityType: number;
  entityTypeName: string;
}

export interface Contract {
  basic: ContractBasic;
  linkman?: ContractContact;
  bank?: ContractBank;
  contract: ContractInfo;
  kitchen?: ContractKitchen;
  operation?: ContractOperation;
  fee: ContractFee;
  pubCost?: Pair[];
  custom?: Pair[];
}

export interface ContractBasic {
  contractId: string;
  code: string;
  signType?: string;
  contractStatus: number;
  contractStatusName: string;
  facilityName: string;
  facilityCode: string;
  kitchenCode: string;
  storeName: string;
  entityType: number;
  entityTypeName: string;
  entity?: string;
  idCard?: string;
  comAddr?: string;
  linkAddr?: string;
}

export interface ContractInfo {
  servicePeriod?: string;
  licenseTypeName?: string;
  actualRentalDate?: string;
}

export interface ContractContact {
  name: string;
  phone: string;
  email?: string;
}

export interface ContractBank {
  name?: string;
  branch?: string;
  account?: string;
}

export interface ContractKitchen {
  address?: string;
  totalArea?: string;
  electricity?: string;
  exhaust?: string;
  gas?: string;
}

export interface ContractOperation {
  kitchenTypeName?: string;
  brandName?: string;
  cuisineTypeName?: string;
  storeName?: string;
}

export interface ContractFee {
  entry?: string;
  rent: string;
  siteUseServiceFee?: string;
  protect?: string;
  deposit?: string;
  quality?: string;
  cardCancelDeposit?: string;
  cardRegFee?: string;
  rentPaymentMonth: number;
}

export interface Pair {
  key: string;
  value: string;
}

export enum ContractEntityType {
  PERSONAL = 1,
  COMPANY = 2,
}

export enum ContractStatusEnum {
  IN_RESERVATION = 1,
  RESERVED = 2,
  INVALID = 3,
  LEASED = 4,
  TERMINATION = 5,
  REJECTED = 6,
  RENEWAL = 7,
  SIGNED_AND_NOT_PAID = 8,
  PENDING = 9,
  NEED_TO_SIGN_AND_PAYMENT = 10,
  PAID_AND_NOT_SIGNED = 11,
}

export const getDefaultContract: Contract = {
  basic: {
    contractId: '',
    code: '',
    signType: '',
    contractStatus: 4,
    contractStatusName: '',
    facilityName: '',
    facilityCode: '',
    kitchenCode: '',
    storeName: '',
    entityType: 1,
    entityTypeName: '',
  },
  contract: {
    servicePeriod: '',
    licenseTypeName: '',
    actualRentalDate: '',
  },
  fee: {
    rent: '',
    rentPaymentMonth: 1,
  },
};
