import moment from 'moment';
import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { BottomButtonGroup } from '../../components/BottomButtonGroup';
import { Dialog } from '../../components/Dialog';
import { DATETIME_YEAR_MONTH } from '../../utils/utils';

interface SelectDateDialogProps {
  title: string;
  date: string;
  selectDate(date: string): void;
  onClose: () => void;
}

const months = (): string[] => {
  const list: string[] = [];
  for (let i = 1; i <= 12; i++) {
    list.push(i + '月');
  }
  return list;
};

const years = (): string[] => {
  const list: string[] = [];
  const year = Number.parseInt(moment().format('YYYY'));
  for (let i = year - 10; i <= year; i++) {
    list.push(i.toString());
  }
  return list;
};

export const SelectDateDialog: React.FC<SelectDateDialogProps> = ({ title, date, selectDate, onClose }) => {
  const [year, setYear] = useState<string>(moment(date, DATETIME_YEAR_MONTH).format('YYYY'));
  const [month, setMonth] = useState<string>(moment(date, DATETIME_YEAR_MONTH).format('M') + '月');

  useEffect(() => {
    document?.getElementById(year)?.scrollIntoView({ behavior: 'auto', block: 'center' });

    document?.getElementById(month)?.scrollIntoView({ behavior: 'auto', block: 'center' });
  }, []);

  const submitSelected = useCallback(() => {
    selectDate(year + '-' + month.replace('月', ''));
    onClose();
  }, [year, month, selectDate, onClose]);

  return (
    <Dialog title={title} onClose={onClose}>
      <BodyWrapper>
        <ContentWrapper>
          {years().map((item: string) => (
            <Item selected={item === year} key={item} id={item} onClick={() => setYear(item)}>
              {item}
            </Item>
          ))}
        </ContentWrapper>
        <ContentWrapper>
          {months().map((item) => (
            <Item selected={item === month} key={item} id={item} onClick={() => setMonth(item)}>
              {item}
            </Item>
          ))}
        </ContentWrapper>
      </BodyWrapper>
      <SpaceBetween />
      <BottomButtonGroup
        primary={{
          text: '确认',
          onClick: submitSelected,
        }}
      />
    </Dialog>
  );
};

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 206px;
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: inset 0px 30px 30px -15px #ffffff;
`;

const ContentWrapper = styled.div`
  flex: 1 0 auto;
  height: 206px;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Item = styled.div<{ selected?: boolean }>`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  color: #141414;
  ${({ selected }) => selected && 'background: #F3F5F7;'};
  ${({ selected }) => selected && 'border-radius: 8px;'};
`;

const SpaceBetween = styled.div`
  display: block;
  height: 70px;
  width: 10px;
`;
