import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Input } from '../../components/op-components';
import { v1 as JSBClient } from '../../components/js-bridge';

import { routingPaths } from '../../common/constants';
import { mpTrack } from '../../common/mixPanel';
import { BlankBox } from '../../components/BlankBox';
import { BottomButtonGroup } from '../../components/BottomButtonGroup';
import ErrorScreen, { Error as PayPageError } from '../../components/ErrorScreen';
import { IconRadioGroup } from '../../components/IconRadioGroup';
import LoadingDialog from '../../components/LoadingDialog';
import { MobOverlayDialog } from '../../components/MobOverlayDialog';
import CloseBlackIcon from '../../components/icon/CloseBlackIcon';
import ErrorRedIcon from '../../components/icon/ErrorRedIcon';
import { AliPayIcon, WechatPayIcon } from '../../components/icon/PayIcons';
import { PayChannelEnum, PayModelEnum } from '../../models/pay';
import { prePay, queryPay } from '../../services/pay';
import { getFmsTokenFromStorage } from '../../services/user';
import { parseQueryString } from '../../utils/urlHelper';
import { sleep } from '../../utils/utils';
import { PayAllIcon, PaySplitIcon } from './PayTypeIcon';

const jsbc = new JSBClient();

export const PayPage: React.FC = () => {
  const [error, setError] = useState<PayPageError | undefined>(undefined);
  const [billId, setBillId] = useState<string>('');
  const [needPayAmount, setNeedPayAmount] = useState<number>(0);
  const [isSplit, setIsSplit] = useState<boolean>();
  const [payChannel, setPayChannel] = useState<PayChannelEnum>();
  const [splitAmount, setSplitAmount] = useState<number>(0);
  const [splitDialogOpen, setSplitDialogOpen] = useState<boolean>(false);
  const [payLoading, setPayLoading] = useState<boolean>(false);
  const [currencySymbol, setCurrencySymbol] = useState<string>('￥');
  const [utilityPrepay, setUtilityPrepay] = useState<boolean>(false);
  const [disableSplit, setDisableSplit] = useState<boolean>(false);
  // payment will be time out after 2 minutes by default
  const paymentTimeLimitMs = 2 * 60 * 1000;

  const history = useHistory();

  useEffect(() => {
    const params = parseQueryString();

    const paramNeedPayAmount = params.get('needPayAmount');
    if (paramNeedPayAmount) {
      try {
        setNeedPayAmount(parseFloat(paramNeedPayAmount));
      } catch (e) {
        setError({ type: 'BROKEN', message: '页面参数错误' });
      }
    } else {
      setError({ type: 'BROKEN', message: '页面参数错误' });
    }

    const paramBillId = params.get('billId');
    if (paramBillId) {
      setBillId(paramBillId);
    } else {
      setError({ type: 'BROKEN', message: '页面参数错误' });
    }

    // optional params
    setUtilityPrepay('true' === params.get('utilityPrepay'));
    setDisableSplit('true' === params.get('disableSplit'));
    setCurrencySymbol(decodeURIComponent(params.get('currencySymbol') || '') || '￥');

    console.log('utilityPrepay-params', params.get('utilityPrepay'));
  }, []);

  const queryPayment = useCallback(
    async (payDetailId: string, timeout: number) => {
      for (let i = 0; i < 200; i++) {
        if (Date.now() > timeout) {
          throw new Error('Payment timeout!');
        }
        const queryPayResponse = await queryPay({ billId: billId, payDetailId: payDetailId });
        if (queryPayResponse.payStatus === 3) {
          break;
        }
        await sleep(1000);
      }
    },
    [billId]
  );

  const pay = useCallback(() => {
    if (!billId || !needPayAmount || !payChannel) {
      return;
    }

    setPayLoading(true);

    if (payChannel === PayChannelEnum.WECHAT) {
      const path =
        `pages/payment/index?billId=${billId}&payModel=${
          PayModelEnum.ONLINE
        }&payChannel=${payChannel}&payAmount=${(isSplit ? splitAmount : needPayAmount).toFixed(2)}&isSplit=${
          isSplit ? 'Y' : 'N'
        }&utilityPrepay=${utilityPrepay}&token=` + getFmsTokenFromStorage();
      window.location.assign(`otter://open_wechat_mini_app?username=gh_03d731fcc27a&path=` + encodeURIComponent(path));
      sleep(2000).then(() => {
        window.location.href = document.referrer;
      });
    } else {
      prePay({
        billId,
        payAmount: `${(isSplit ? splitAmount : needPayAmount).toFixed(2)}`,
        isSplit: isSplit ? 'Y' : 'N',
        payModel: PayModelEnum.ONLINE,
        payChannel: payChannel,
        cssToken: getFmsTokenFromStorage(),
        source: 'otter',
        remark: utilityPrepay ? 'utilities-prepay' : '',
      })
        .then(async (res) => {
          console.log('prePay-res', JSON.stringify(res));
          console.log('billId: ', billId);
          console.log('payDetailId: ', res.payDetailId);

          const paymentMethodType =
            payChannel === PayChannelEnum.ALIPAY ? 'PAYMENT_METHOD_TYPE_ALI_PAY' : 'PAYMENT_METHOD_TYPE_WECHAT_PAY';
          const jsbResult = jsbc.launchPayment(paymentMethodType, JSON.stringify(res));
          console.log('launchPayment', JSON.stringify(jsbResult));

          const startWaitPaymentTime = Date.now();
          await queryPayment(res.payDetailId as string, startWaitPaymentTime + paymentTimeLimitMs);
          history.push(
            `${routingPaths.paySuccess}?payDetailId=${res.payDetailId || ''}&isSplit=${isSplit ? 'Y' : 'N'}`
          );
        })
        .catch((e) => {
          console.log('utilityPrepay', utilityPrepay);
          console.log('prePay-error', JSON.stringify(e));
          history.push(`${routingPaths.payFail}?errorMsg=${e.returnMsg || ''}`);
          mpTrack('error', { name: '支付失败', errorMsg: e.returnMsg || '' });
        });
    }
  }, [
    billId,
    needPayAmount,
    payChannel,
    isSplit,
    splitAmount,
    utilityPrepay,
    queryPayment,
    paymentTimeLimitMs,
    history,
  ]);

  const onSplitAmountSet = useCallback((amount: number) => {
    setSplitDialogOpen(false);
    setSplitAmount(amount);
  }, []);

  if (error) {
    return (
      <ErrorScreen message={error.message} errorType={error.type} fullScreen={true} retry={error.type === 'UNKNOWN'} />
    );
  }

  if (payLoading) {
    return <LoadingDialog />;
  }

  return (
    <PayContainer>
      <PayAmountBox>
        {currencySymbol}
        {isSplit ? splitAmount : needPayAmount}
      </PayAmountBox>
      <BillRestBox>
        {isSplit && needPayAmount && (
          <>
            <BillRestText>{'账单剩余：'}</BillRestText>
            <BillRestValue>
              {currencySymbol}
              {(needPayAmount - splitAmount).toFixed(2)}
            </BillRestValue>
          </>
        )}
      </BillRestBox>
      <BlankBox height="36px" />
      <div>
        {disableSplit || (
          <IconRadioGroup
            items={[
              {
                id: 'ALL',
                content: '全额支付',
                icon: <PayAllIcon />,
              },
              {
                id: 'SPLIT',
                content:
                  isSplit && splitAmount ? (
                    <SplitLabel>
                      <div>{'拆分支付'}</div>
                      <SplitLabelSubInfo>
                        <div>
                          本次支付{currencySymbol}
                          {splitAmount}
                        </div>
                        <ModifyText onClick={() => setSplitDialogOpen(true)}>修改</ModifyText>
                      </SplitLabelSubInfo>
                    </SplitLabel>
                  ) : (
                    '拆分支付'
                  ),
                icon: <PaySplitIcon />,
              },
            ]}
            selection={isSplit ? 'SPLIT' : 'ALL'}
            setSelection={(v: string) => {
              if (v === 'SPLIT') {
                if (!splitAmount) {
                  setSplitDialogOpen(true);
                }
                setIsSplit(true);
              } else {
                setIsSplit(false);
              }
            }}
          />
        )}
      </div>
      <BlankBox height="48px" />
      <div>
        <IconRadioGroup
          items={[
            {
              id: 'ALIPAY',
              content: '支付宝支付',
              icon: <AliPayIcon />,
            },
            {
              id: 'WECHAT',
              content: '微信支付',
              icon: <WechatPayIcon />,
            },
          ]}
          selection={getPayChannelString(payChannel)}
          setSelection={(v) => {
            switch (v) {
              case 'ALIPAY':
                setPayChannel(PayChannelEnum.ALIPAY);
                break;
              case 'WECHAT':
                setPayChannel(PayChannelEnum.WECHAT);
                break;
              default:
                setPayChannel(undefined);
            }
          }}
        />
      </div>
      <PayButton
        payChannel={payChannel}
        amount={isSplit ? splitAmount : needPayAmount}
        onClick={pay}
        currencySymbol={currencySymbol}
      />
      {splitDialogOpen && needPayAmount > 0 && (
        <SplitDialog
          onClose={() => {
            setSplitDialogOpen(false);
            splitAmount || setIsSplit(false);
          }}
          onOK={onSplitAmountSet}
          current={splitAmount}
          max={needPayAmount}
          currencySymbol={currencySymbol}
        />
      )}
    </PayContainer>
  );
};

const getPayChannelString = (payChannel: PayChannelEnum | undefined): string | undefined => {
  switch (payChannel) {
    case PayChannelEnum.ALIPAY:
      return 'ALIPAY';
    case PayChannelEnum.WECHAT:
      return 'WECHAT';
    default:
      return undefined;
  }
};

interface PayButtonProps {
  payChannel?: PayChannelEnum;
  amount?: number;
  onClick: () => void;
  currencySymbol: string;
}

const PayButton = ({ payChannel, amount, onClick, currencySymbol }: PayButtonProps) => {
  const [text, setText] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (!payChannel) {
      setText('请选择支付方式');
      setDisabled(true);
    } else if (!amount) {
      setText('支付');
      setDisabled(true);
    } else if (payChannel === PayChannelEnum.ALIPAY) {
      setText(`${'支付宝支付'} ${currencySymbol}${amount}`);
      setDisabled(false);
    } else if (payChannel === PayChannelEnum.WECHAT) {
      setText(`${'微信支付'} ${currencySymbol}${amount}`);
      setDisabled(false);
    }
  }, [payChannel, amount, currencySymbol]);

  return (
    <BottomButtonGroup
      primary={{
        text,
        onClick,
        disabled,
        mpAction: { name: 'pay', params: { channel: payChannel ? PayChannelEnum[payChannel] : '' } },
      }}
    />
  );
};

interface SplitDialogProps {
  onClose: () => void;
  onOK: (amount: number) => void;
  max: number;
  current: number;
  currencySymbol: string;
}

const SplitDialog = ({ onClose, onOK, max, current, currencySymbol }: SplitDialogProps) => {
  const [value, setValue] = useState<number | ''>(current);
  const [error, setError] = useState<string>('');
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newStrValue = e.target.value;
      if (!newStrValue) {
        setValue('');
        setError('');
        return;
      }
      const newValue = parseFloat(newStrValue);
      if (newValue > max || newValue <= 0) {
        setError(`请输入大于0，小于${max}的有效金额`);
        return;
      }
      setValue(Math.floor(newValue * 100) / 100);
      setError('');
    },
    [max]
  );
  return (
    <MobOverlayDialog onClose={onClose}>
      <SplitContentDiv>
        <SplitDialogHeader>
          <SplitDialogTitle>{'拆分支付'}</SplitDialogTitle>
          <SplitDialogCloseBut onClick={onClose}>
            <CloseBlackIcon />
          </SplitDialogCloseBut>
        </SplitDialogHeader>
        <SplitDialogBody>
          <BlankBox height="22px" />
          <Input
            prefix={<SplitInputPrefixDiv>{currencySymbol}</SplitInputPrefixDiv>}
            value={value}
            type="number"
            onChange={onChange}
            placeholder={'请输入本次支付金额'}
            intent={error ? 'error' : 'default'}
          />
          <SplitDialogInputErrorDiv>
            {error && (
              <ErrorIconDiv>
                <ErrorRedIcon />
              </ErrorIconDiv>
            )}
            {error}
          </SplitDialogInputErrorDiv>
          <BlankBox height="58px" />
        </SplitDialogBody>
        <BottomButtonGroup
          primary={{ text: '确认', onClick: () => onOK(value || 0), disabled: !value }}
          secondary={{ text: '取消', onClick: onClose }}
        />
      </SplitContentDiv>
    </MobOverlayDialog>
  );
};

const SplitDialogInputErrorDiv = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #c4232b;
  width: 100%;
  height: 14px;
  margin: 9px 0 17px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const SplitLabel = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const SplitLabelSubInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #525252;
`;

const ModifyText = styled.div`
  color: #cc5a3d;
  margin-left: 10px;
`;

const ErrorIconDiv = styled.div`
  margin-right: 6px;
`;

const SplitInputPrefixDiv = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  height: 20px;
  color: #8f8f8f;
`;

const SplitContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
`;

const SplitDialogBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const SplitDialogHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 16px;
`;

const SplitDialogTitle = styled.div`
  flex: 1 0 auto;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  height: 28px;
  color: #000000;
`;

const SplitDialogCloseBut = styled.div`
  flex: none;
  padding: 8px;
  margin-right: -4px;
`;

const PayContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #f9fafb;
  min-height: 100vh;
`;

const PayAmountBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 22px;
  margin: 32px 0 0 0;

  font-family: 'Inter', 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 22px;
  font-feature-settings: 'ss06' on;
  color: #000000;
`;

const BillRestBox = styled.div`
  text-align: center;
  margin: 22px auto;
  margin-bottom: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BillRestValue = styled.div`
  font-family: 'Inter';
  font-weight: 500;
  color: #27292b;
  font-size: 18px;
  line-height: 22px;
  height: 22px;
`;

const BillRestText = styled.div`
  height: 20px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  font-feature-settings: 'ss06' on;
`;
