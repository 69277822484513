const BellIcon: React.FC = () => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99984 17.125C6.08317 17.125 5.33317 16.375 5.33317 15.4583H8.6665C8.6665 16.375 7.92484 17.125 6.99984 17.125ZM1.99984 12.125V7.95833C1.99984 5.4 3.3665 3.25833 5.74984 2.69167V2.125C5.74984 1.43333 6.30817 0.875 6.99984 0.875C7.6915 0.875 8.24984 1.43333 8.24984 2.125V2.69167C10.6415 3.25833 11.9998 5.39167 11.9998 7.95833V12.125L13.6665 13.7917V14.625H0.333171V13.7917L1.99984 12.125Z"
        fill="white"
      />
    </svg>
  );
};
export default BellIcon;
