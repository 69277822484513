import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { routingPaths } from './common/constants';
import { mpTrackError } from './common/mixPanel';
import { AccountPage } from './pages/account';
import { PrivateRoute, SignInPage } from './pages/auth';
import { BillPage, BillWaitPayPage } from './pages/bill';
import { BillPageDetail } from './pages/bill/BillDetailPage';
import { ContractBasicPage, ContractDetailPage, ContractPreviewPage } from './pages/contract';
import { HomePage } from './pages/home';
import { MonitorDetailPage, MonitorReplayPage } from './pages/monitor';
import { OfflinePayPage } from './pages/pay/OfflinePayPage';
import { PayFailPage } from './pages/pay/PayFailPage';
import { PayPage } from './pages/pay/PayPage';
import { PaySuccessPage } from './pages/pay/PaySuccessPage';
import { UtilitiesPage } from './pages/utilities';
import { UtilitiesHistoryPage } from './pages/utilities/UtilitiesHistoryPage';
import { getFmsUser, setFmsUserProfileToStorage } from './services/user';
import {
  CSS_TOKEN_STORAGE_KEY,
  CSS_USER_STORAGE_KEY,
  getCssTokenFromLocalStorage,
  getCssUserIdFromStorage,
} from './utils/token';

const App: React.FC = () => {
  const [authToken, setAuthToken] = useState('');
  const [cssUserId, setCssUserId] = useState('');
  const authenticated = !!authToken;
  const [fmsAuthenticated, setFmsAuthenticated] = useState(false);

  useEffect(() => {
    const data = getCssTokenFromLocalStorage(CSS_TOKEN_STORAGE_KEY);
    if (data) {
      setAuthToken(data);
    } else {
      mpTrackError('getCssTokenFromLocalStorage', { error: 'Failed to getCssTokenFromLocalStorage by localStorage' });
    }
  }, []);

  useEffect(() => {
    const data = getCssUserIdFromStorage(CSS_USER_STORAGE_KEY);
    if (data) {
      setCssUserId(data);
    } else {
      mpTrackError('getCssUserIdFromStorage', { error: 'Failed to getCssUserIdFromStorage by localStorage' });
    }
  }, []);

  useEffect(() => {
    if (cssUserId && authToken) {
      getFmsUser(cssUserId, authToken)
        .then((res) => {
          setFmsUserProfileToStorage(res);
          setFmsAuthenticated(true);
        })
        .catch((res) => {
          mpTrackError('getFmsUser', { error: JSON.stringify(res) });
        });
      mixpanel.identify(cssUserId);
    }
  }, [cssUserId, authToken]);

  return (
    <div className="App">
      <Router>
        <Switch>
          <PrivateRoute
            path={routingPaths.utilitiesHistory}
            authenticated={authenticated}
            fmsAuthenticated={fmsAuthenticated}
          >
            <UtilitiesHistoryPage />
          </PrivateRoute>
          <PrivateRoute path={routingPaths.utilities} authenticated={authenticated} fmsAuthenticated={fmsAuthenticated}>
            <UtilitiesPage />
          </PrivateRoute>
          <PrivateRoute path={routingPaths.account} authenticated={authenticated} fmsAuthenticated={fmsAuthenticated}>
            <AccountPage />
          </PrivateRoute>
          <PrivateRoute
            path={routingPaths.billDetail}
            authenticated={authenticated}
            fmsAuthenticated={fmsAuthenticated}
          >
            <BillPageDetail />
          </PrivateRoute>
          <PrivateRoute path={routingPaths.bill} authenticated={authenticated} fmsAuthenticated={fmsAuthenticated}>
            <BillPage />
          </PrivateRoute>
          <PrivateRoute
            path={routingPaths.billWaitPay}
            authenticated={authenticated}
            fmsAuthenticated={fmsAuthenticated}
          >
            <BillWaitPayPage />
          </PrivateRoute>
          <PrivateRoute
            path={routingPaths.previewContract}
            authenticated={authenticated}
            fmsAuthenticated={fmsAuthenticated}
          >
            <ContractPreviewPage />
          </PrivateRoute>
          <PrivateRoute
            path={routingPaths.contractBasicDetail}
            authenticated={authenticated}
            fmsAuthenticated={fmsAuthenticated}
          >
            <ContractBasicPage />
          </PrivateRoute>
          <PrivateRoute
            path={routingPaths.contractDetail}
            authenticated={authenticated}
            fmsAuthenticated={fmsAuthenticated}
          >
            <ContractDetailPage />
          </PrivateRoute>
          <PrivateRoute
            path={routingPaths.monitorDetail}
            authenticated={authenticated}
            fmsAuthenticated={fmsAuthenticated}
          >
            <MonitorDetailPage />
          </PrivateRoute>
          <PrivateRoute
            path={routingPaths.monitorReplay}
            authenticated={authenticated}
            fmsAuthenticated={fmsAuthenticated}
          >
            <MonitorReplayPage />
          </PrivateRoute>
          <PrivateRoute path={routingPaths.pay} authenticated={authenticated} fmsAuthenticated={fmsAuthenticated}>
            <PayPage />
          </PrivateRoute>
          <PrivateRoute
            path={routingPaths.offlinePay}
            authenticated={authenticated}
            fmsAuthenticated={fmsAuthenticated}
          >
            <OfflinePayPage />
          </PrivateRoute>
          <PrivateRoute
            path={routingPaths.paySuccess}
            authenticated={authenticated}
            fmsAuthenticated={fmsAuthenticated}
          >
            <PaySuccessPage />
          </PrivateRoute>
          <PrivateRoute path={routingPaths.payFail} authenticated={authenticated} fmsAuthenticated={fmsAuthenticated}>
            <PayFailPage />
          </PrivateRoute>
          <PrivateRoute path={routingPaths.home} authenticated={authenticated} fmsAuthenticated={fmsAuthenticated}>
            <HomePage />
          </PrivateRoute>
          <Route
            exact
            path={routingPaths.login}
            render={() => {
              return authenticated ? null : <SignInPage />;
            }}
          />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
