import styled from 'styled-components';

import { RadioOffIcon, RadioOnIcon } from './icon/Radio';
import React from "react";

interface RadioProps {
  checked: boolean;
}

export const Radio: React.FC<RadioProps> = ({ checked }) => {
  return checked ? <RadioOnIcon /> : <RadioOffIcon />;
};

interface IconRadioItemProps {
  icon?: React.ReactNode;
  content: React.ReactNode | string;
  checked: boolean;
  onClick: () => void;
}

export const IconRadioItem: React.FC<IconRadioItemProps> = ({ icon, content, checked, onClick }) => {
  return (
    <IconRadioItemDiv onClick={onClick}>
      <IconRadioItemInnerDiv>
        <ItemLeftDiv>
          <RadioItemIcon>{icon}</RadioItemIcon>
          <RadioItemContent>{content}</RadioItemContent>
        </ItemLeftDiv>
        <RadioDiv>
          <Radio checked={checked} />
        </RadioDiv>
      </IconRadioItemInnerDiv>
    </IconRadioItemDiv>
  );
};

const IconRadioItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 100vw;
  background: #ffffff;
`;

const IconRadioItemInnerDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px;
  padding: 13px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  width: calc(100vw - 32px);
  flex: auto;
`;

const ItemLeftDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1 0 auto;
`;

const RadioItemIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const RadioItemContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 12px;

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`;

const RadioDiv = styled.div`
  padding: 12px;
  flex: none;
`;

interface IconRadioGroupSubItemProps {
  id: string;
  icon?: React.ReactNode;
  content: React.ReactNode | string;
}

interface IconRadioGroupProps {
  items: IconRadioGroupSubItemProps[];
  selection?: string;
  setSelection: (toBeSelected: string) => void;
}

export const IconRadioGroup: React.FC<IconRadioGroupProps> = ({ items, selection, setSelection }) => {
  return (
    <>
      {items.map((item) => (
        <IconRadioItem
          key={item.id}
          icon={item.icon}
          content={item.content}
          checked={item.id === selection}
          onClick={() => setSelection(item.id)}
        />
      ))}
    </>
  );
};
