import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import iconBroken from '../assets/images/icon-broken.png';
import iconUnknown from '../assets/images/icon-unknown.png';
import { mpTrackError } from '../common/mixPanel';

export interface Error {
  type: errorType;
  message?: string;
}

interface ErrorScreenProps {
  errorType?: errorType;
  title?: string;
  message?: string;
  retry?: boolean;
  onRetry?: () => void;
  fullScreen?: boolean;
}

export type errorType = 'EMPTY' | 'BROKEN' | 'UNKNOWN';

const ErrorScreen: React.FC<ErrorScreenProps> = ({ title, message, errorType, retry, onRetry, fullScreen }) => {
  useEffect(() => {
    mpTrackError(title || message || 'error', { message, errorType });
  }, [errorType, message, title]);
  const errorIcon = useMemo(() => {
    if (errorType === 'EMPTY') {
      return <StyledIcon src={iconBroken} />;
    } else if (errorType === 'BROKEN') {
      return <StyledIcon src={iconBroken} />;
    } else {
      return <StyledIcon src={iconUnknown} />;
    }
  }, [errorType]);

  return (
    <StyledWrapper fullScreen={fullScreen || false}>
      <IconWrapper fullScreen={fullScreen || false}>{errorIcon}</IconWrapper>
      {title && <StyledTitle>{title}</StyledTitle>}
      {message && <StyledMessage>{message}</StyledMessage>}
      {retry && <StyledRetryButton onClick={onRetry}>{'重试'}</StyledRetryButton>}
    </StyledWrapper>
  );
};

export default ErrorScreen;

const StyledWrapper = styled.div<{ fullScreen: boolean }>`
  width: 100vw;
  ${({ fullScreen }) => fullScreen && 'height: 100vh;'}
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div<{ fullScreen: boolean }>`
  padding: ${({ fullScreen }) => (fullScreen ? 0 : 50)}px 50px 40px 50px;
`;

const StyledIcon = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;

const StyledTitle = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  font-feature-settings: 'ss06' on;
  color: #000000;
`;

const StyledMessage = styled.div`
  margin-top: 12px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  font-feature-settings: 'ss06' on;
  color: rgba(22, 24, 35, 0.75);
`;

const StyledRetryButton = styled.div`
  margin-top: 40px;
  width: 200px;
  height: 52px;
  background: rgba(255, 255, 255, 0.0001);
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'ss06' on;
  color: #000000;
`;
