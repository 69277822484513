import { RefCallback, useCallback, useRef, useState } from 'react';

/**
 * In addition to being set to null around mounting, refs can also get reassigned to different DOM
 * nodes (for example, when a single row is removed from a list). If you need to re-execute some
 * logic whenever the reference changes, using a ref callback is handy.
 *
 * @param customCallback
 * @returns [refCallback, ref]
 */
export const useRefCallback = <Element extends HTMLElement = HTMLElement>(
  customCallback?: RefCallback<Element>
): [RefCallback<Element>, Element | null] => {
  const previousRef = useRef<Element | null>(null);
  // We store the ref in state to allow consuming components to react when the underlying node
  // reference changes via a useEffect hook with a `ref` dependency. See useClientRect for an
  // example.
  const [ref, setRef] = useState<Element | null>(null);

  const refCallback = useCallback(
    (node: Element | null) => {
      if (node !== previousRef?.current) {
        setRef(node);
        previousRef.current = node;
      }
      customCallback?.(node);
    },
    [customCallback]
  );

  return [refCallback, ref];
};
