import { ResizeObserverEntry } from '@juggle/resize-observer';

// NOTE: Support for non-content-box modes wasn't added to desktop Safari until 15.4 (still in beta
// as of 3/3/2022) and hasn’t landed in mobile Safari at all. So let's check for the
// non-content-box implementation and use the polyfill if necessary (i.e. in Safari).
export const supportsResizeObserver = (): Promise<boolean> => {
  if (!window.ResizeObserver) {
    return Promise.resolve(false);
  }

  return new Promise((resolve) => {
    const observer = new window.ResizeObserver((entries) => {
      // Using the polyfill's type because `devicePixelContentBoxSize` was added to TypeScript in
      // version 4.4.2 and we use 4.3.5
      resolve((entries[0] as ResizeObserverEntry).devicePixelContentBoxSize !== undefined);
    });
    const htmlEl = document.querySelector('html');
    if (htmlEl !== null) {
      observer.observe(htmlEl);
    }
  });
};
