import styled, { css } from 'styled-components';

import { border, borderRadius, boxShadow, color, mediaQuery, padding } from '../../../../theme';
import { Box } from '../../../layouts/Box';
import { fontSize, fontWeight } from '../../Text';
import { InputIntent, InputSize, InputVariant } from '../types';

export const StyledInputWrapper = styled(Box)`
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
`;

export const StyledInputAffixWrapper = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 20px;
  position: absolute;
`;

export const inputBorderStyles = css<{ $disabled?: boolean; $intent: InputIntent }>`
  outline: none;
  ${border('1px', 'ashDark')}
  ${borderRadius('lg')}

  &:focus-within {
    ${border('1px', 'greyDark')}
  }

  &:not(:focus-within) {
    // on-hover for itself and all children
    &:hover,
    ${StyledInputWrapper}:hover > & {
      // mouse hover but no focus
      ${border('1px', 'greyLight')}
      ${boxShadow('md')}
    }
  }

  ${({ $intent }) =>
    $intent === 'error' &&
    css`
      border-color: ${(props) => props.theme.colors.red} !important;
    `}
  ${({ $intent }) =>
    $intent === 'warning' &&
    css`
      border-color: ${(props) => props.theme.colors.yellow} !important;
    `}

  // Because non-input elements sometimes use these same border styles, we support both the
  // disabled pseudo-class and a prop
  &:disabled {
    border-color: ${(props) => props.theme.colors.ashDark} !important;
    box-shadow: ${(props) => props.theme.shadows.none} !important;
  }
  ${({ $disabled }) =>
    $disabled === true &&
    css`
      border-color: ${(props) => props.theme.colors.ashDark} !important;
      box-shadow: ${(props) => props.theme.shadows.none} !important;
    `};
`;

export const inputColorStyles = css`
  caret-color: ${(props) => props.theme.colors.orange};
  ${color('greyDark')}
  ${color.bg('white')}

  &::placeholder {
    ${color('greyLighter')}
  }

  &:disabled {
    ${color('greyLight')}
    ${color.bg('ashLight')}

    &::placeholder {
      ${color('greyLight')}
    }
  }
`;

export const inputTextStyles = css<{ $disabled?: boolean }>`
  line-height: 20px;
  ${fontWeight('medium')}

  // This should be at least 16px to prevent iOS Safari from zooming in on focus.
  ${fontSize('paragraph')}
  ${mediaQuery('$6')} {
    ${fontSize('small')}
  }

  &::placeholder {
    ${fontWeight('normal')}
  }

  ${({ $disabled }) =>
    $disabled === true &&
    css`
      ${color('greyLight')}
    `};
`;

export const StyledInput = styled.input<{
  $intent: InputIntent;
  $size: InputSize;
  $variant: InputVariant;
  $disableNumberArrows?: boolean;
}>`
  appearance: none;
  ${inputBorderStyles}
  ${inputColorStyles}
  ${inputTextStyles}

  ${padding.x('$3')}
  ${padding.y('9px')}
  width: 100%;

  &:disabled {
    cursor: not-allowed;
  }

  ${(props) =>
    props.$size === 'small' &&
    css`
      padding-bottom: 5px;
      padding-top: 5px;
    `}

  ${(props) =>
    props.$variant === 'minimal' &&
    css`
      background: transparent;
      border-color: transparent;
      padding-left: 0;
      padding-right: 0;

      &:focus-within,
      &:hover,
      ${StyledInputWrapper}:hover > & {
        border-color: transparent !important;
        box-shadow: none !important;
      }
    `}

  ${({ $disableNumberArrows }) =>
    $disableNumberArrows &&
    css`
      ::-webkit-inner-spin-button,
      ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &[type='number'] {
        -moz-appearance: textfield;
      }
    `}
`;
