export interface FacilityBillDetail {
  billList: Bill[];
  historyBillList: Bill[];
  totalUnpaidAmount: number;
  totalBalanceAmount: number;
}

export interface Bill {
  id: string;
  code: string;
  billType: string;
  billTypeName: string;
  tenantName?: string;
  storeName?: string;
  facilityName?: string;
  kitchenCode?: string;
  payStatus: number;
  confirmStatus: number;
  cycleEndDate?: string;
  cycleStartDate?: string;
  payCycleEndDate?: string;
  payCycleStartDate?: string;
  amount: string;
  lateFee?: string;
  costList?: BillCost[];
  paymentList?: PaymentRecord[];
  totalPaymentAmount: string;
  tbcAmount: string;
  remainAmount: string;
  contractId: string;
  contractCode: string;
  isCanPay: true;
  cannotPayMessage?: string;
}

export interface PaymentRecord {
  payChannel: number;
  payDetail: string;
  payAmount: string;
  status: number;
  statusName: string;
  payTime: Date;
}

export interface BillCost {
  id: string;
  code: string;
  type: number;
  typeName: string;
  handleType: number;
  isPublic?: string;
  initialStageNum?: string;
  lastStageNum?: string;
  cycleStartTime?: string;
  cycleEndTime?: string;
  usage?: string;
  price?: string;
  description?: string;
  amount: string;
}

export enum PayStatus {
  UNKNOWN = 0,
  NOT_PAID = 1,
  PARTIAL_PAID = 2,
  FULLY_PAID = 3,
  PAYING_PENDING_APPROVE_PAYMENT = 4,
}

export enum ConfirmStatus {
  UNKNOWN = 0,
  TO_BE_CONFIRM = 1,
  SYSTEM_CONFIRM = 2,
  TENANT_MANUAL = 3,
  SIGN_DELAY = 4,
}

export enum BillCostType {
  UNKNOWN = 0,
  WATER_FEE = 1,
  ELECTRICITY_FEE = 2,
  GAS_FEE = 3,
  LICENSE_FEE_BASE = 4,
}

export const defaultFacilityBillDetail: FacilityBillDetail = {
  billList: [],
  historyBillList: [],
  totalUnpaidAmount: 10000.01,
  totalBalanceAmount: 10000.01,
};

export const defaultBill: Bill = {
  id: '',
  code: '',
  billType: '',
  billTypeName: '',
  payStatus: 1,
  confirmStatus: 1,
  amount: '',
  totalPaymentAmount: '',
  tbcAmount: '',
  remainAmount: '',
  contractId: '',
  contractCode: '',
  isCanPay: true,
};
