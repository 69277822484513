import React from 'react';
import styled from 'styled-components';

import { UtilitiesBalanceAccountHistory, UtilitiesBalanceAccountHistoryType } from '../../models/account';
import { formatMoney, moneyToNumber, toLocalDateTime } from '../../utils/utils';

interface UtilitiesHistoryCardProps {
  history: UtilitiesBalanceAccountHistory;
}

const getHistoryTypeString = (historyType: string): string | undefined => {
  switch (historyType) {
    case UtilitiesBalanceAccountHistoryType.RECHARGE:
      return '充值记录';
    case UtilitiesBalanceAccountHistoryType.DAILY_COST:
      return '扣款记录';
    case UtilitiesBalanceAccountHistoryType.ALL:
      return '全部记录';
    default:
      return undefined;
  }
};

export const UtilitiesHistoryCard: React.FC<UtilitiesHistoryCardProps> = ({ history }) => {
  return (
    <AccountHistoryCard>
      <AccountHistoryTypeWrapper>
        <HistoryType>{getHistoryTypeString(history.type)}</HistoryType>
        <HistoryTime>{toLocalDateTime(history.timestamp)}</HistoryTime>
      </AccountHistoryTypeWrapper>
      <HistoryAmount>
        {history.type === UtilitiesBalanceAccountHistoryType.DAILY_COST && '-'}
        {'￥'}
        {formatMoney(moneyToNumber(history.value))}
      </HistoryAmount>
    </AccountHistoryCard>
  );
};

const AccountHistoryCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 42px;
  margin-bottom: 20px;
`;

const AccountHistoryTypeWrapper = styled.div``;

const HistoryType = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-feature-settings: 'ss06' on;
  color: #1a1a1a;
`;

const HistoryTime = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #707070;
`;

const HistoryAmount = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #141414;
`;
