import { fmsFetchData } from '../common/fmsApiRequest';
import {
  GenerateUrlSchemeRequest,
  GenerateUrlSchemeResponse,
  PrePayRequest,
  PrepayResponse,
  QueryPayRequest,
  QueryPayResponse,
} from '../models/pay';

export const prePay = async (req: PrePayRequest): Promise<PrepayResponse> => {
  return fmsFetchData<PrepayResponse>({
    url: '/merchant_css/netPay/prePay',
    method: 'POST',
    params: { ...req, ...{ source: 'otter' } },
  });
};

export const queryPay = async (req: QueryPayRequest): Promise<QueryPayResponse> => {
  return fmsFetchData<QueryPayResponse>({
    url: '/merchant_css/netPay/query',
    method: 'GET',
    params: { ...req },
  });
};

export const generateUrlScheme = async (req: GenerateUrlSchemeRequest): Promise<GenerateUrlSchemeResponse> => {
  return fmsFetchData<GenerateUrlSchemeResponse>({
    url: '/merchant_css/wechat/generateUrlScheme',
    method: 'POST',
    params: { ...req },
  });
};
