import { JSBBase } from './base';
import type { JSBIBase } from './base';

type PaymentMethod =
  | 'PAYMENT_METHOD_TYPE_ALIPAY_MINIAPP'
  | 'PAYMENT_METHOD_TYPE_ALI_PAY'
  | 'PAYMENT_METHOD_TYPE_WECHAT_PAY';

type V1Methods = {
  getUserToken(): Promise<string | undefined>;
  getFacilityId(): Promise<string | undefined>;
  getVasStoreId(): Promise<string | undefined>;
  getUserId(): Promise<string | undefined>;
  downloadBase64Image(base64: string): Promise<void> | undefined;
  launchPayment(paymentMethodType: PaymentMethod, payload: string): void;
  downloadImageFromUrl(url: string): Promise<void> | undefined;
};

export type JSBI = JSBIBase<V1Methods>;

declare global {
  interface Window {
    __jsbi__: JSBI;
  }
}

export class JSBClient extends JSBBase implements V1Methods {
  public bridgeVersion: '1' = '1';
  protected jsbi: JSBI = window.__jsbi__;

  constructor() {
    super({ bridgeVersion: '1' });
  }

  getUserToken() {
    return window.__jsbi__?.getUserToken();
  }

  getFacilityId() {
    return window.__jsbi__?.getFacilityId();
  }

  getVasStoreId() {
    return window.__jsbi__?.getVasStoreId();
  }

  getUserId() {
    return window.__jsbi__?.getUserId();
  }

  downloadImageFromUrl(url: string) {
    return window.__jsbi__?.downloadImageFromUrl(url);
  }

  downloadBase64Image(base64: string) {
    return window.__jsbi__?.downloadBase64Image(base64);
  }

  launchPayment(paymentMethodType: PaymentMethod, payload: string) {
    return window.__jsbi__?.launchPayment(paymentMethodType, payload);
  }
}
