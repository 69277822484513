import styled from 'styled-components';
import Headline from '../../components/Headline';
import React from "react";

interface HomeCardProps {
  title: string;
}

const HomeCard: React.FC<HomeCardProps> = ({ title, children }) => {
  return (
    <StyledWrapper>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </StyledWrapper>
  );
};

export default HomeCard;

const StyledWrapper = styled.div`
  width: 100%;
  padding-top: 40px;
  box-sizing: border-box;
`;

const Title = styled(Headline)`
  margin-left: 16px;
`;

const Content = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
`;
