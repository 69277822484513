import styled from 'styled-components';
import { DeviceStatus, Monitor } from '../../models/monitor';
import PlayIcon from '../../components/icon/PlayIcon';
import { useHistory } from 'react-router';
import { FmsContext, setMonitorAction } from '../../context/FmsContext';
import React, { useContext } from 'react';
import { routingPaths } from '../../common/constants';

interface HomeMonitorCardProps {
  monitor: Monitor;
  narrow: boolean;
  lastElement: boolean;
}

const HomeMonitorCard: React.FC<HomeMonitorCardProps> = ({ monitor, narrow, lastElement }) => {
  const history = useHistory();
  const { dispatch } = useContext(FmsContext);

  const navigateMonitorDetail = (value: Monitor) => {
    dispatch(setMonitorAction(value));
    history.push(routingPaths.monitorDetail);
  };

  return (
    <StyledWrapper narrow={narrow} lastElement={lastElement}>
      <HeadWrapper>
        <State state={monitor.deviceStatus === DeviceStatus.ACTIVE} />
        <Title>{monitor.storeName}</Title>
        <UpdatedAt>{monitor.updatedAt}</UpdatedAt>
      </HeadWrapper>
      <MonitorWrapper picUrl={monitor.channelPicUrl || ''} onClick={() => navigateMonitorDetail(monitor)}>
        <PlayIcon />
      </MonitorWrapper>
    </StyledWrapper>
  );
};

export default HomeMonitorCard;

const StyledWrapper = styled.div<{ narrow: boolean; lastElement: boolean }>`
  width: ${({ narrow }) => (narrow ? '80%' : 'calc(100% - 32px)')};
  height: 179px;
  background: #ffffff;
  border-radius: 12px;
  margin: 24px ${({ lastElement }) => (lastElement ? 16 : 0)}px 26px 16px;
  flex: none;
`;

const HeadWrapper = styled.div`
  margin-left: 16px;
  height: 39px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MonitorWrapper = styled.div<{ picUrl: string }>`
  width: 100%;
  height: 140px;
  background: url(${({ picUrl }: { picUrl: string }) => (picUrl.length > 0 ? picUrl : '')}) no-repeat 0 1px;
  background-size: 100% auto;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const State = styled.div<{ state: boolean }>`
  width: 6px;
  height: 6px;
  background: ${({ state }: { state: boolean }) => (state ? '#36BF36' : '#FF0000')};
  border: 2px solid #e7f8e7;
  border-radius: 10px;
`;

const Title = styled.div`
  margin-left: 10px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 10px;
  color: #000000;
`;

const UpdatedAt = styled.div`
  margin-left: auto;
  margin-right: 16px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 10px;
  letter-spacing: 0.01em;
  color: #a3a3a3;
`;
