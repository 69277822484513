import styled from 'styled-components';

const Headline = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #000000;
`;
export default Headline;
