import React, { useState } from 'react';
import styled from 'styled-components';

import { Document, Page, pdfjs } from 'react-pdf';
// This is a workaround so that the file loads, documented here: https://github.com/wojtekmaj/react-pdf/issues/321
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface PDFViewerProps {
  file?: string;
}

const DocumentView = styled(Document)`
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
`;

export const PDFViewer: React.FC<PDFViewerProps> = ({ file }) => {
  const [numPages, setNumPages] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }
  return (
    <DocumentView
      file={file}
      onLoadSuccess={onDocumentLoadSuccess}
      options={{
        // This is fixed Chinese garbled issue.
        cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
        cMapPacked: true,
      }}
    >
      {Array.from({ length: numPages }, (_, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          loading={''}
          width={window.innerWidth}
          height={window.innerHeight}
        />
      ))}
    </DocumentView>
  );
};
