import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import ErrorScreen, { Error } from '../../components/ErrorScreen';
import LoadingScreen from '../../components/LoadingScreen';
import { FmsContext } from '../../context/FmsContext';
import { ContractEntityType, ContractStatusEnum, SimpleContract } from '../../models/contract';
import { Monitor } from '../../models/monitor';
import { getContractList } from '../../services/contract';
import { getMonitorList } from '../../services/monitor';
import { reload } from '../../utils/utils';
import ContractSignPage from '../contract/sign/ContractSignPage';
import HomeCard from './HomeCard';
import HomeContractCard from './HomeContractCard';
import HomeHead from './HomeHead';
import HomeMonitorCard from './HomeMonitorCard';

export const HomePage: React.FC = () => {
  const {
    state: { facilityId },
  } = useContext(FmsContext);
  const [contractList, setContractList] = useState<SimpleContract[] | undefined>(undefined);
  const [monitorList, setMonitorList] = useState<Monitor[] | undefined>(undefined);
  const [kitchenTotal, setKitchenTotal] = useState<string>('');
  const [showSignContractList, setShowSignContractList] = useState<SimpleContract[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const showSignPage = (contractStatus: number, entityType: number): boolean => {
    return contractStatus === ContractStatusEnum.NEED_TO_SIGN_AND_PAYMENT && entityType === ContractEntityType.PERSONAL;
  };

  useEffect(() => {
    setLoading(true);
    getContractList(facilityId)
      .then((contracts) => {
        if (contracts) {
          setContractList(contracts);
        } else {
          setError({ type: 'EMPTY', message: '当前门店暂无合同' });
        }
      })
      .catch((res) => {
        setError({ type: 'UNKNOWN', message: res.message });
      })
      .finally(() => {
        setLoading(false);
      });

    getMonitorList(facilityId)
      .then((monitors) => {
        if (monitors) {
          setMonitorList(monitors);
        } else {
          setError({ type: 'EMPTY', message: '当前门店暂无监控' });
        }
      })
      .catch((res) => {
        setError({ type: 'UNKNOWN', message: res.message });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [facilityId, setContractList, setMonitorList, setLoading, setError]);

  useEffect(() => {
    if (contractList !== undefined) {
      setKitchenTotal(contractList.length + '个厨房');
    }
  }, [setKitchenTotal, contractList]);

  useEffect(() => {
    if (contractList) {
      const showSignContracts = contractList.filter((contract) =>
        showSignPage(contract.contractStatus, contract.entityType)
      );
      setShowSignContractList(showSignContracts);
    }
  }, [setShowSignContractList, contractList]);

  if (showSignContractList && showSignContractList.length > 0) {
    return <ContractSignPage contractList={showSignContractList} />;
  }

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <ErrorScreen
        message={error.message}
        errorType={error.type}
        fullScreen={true}
        retry={error.type === 'UNKNOWN'}
        onRetry={reload}
      />
    );
  }

  return (
    <StyledWrapper>
      <HomeHead title={'当前门店'} content={kitchenTotal} />
      <HomeWrapper>
        <HomeCard title={'我的合同'}>
          {(contractList || []).map((contract: SimpleContract, index: number) => (
            <HomeContractCard
              key={index}
              contract={contract}
              narrow={(monitorList || []).length > 1}
              lastElement={contractList?.length === index + 1}
            />
          ))}
        </HomeCard>
        {monitorList && (
          <HomeCard title={'我的监控'}>
            {(monitorList || []).map((monitor: Monitor, index: number) => (
              <HomeMonitorCard
                key={index}
                monitor={monitor}
                narrow={(monitorList || []).length > 1}
                lastElement={monitorList?.length === index + 1}
              />
            ))}
          </HomeCard>
        )}
      </HomeWrapper>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(175.4deg, #ffffff 5.08%, #f3f4f7 26.68%);
`;

const HomeWrapper = styled.div`
  width: 100%;
  margin-top: -20px;
  height: ${() => window.innerHeight - 206}px;
  z-index: 1;
  background: linear-gradient(175.4deg, #ffffff 5.08%, #f3f4f7 26.68%);
  border-radius: 12px;
`;
