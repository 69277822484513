import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { routingPaths } from '../../common/constants';
import { BottomButtonGroup } from '../../components/BottomButtonGroup';
import ErrorScreen, { Error } from '../../components/ErrorScreen';
import LoadingScreen from '../../components/LoadingScreen';
import MoreGrayIcon from '../../components/icon/MoreGrayIcon';
import {
  FmsContext,
  setUtilitiesBalanceAccountAction,
  setUtilitiesBalanceAccountListAction,
} from '../../context/FmsContext';
import { defaultUtilitiesBalanceAccount, UtilitiesBalanceAccount } from '../../models/account';
import { SimpleContract } from '../../models/contract';
import { getUtilitiesBalanceAccountList } from '../../services/account';
import { getContractList } from '../../services/contract';
import { reload } from '../../utils/utils';
import { CustomRechargeDialog } from './CustomRechargeDialog';
import { UtilitiesBalanceCard } from './UtilitiesBalanceCard';
import { UtilitiesHead } from './UtilitiesHead';

const utilitiesRechargeList = [200, 400, 800, 1000];

const parseUtilitiesBalanceAccountList = (
  utilitiesBalanceAccountList: UtilitiesBalanceAccount[],
  contractList: SimpleContract[]
): UtilitiesBalanceAccount[] => {
  const list: UtilitiesBalanceAccount[] = [];
  utilitiesBalanceAccountList.forEach((account) => {
    contractList.forEach((contract) => {
      if (account.contractId.toString() === contract.contractId.toString()) {
        list.push(
          Object.assign(account, {
            facilityCode: contract.facilityCode,
            facilityName: contract.facilityName,
            kitchenCode: contract.kitchenCode,
            storeName: contract.storeName,
          })
        );
      }
    });
  });
  return list;
};

export const UtilitiesPage: React.FC = () => {
  const history = useHistory();
  const {
    dispatch,
    state: { facilityId },
  } = useContext(FmsContext);
  const [utilitiesBalanceAccount, setUtilitiesBalanceAccount] =
    useState<UtilitiesBalanceAccount>(defaultUtilitiesBalanceAccount);
  const [rechargeAmount, setRechargeAmount] = useState<number>(0);
  const [showCustomRecharge, setShowCustomRecharge] = useState<boolean>();
  const rechargeButtonWidth = (window.innerWidth - 24 * 2 - 8 * 2 - 6) / 3;
  const [contractList, setContractList] = useState<SimpleContract[] | undefined>(undefined);
  const [utilitiesBalanceAccountList, setUtilitiesBalanceAccountList] = useState<UtilitiesBalanceAccount[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    setLoading(true);
    getContractList(facilityId)
      .then((contracts) => {
        if (contracts) {
          setContractList(contracts);
        } else {
          setError({ type: 'EMPTY', message: '当前门店暂无合同' });
        }
      })
      .catch((res) => {
        setError({ type: 'UNKNOWN', message: res.message });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [facilityId, setContractList, setLoading, setError]);

  useEffect(() => {
    if (contractList) {
      setLoading(true);
      const contractIds = contractList.map((value) => {
        return value.contractId;
      });
      getUtilitiesBalanceAccountList(contractIds, 'CONTRACT_ID')
        .then((res) => {
          if (res && res.length > 0) {
            setUtilitiesBalanceAccountList(parseUtilitiesBalanceAccountList(res, contractList));
          } else {
            setError({ type: 'EMPTY', message: '当前门店暂无水电费账号' });
          }
        })
        .catch((res) => {
          setError({ type: 'EMPTY', message: res.message });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [setUtilitiesBalanceAccountList, contractList, setLoading, setError]);

  useEffect(() => {
    if (utilitiesBalanceAccountList) {
      setUtilitiesBalanceAccount(utilitiesBalanceAccountList[0]);
    }
  }, [setUtilitiesBalanceAccount, utilitiesBalanceAccountList]);

  useEffect(() => {
    if (utilitiesBalanceAccountList) {
      dispatch(setUtilitiesBalanceAccountListAction(utilitiesBalanceAccountList));
    }
  }, [utilitiesBalanceAccountList, dispatch]);

  useEffect(() => {
    if (utilitiesBalanceAccount && utilitiesBalanceAccount.id) {
      dispatch(setUtilitiesBalanceAccountAction(utilitiesBalanceAccount));
    }
  }, [utilitiesBalanceAccount, dispatch]);

  useEffect(() => {
    document.body.addEventListener('focusout', () => {
      window.scrollTo(0, 0);
    });
  }, []);

  const modifyCustomRechargeAmount = useCallback((amount: number) => {
    setRechargeAmount(amount);
  }, []);

  if (error) {
    return (
      <ErrorScreen
        message={error.message}
        errorType={error.type}
        fullScreen={true}
        retry={error.type === 'UNKNOWN'}
        onRetry={reload}
      />
    );
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <StyledWrapper>
      <UtilitiesHead
        utilitiesBalanceAccount={utilitiesBalanceAccount}
        utilitiesBalanceAccountList={utilitiesBalanceAccountList}
        selectedUtilitiesBalanceAccount={setUtilitiesBalanceAccount}
      />
      <ContextWrapper>
        <UtilitiesBalanceCard utilitiesBalanceAccount={utilitiesBalanceAccount} />
        <RechargeWrapper>
          <RechargeTitle>{'快速充值'}</RechargeTitle>
          <RechargeButtonGroup>
            {utilitiesRechargeList.map((amount: number, index: number) => (
              <RechargeButton
                key={index}
                narrow={(index + 1) % 3 !== 0}
                width={rechargeButtonWidth}
                selected={amount === rechargeAmount}
                onClick={() => setRechargeAmount(amount)}
              >
                {'￥'} {amount}
              </RechargeButton>
            ))}
            <RechargeButton
              custom={true}
              narrow={(utilitiesRechargeList.length + 1) % 3 !== 0}
              width={rechargeButtonWidth}
              selected={!utilitiesRechargeList.includes(rechargeAmount) && rechargeAmount > 0}
              onClick={() => setShowCustomRecharge(true)}
            >
              {'自定义金额'}
            </RechargeButton>
          </RechargeButtonGroup>
          {!utilitiesRechargeList.includes(rechargeAmount) && rechargeAmount > 0 && (
            <CustomRechargeWrapper>
              <CustomRechargeAmount>
                {'充值金额'} {'￥'}
                {rechargeAmount}
              </CustomRechargeAmount>
              <CustomRechargeModify onClick={() => setShowCustomRecharge(true)}>{'修改金额'}</CustomRechargeModify>
              <MoreWrapper>
                <MoreGrayIcon />
              </MoreWrapper>
            </CustomRechargeWrapper>
          )}
          {showCustomRecharge && (
            <CustomRechargeDialog
              title={'自定义金额'}
              amount={rechargeAmount}
              modifyCustomRechargeAmount={modifyCustomRechargeAmount}
              onClose={() => setShowCustomRecharge(false)}
            />
          )}
        </RechargeWrapper>
      </ContextWrapper>
      <BottomButtonGroup
        primary={{
          text: '充值',
          onClick: () =>
            history.push(
              routingPaths.pay +
                `?needPayAmount=${rechargeAmount}&billId=${utilitiesBalanceAccount.id}&utilityPrepay=true&disableSplit=true`
            ),
        }}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100vw;
  height: 100%;
`;

const ContextWrapper = styled.div`
  margin: -32px 24px 0px 24px;
`;

const RechargeWrapper = styled.div`
  margin-top: 40px;
`;

const RechargeTitle = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #000000;
`;

const RechargeButtonGroup = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const Button = styled.div<{ width?: number; selected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${({ width }) => (width ? width + 'px' : '102px')};
  height: 54px;
  background: ${({ selected }) => (selected ? 'rgba(206, 109, 80, 0.05)' : '#FFFFFF')};
  border: 1px solid ${({ selected }) => (selected ? '#BF5636' : '#E0E0E0')};
  border-radius: 4px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 32px;
  font-feature-settings: 'ss06' on;
  color: ${({ selected }) => (selected ? '#CC5A3D' : '#000000')};
`;

const RechargeButton = styled(Button)<{ narrow?: boolean; custom?: boolean }>`
  margin-right: ${({ narrow }) => (narrow ? '8px' : '0px')};
  margin-bottom: 12px;
  font-weight: ${({ custom }) => (custom ? '800' : '600')};
  font-size: ${({ custom }) => (custom ? '16px' : '20px')};
`;

const CustomRechargeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 4px;
  height: 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const CustomRechargeAmount = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'ss06' on;
  color: #000000;
`;

const CustomRechargeModify = styled.div`
  margin-left: auto;
  margin-right: 0;

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #525252;
`;

const MoreWrapper = styled.div`
  margin-left: 10px;
  margin-right: 4px;
`;
