const CloseBlackIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_997_18863)">
        <path
          d="M15.8337 5.34163L14.6587 4.16663L10.0003 8.82496L5.34199 4.16663L4.16699 5.34163L8.82533 9.99996L4.16699 14.6583L5.34199 15.8333L10.0003 11.175L14.6587 15.8333L15.8337 14.6583L11.1753 9.99996L15.8337 5.34163Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_997_18863">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CloseBlackIcon;
