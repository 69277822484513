import { fmsFetchData } from '../common/fmsApiRequest';
import { FmsUser, getDefaultFmsUser } from '../models/user';

const FMS_USE_STORAGE_KEY = 'fms.user-profile';

export const getFmsUser = async (cssUserId: string, cssToken: string): Promise<FmsUser> => {
  return fmsFetchData<FmsUser>({
    url: `/fms_css/login/css/auth`,
    method: 'POST',
    params: {
      cssUserId: cssUserId,
      cssToken: cssToken,
    },
  });
};

export const sendXcdCampaignCoupon = async (cssUserId: string, paymentRecordId: string): Promise<string> => {
  return fmsFetchData<string>({
    url: `/fms_css/coupon/xcd_campaign`,
    method: 'GET',
    params: {
      cssUserId: cssUserId,
      paymentRecordId: paymentRecordId,
    },
  });
};

export const setFmsUserProfileToStorage = (user: FmsUser) => {
  localStorage.setItem(FMS_USE_STORAGE_KEY, JSON.stringify(user));
};

export const getFmsUserProfileFromStorage = (): FmsUser => {
  const value = localStorage.getItem(FMS_USE_STORAGE_KEY);
  if (!value) {
    return getDefaultFmsUser;
  }
  try {
    return JSON.parse(value);
  } catch (e) {
    return getDefaultFmsUser;
  }
};

export const cleanFmsUserProfileFromStorage = () => {
  localStorage.removeItem(FMS_USE_STORAGE_KEY);
};

export const getFmsTokenFromStorage = (): string => {
  const fmsUser: FmsUser = getFmsUserProfileFromStorage();
  return fmsUser && fmsUser.token ? fmsUser.token : '';
};

export const getServiceTokenFromStorage = (): string => {
  const fmsUser: FmsUser = getFmsUserProfileFromStorage();
  return fmsUser && fmsUser.serviceToken ? fmsUser.serviceToken : '';
};
