import React from 'react';
import styled from 'styled-components';

import BlackButton from '../../components/BlackButton';
import { UtilitiesBalanceAccount } from '../../models/account';
import flash from '../../assets/images/flash.png';

interface UtilitiesHeadProps {
  utilitiesBalanceAccount: UtilitiesBalanceAccount;
  utilitiesBalanceAccountList: UtilitiesBalanceAccount[];
  selectedUtilitiesBalanceAccount(utilitiesBalanceAccount: UtilitiesBalanceAccount): void;
}

export const UtilitiesHead: React.FC<UtilitiesHeadProps> = ({
  utilitiesBalanceAccount,
  utilitiesBalanceAccountList,
  selectedUtilitiesBalanceAccount,
}) => {
  return (
    <StyledWrapper>
      <NavWrapper>
        {(utilitiesBalanceAccountList || []).map((account: UtilitiesBalanceAccount, index: number) => (
          <NavButton
            key={index}
            selected={utilitiesBalanceAccount.contractId === account.contractId}
            onClick={() => selectedUtilitiesBalanceAccount(account)}
          >
            {account.kitchenCode}
            {account.storeName}
          </NavButton>
        ))}
      </NavWrapper>
      <TitleWrapper picUrl={flash}>
        <Title>
          {utilitiesBalanceAccount.kitchenCode} {utilitiesBalanceAccount.storeName}
        </Title>
      </TitleWrapper>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  height: 194px;
  background: #f9fafb;
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;

  padding: 20px 24px 15px 24px;
  box-sizing: border-box;

  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
`;

const NavButton = styled(BlackButton)`
  box-sizing: border-box;
  margin-right: 12px;
  padding: 4px 11px 4px 11px;
`;

const Title = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #000000;
`;

const TitleWrapper = styled.div<{ picUrl: string }>`
  padding-top: 17px;
  margin-left: 24px;
  height: 110px;
  background-image: url(${({ picUrl }: { picUrl: string }) => (picUrl.length > 0 ? picUrl : '')});
  background-size: 74px 99px;
  background-repeat: no-repeat;
  background-position: ${() => window.innerWidth - 156}px 0px;
  box-sizing: border-box;
`;
