import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import ErrorScreen, { Error } from '../../components/ErrorScreen';
import LoadingScreen from '../../components/LoadingScreen';
import PurpleButton from '../../components/PurpleButton';
import Toast, { ToastMessage } from '../../components/Toast';
import { FmsContext } from '../../context/FmsContext';
import {
  LicenseeBalanceAccount,
  LicenseeBalanceAccountHistory,
  defaultLicenseeBalanceAccount,
} from '../../models/account';
import { SimpleContract } from '../../models/contract';
import {
  getLicenseeBalanceAccountList,
  getLicenseeBalanceAccountDetail,
  getLicenseeBalanceAccountHistoryList,
} from '../../services/account';
import { formatMoney, reload } from '../../utils/utils';
import AccountCard from './AccountCard';

export const AccountPage: React.FC = () => {
  const {
    state: { facilityId },
  } = useContext(FmsContext);
  const [accountContractList, setAccountContractList] = useState<SimpleContract[]>();
  const [accountHistoryList, setAccountHistoryList] = useState<LicenseeBalanceAccountHistory[]>();
  const [licenseeBalanceAccount, setLicenseeBalanceAccount] =
    useState<LicenseeBalanceAccount>(defaultLicenseeBalanceAccount);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [contractId, setContractId] = useState<string>('');
  const [toastMessage, setToastMessage] = useState<ToastMessage>();

  useEffect(() => {
    setLoading(true);
    getLicenseeBalanceAccountList(facilityId)
      .then((res) => {
        if (!res || res.length <= 0) {
          setError({ type: 'EMPTY', message: '当前门店暂无余额账号' });
        } else {
          setAccountContractList(res);
          setContractId(res[0].contractId);
        }
      })
      .catch((res) => {
        setError({ type: 'UNKNOWN', message: res.message });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [facilityId, setAccountContractList, setContractId, setLoading, setError]);

  useEffect(() => {
    if (contractId) {
      getLicenseeBalanceAccountDetail(contractId)
        .then((res) => {
          setLicenseeBalanceAccount(res);
        })
        .catch((res) => {
          setToastMessage({ type: 'error', message: res.message });
        });

      getLicenseeBalanceAccountHistoryList(contractId)
        .then((res) => {
          setAccountHistoryList(res);
        })
        .catch((res) => {
          setToastMessage({ type: 'error', message: res.message });
        });
    }
  }, [setLicenseeBalanceAccount, setAccountHistoryList, contractId, setLoading, setToastMessage]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <ErrorScreen
        message={error.message}
        errorType={error.type}
        fullScreen={true}
        retry={error.type === 'UNKNOWN'}
        onRetry={reload}
      />
    );
  }

  return (
    <StyledWrapper>
      <HeadWrapper>
        <NavWrapper>
          {(accountContractList || []).map((contract: SimpleContract, index: number) => (
            <Button
              key={index}
              selected={contractId === contract.contractId}
              onClick={() => setContractId(contract.contractId)}
            >
              {contract.kitchenCode}
              {contract.storeName}
            </Button>
          ))}
        </NavWrapper>
        <Title>{'可用余额'}</Title>
        <Balance>
          {'￥'}
          {formatMoney(licenseeBalanceAccount.balance)}
        </Balance>
      </HeadWrapper>
      <ContextWrapper>
        {(accountHistoryList || []).map((history: LicenseeBalanceAccountHistory, index: number) => (
          <AccountCard key={index} accountHistory={history} />
        ))}
      </ContextWrapper>
      <Masker />
      <BottomWrapper>
        <Bottom />
      </BottomWrapper>
      <Toast
        visible={!!toastMessage}
        type={toastMessage?.type || 'error'}
        message={toastMessage?.message || ''}
        onHide={() => setToastMessage(undefined)}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const HeadWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 193px;
  box-sizing: border-box;
  padding: 32px 14px 29px 24px;
  background: #8361fd;
`;

const BottomWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 55px;
  background: #8361fd;
`;

const Bottom = styled.div`
  width: 100%;
  height: 20px;
  background: #ffffff;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
`;

const Masker = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 138px;
  margin-bottom: 35px;
  background: linear-gradient(358.52deg, #ffffff -25.52%, #ffffff 15.39%, rgba(255, 255, 255, 0) 64.38%);
  border-radius: 16px;
`;

const ContextWrapper = styled.div`
  background: #ffffff;
  box-sizing: border-box;
  padding: 32px 24px 32px 24px;
  margin-top: 193px;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;

  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
`;

const Button = styled(PurpleButton)`
  box-sizing: border-box;
  margin-right: 12px;
  padding: 4px 11px 4px 11px;
`;

const Title = styled.div`
  margin-top: 9px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;

const Balance = styled.div`
  margin-top: 20px;
  margin-left: -7px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 22px;
  font-feature-settings: 'ss06' on;
  color: #ffffff;
`;
