import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import ListIcon from '../../components/icon/ListIcon';
import { UtilitiesBalanceAccount } from '../../models/account';
import { formatMoney, moneyToNumber } from '../../utils/utils';

interface UtilitiesBalanceCardProps {
  utilitiesBalanceAccount: UtilitiesBalanceAccount;
}

export const UtilitiesBalanceCard: React.FC<UtilitiesBalanceCardProps> = ({ utilitiesBalanceAccount }) => {
  const history = useHistory();
  return (
    <BalanceCard>
      <BalanceWrapper>
        <BalanceTitle>{'电费余额'}</BalanceTitle>
        <BalanceAmount>
          {'￥'}
          {formatMoney(moneyToNumber(utilitiesBalanceAccount.balance))}
        </BalanceAmount>
      </BalanceWrapper>
      <BalanceHistoryWrapper onClick={() => history.push(`/utilities/history/${utilitiesBalanceAccount.id}`)}>
        <ListIcon />
        <BalanceHistory>{'电费记录'}</BalanceHistory>
      </BalanceHistoryWrapper>
    </BalanceCard>
  );
};

const BalanceCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 114px;
  background: #ffffff;
  box-shadow: 0px 5px 13px rgba(28, 28, 28, 0.1);
  border-radius: 12px;

  padding: 0px 20px 0px 20px;
  box-sizing: border-box;
`;

const BalanceWrapper = styled.div``;

const BalanceHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  box-sizing: border-box;
`;

const BalanceTitle = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #8f8f8f;
`;

const BalanceAmount = styled.div`
  margin-left: -5px;
  margin-top: 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 20px;
  color: #000000;
`;

const BalanceHistory = styled.div`
  margin-top: 8px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #8f8f8f;
`;
