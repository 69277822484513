import styled from 'styled-components';
import {
  background,
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  textAlign,
  typography,
} from 'styled-system';

import type {
  StyledSystemBackgroundProps,
  StyledSystemBorderProps,
  StyledSystemColorProps,
  StyledSystemColumnGapProps,
  StyledSystemFlexboxProps,
  StyledSystemGapProps,
  StyledSystemGridProps,
  StyledSystemLayoutProps,
  StyledSystemPositionProps,
  StyledSystemRowGapProps,
  StyledSystemShadowProps,
  StyledSystemSpaceProps,
  StyledSystemTextAlignProps,
  StyledSystemTypographyProps,
} from '../../types/styled-system';
import { columnGap, gap, rowGap } from './customProps';
import { getStyledSystemConfig } from './getStyledSystemConfig';
import { injDataTest } from './injDataTest';

export interface BoxPropsInner
  extends StyledSystemBackgroundProps,
    StyledSystemBorderProps,
    StyledSystemColorProps,
    StyledSystemColumnGapProps,
    StyledSystemFlexboxProps,
    StyledSystemGapProps,
    StyledSystemGridProps,
    StyledSystemLayoutProps,
    StyledSystemPositionProps,
    StyledSystemRowGapProps,
    StyledSystemShadowProps,
    StyledSystemSpaceProps,
    StyledSystemTextAlignProps,
    StyledSystemTypographyProps {
  /**
   * Optional data test id
   * @default "csds-box"
   */
  dataTest?: string;
}

export type BoxProps = React.HTMLAttributes<HTMLElement> & BoxPropsInner;

export const boxStyledSystemProps = compose(
  background,
  border,
  color,
  columnGap,
  flexbox,
  gap,
  grid,
  layout,
  position,
  rowGap,
  shadow,
  space,
  textAlign,
  typography
);

/**
 * The Box component is a low-level primitive used for layout purposes. It can be used to create grid layouts, apply padding or margin, and more. If you are looking for a simple vertical or horizontal stacking component, see Stack.
 *
 * https://styled-system.com/guides/build-a-box/
 */
export const Box = styled('div')
  .withConfig<BoxProps>(getStyledSystemConfig())
  .attrs<BoxPropsInner>((props) => injDataTest(props.dataTest || 'csds-box'))<BoxPropsInner>`
  box-sizing: border-box;
  min-width: 0;
  ${boxStyledSystemProps};
`;
