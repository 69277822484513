import styled from 'styled-components';

const WhiteButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  flex: 1 0 auto;
  min-width: 129px;
  height: 44px;
  background: rgba(0, 0, 0, 0.0001);
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'ss06' on;
  color: #141414;
`;
export default WhiteButton;
