import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ThemeProvider } from 'styled-components';

import { theme } from './components/op-components';

import './assets/fonts/inter.css';
import App from './App';
import { FmsContextProvider } from './context/FmsContext';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <FmsContextProvider>
          <App />
        </FmsContextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>,

  document.getElementById('root')
);
