export interface PrePayRequest {
  // 账单ID
  billId: string;
  // 账单编号
  billCode?: string;
  // 金额
  payAmount: string;
  // 是否拆分
  isSplit: 'Y' | 'N';
  // 支付方式（见PayModelEnum） 1:线上支付 2:线下支付 3:转账 4:定金支付
  payModel: number;
  // 支付渠道 (见PayChannelEnum)1:支付宝 2:微信 3: 线下转账 4: pos机 5:二维码支付 6:pos银行卡、7:pos扫一扫
  payChannel: number;
  // 门店id
  facilityId?: number;
  // CSS门店id
  cssFacilityId?: string;
  // 登陆用户的手机号
  phone?: string;
  // 转账截图,图片地址或文件id列表
  transferImgUrls?: string[];
  // 转账备注
  remark?: string;
  // 转账时间
  transferTimeLg?: number;
  cssToken: string;
  // 付款单据类型
  payDocumentTypeEnum?: PayDocumentTypeEnum;
  // 水电费
  utilityPrepay?: boolean;
  source?: string;
}

export interface PrepayResponse {
  [key: string]: unknown;
}

export enum PayModelEnum {
  ONLINE = 1, // 线上支付
  OFFLINE = 2, // 线下支付
  BANK_TRANSFER = 3, // 转账
  DEPOSIT = 4, // 定金支付
  CASH_PLEDGE_PAY = 5, // 押金抵扣
  BALANCE = 6, // 余额支付
}

export enum PayChannelEnum {
  ALIPAY = 1, // 支付宝
  WECHAT = 9, // 微信
  OFFLINE = 3, // 线下转账
  POS = 4, // pos机 这个没有用到
  QR_CODE = 5, // 二维码支付
  POS_BANK_CARD = 6, // pos银行卡
  POS_SCAN = 7, // pos扫一扫
  BALANCE = 8, // 账户余额
}

export enum PayDocumentTypeEnum {
  INVOICE = 1, // 账单
  UTILITY = 2, // 水电费
  RESERVATION_FEE = 3, //定金
}

export interface QueryPayRequest {
  billId: string;
  payDetailId: string;
}

export interface QueryPayResponse {
  payStatus: number;
}

export interface GenerateUrlSchemeRequest {
  billId: string;
  companyName?: string;
  payAmount: string;
  isSplit: 'Y' | 'N';
  utilityPrepay?: boolean;
  token: string;
}

export interface GenerateUrlSchemeResponse {
  errcode: number;
  errmsg?: string;
  openlink: string;
}
