import styled from 'styled-components';

interface BlankBoxProps {
  width?: string;
  height?: string;
}

export const BlankBox = styled.div<BlankBoxProps>`
  flex: none;
  display: block;
  ${(props) => (props.width ? `width: ${props.width};` : '')}
  ${(props) => (props.height ? `height: ${props.height};` : '')}
`;
