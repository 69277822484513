const BookIcon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.4" filter="url(#filter0_b_580_73272)">
        <circle cx="16" cy="16" r="16" fill="white" />
      </g>
      <path
        d="M10.5565 9.00014H13.8076C14.1343 8.09791 14.9899 7.44458 16.001 7.44458C17.0121 7.44458 17.8676 8.09791 18.1943 9.00014H21.4454C22.301 9.00014 23.001 9.70014 23.001 10.5557V23.0001C23.001 23.8557 22.301 24.5557 21.4454 24.5557H10.5565C9.70098 24.5557 9.00098 23.8557 9.00098 23.0001V10.5557C9.00098 9.70014 9.70098 9.00014 10.5565 9.00014ZM16.001 9.00014C15.5732 9.00014 15.2232 9.35014 15.2232 9.77791C15.2232 10.2057 15.5732 10.5557 16.001 10.5557C16.4288 10.5557 16.7788 10.2057 16.7788 9.77791C16.7788 9.35014 16.4288 9.00014 16.001 9.00014ZM10.5565 23.0001H21.4454V10.5557H19.8899V12.889H12.1121V10.5557H10.5565V23.0001Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_b_580_73272"
          x="-10.5"
          y="-10.5"
          width="53"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="5.25" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_580_73272" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_580_73272" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
export default BookIcon;
