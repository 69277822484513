import React from 'react';
import styled from 'styled-components';

import MoreGrayIcon from '../../components/icon/MoreGrayIcon';
import { Bill } from '../../models/bill';

interface BillHistoryCardProps {
  bill: Bill;
  showBillDetail: () => void;
}

const BillHistoryCard: React.FC<BillHistoryCardProps> = ({ bill, showBillDetail }) => {
  return (
    <StyledWrapper onClick={showBillDetail}>
      <KitchenCodeWrapper>
        <KitchenCode>{bill.kitchenCode}</KitchenCode>
      </KitchenCodeWrapper>
      <BillWrapper>
        <BillType>{bill.billTypeName}</BillType>
        <BillCycle>
          {bill.cycleStartDate} - {bill.cycleEndDate}
        </BillCycle>
      </BillWrapper>
      <BillAmount>{bill.amount}</BillAmount>
      <MoreWrapper>
        <MoreGrayIcon />
      </MoreWrapper>
    </StyledWrapper>
  );
};

export default BillHistoryCard;

const StyledWrapper = styled.div`
  width: 100%;
  height: 42px;
  margin-bottom: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const KitchenCodeWrapper = styled.div`
  width: 40px;
  height: 40px;
  background: rgba(206, 109, 80, 0.1);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const KitchenCode = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ad4c33;
  z-index: 10;
`;
const BillWrapper = styled.div`
  margin-left: 12px;
`;

const BillType = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-feature-settings: 'ss06' on;
  color: #1a1a1a;
`;

const BillCycle = styled.div`
  margin-top: 6px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #707070;
`;

const BillAmount = styled.div`
  margin-left: auto;
  margin-right: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #4f4f4f;
`;

const MoreWrapper = styled.div`
  margin-left: 10px;
  margin-right: 0;
`;
