import { fmsFetchData } from '../common/fmsApiRequest';
import { Bill, FacilityBillDetail } from '../models/bill';

export const getFacilityBillDetail = async (facilityId?: string): Promise<FacilityBillDetail> => {
  return fmsFetchData<FacilityBillDetail>({
    url: `/merchant_css/bill/getFacilityBillDetail`,
    method: 'GET',
    params: { facilityId: facilityId ? facilityId : '' },
  });
};

export const getBillDetailById = async (billId: string): Promise<Bill> => {
  return fmsFetchData<Bill>({
    url: `/merchant_css/bill/detail`,
    method: 'GET',
    params: { id: billId },
  });
};

export const confirmBillById = async (billId: string): Promise<void> => {
  return fmsFetchData<void>({
    url: `/merchant_css/bill/confirm`,
    method: 'GET',
    params: { id: billId },
  });
};
