import { css } from 'styled-components';

import { Shadow, StyledBoxShadowFn } from './types';

/**
 * @deprecated Use `boxShadow()` from `@css/csds-components` instead.
 */
export const boxShadow: StyledBoxShadowFn =
  (sh) =>
  ({ theme }) =>
    css`
      box-shadow: ${theme.shadows[sh as Shadow] || sh};
    `;
