import moment from 'moment';

import { Money, UtilitiesBalanceAccountHistoryType } from '../models/account';
import { BillCost, BillCostType } from '../models/bill';
import { ContractEntityType, ContractStatusEnum } from '../models/contract';

const NANOS = 1000000000;
const CURRENCY_CODE = 'CNY';
export const DATETIME_FORMAT = 'YYYY.MM.DD HH:mm';
export const DATETIME_FORMAT_TZ = 'YYYY-MM-DDTHH:mm:[00][Z]';
export const DATETIME_YEAR_MONTH = 'YYYY-M';
export const DATE_ONLY_FORMAT = 'YYYY.MM.DD';
export const TIME_ONLY_FORMAT = 'HH:mm:ss';

export const isInReview = (contractStatus: number): boolean => {
  return contractStatus === ContractStatusEnum.IN_RESERVATION;
};

export const isInvalid = (contractStatus: number): boolean => {
  return contractStatus === ContractStatusEnum.INVALID || contractStatus === ContractStatusEnum.REJECTED;
};

export const isWaitConfirm = (contractStatus: number): boolean => {
  return contractStatus === ContractStatusEnum.RESERVED || contractStatus === ContractStatusEnum.PENDING;
};

export const isToSign = (contractStatus: number): boolean => {
  return (
    contractStatus === ContractStatusEnum.NEED_TO_SIGN_AND_PAYMENT ||
    contractStatus === ContractStatusEnum.PAID_AND_NOT_SIGNED
  );
};

export const isToPay = (contractStatus: number): boolean => {
  return (
    contractStatus === ContractStatusEnum.SIGNED_AND_NOT_PAID ||
    contractStatus === ContractStatusEnum.NEED_TO_SIGN_AND_PAYMENT
  );
};

export const isSigned = (contractStatus: number): boolean => {
  return (
    contractStatus === ContractStatusEnum.LEASED ||
    contractStatus === ContractStatusEnum.TERMINATION ||
    contractStatus === ContractStatusEnum.RENEWAL ||
    contractStatus === ContractStatusEnum.SIGNED_AND_NOT_PAID
  );
};

export const showToSignButton = (contractStatus: number, entityType: number): boolean => {
  return isToSign(contractStatus) && entityType === ContractEntityType.PERSONAL;
};

export const getBillCostDescribe = (billCost: BillCost): string => {
  if (
    billCost.type === BillCostType.WATER_FEE ||
    billCost.type === BillCostType.ELECTRICITY_FEE ||
    billCost.type === BillCostType.GAS_FEE
  ) {
    return `期初至期末度数: ${billCost.initialStageNum || '0'} - ${billCost.lastStageNum || '0'}`;
  } else if (billCost.type === BillCostType.LICENSE_FEE_BASE && billCost.cycleStartTime && billCost.cycleEndTime) {
    return `周期: ${billCost.cycleStartTime} - ${billCost.cycleEndTime}`;
  }
  return '';
};

export const reload = (): void => {
  window.location.reload();
};

export const MONEY_ZERO: Money = {
  currencyCode: CURRENCY_CODE,
  units: 0,
  nanos: 0,
};

export const moneyToNumber = (money?: Money): number => {
  const { units = 0, nanos = 0 } = money || MONEY_ZERO;
  return (units * NANOS + nanos) / NANOS;
};

export const numberToMoney = (num: number): Money => {
  return nanosToMoney(num * NANOS);
};

export const nanosOf = (money?: Money): number => {
  const { units = 0, nanos = 0 } = money || MONEY_ZERO;
  return units * NANOS + nanos;
};

export const nanosToMoney = (totalNanos: number, currencyCode?: string): Money => {
  return {
    units: Math.floor(totalNanos / NANOS),
    nanos: totalNanos % NANOS,
    currencyCode: currencyCode || CURRENCY_CODE,
  };
};

export const formatDate = (date?: Date, format = DATETIME_FORMAT): string => {
  if (!date) {
    return '';
  }
  return moment(date).format(format);
};

export const formatMoney = (money: number): string => {
  if (money === undefined) {
    return '0';
  }

  return money.toFixed(2);
};

export const toLocalDateTime = (date: string): string => {
  return moment(date).format(DATETIME_FORMAT);
};

export const getHistoryTypeString = (historyType: UtilitiesBalanceAccountHistoryType): string => {
  switch (historyType) {
    case UtilitiesBalanceAccountHistoryType.RECHARGE:
      return '充值记录';
    case UtilitiesBalanceAccountHistoryType.DAILY_COST:
      return '扣款记录';
    case UtilitiesBalanceAccountHistoryType.ALL:
      return '全部记录';
    default:
      return '全部记录';
  }
};

export const getYearMonthString = (yearMonth: string): string => {
  return yearMonth.replace('-', '年') + '月';
};

export const numberFix2 = (n: number) => parseFloat(n.toFixed(2));

export const sleep = async (msToWait: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, msToWait));
};
