import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import ErrorScreen, { Error } from '../../components/ErrorScreen';
import LoadingScreen from '../../components/LoadingScreen';
import { getAccountInfo } from '../../services/monitor';
import { reload } from '../../utils/utils';

interface MonitorPlayerProps {
  deviceSerial: string;
  channelNo: number;
  videoType: videoType;
}

type videoType = 'live' | 'rec';

const MonitorPlayer: React.FC<MonitorPlayerProps> = ({ deviceSerial, channelNo, videoType }: MonitorPlayerProps) => {
  const [accessToken, setAccessToken] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    setLoading(true);
    getAccountInfo()
      .then((accountInfo) => {
        if (accountInfo.token) {
          setAccessToken(accountInfo.token);
        } else {
          setError({ type: 'EMPTY', message: 'Failed Get HiK Token' });
        }
      })
      .catch((res) => {
        setError({ type: 'UNKNOWN', message: res.message });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setAccessToken, setError, setLoading]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <ErrorScreen message={error.message} errorType={error.type} fullScreen={true} retry={true} onRetry={reload} />
    );
  }

  return (
    <StyledWrapper>
      {accessToken && (
        <iframe
          title="monitorPlayer"
          id="monitorPlayer"
          width="100%"
          height={videoType === 'rec' ? window.innerHeight : window.innerWidth * 0.65}
          frameBorder={0}
          scrolling="no"
          srcDoc={`
                <!DOCTYPE html>
                <html lang="en">
                  <head>
                  <title>Player</title>
                    <script src="/static/ezuikit.js"></script>
                    <script type="text/javascript">
                      let mPlayer = new EZUIKit.EZUIKitPlayer({
                        id: 'video-container',
                        url:'ezopen://open.ys7.com/${deviceSerial}/${channelNo}.${videoType}', 
                        accessToken: '${accessToken}',
                        themeData: themeData,
                        plugin: [],
                        width: window.innerWidth,
                        height: window.innerWidth * 0.65,
                      });
                    </script>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                    <style type="text/css">
                      body {
                        margin: 0;
                        padding: 0;
                      }
                    </style>
                  </head>
                  <body>
                     <div id="video-container"></div>
                  </body>
                </html>         
            `}
        />
      )}
    </StyledWrapper>
  );
};

export default MonitorPlayer;

const StyledWrapper = styled.div`
  width: 100vw;
`;
