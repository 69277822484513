const SuccessIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0003 1.66663C5.40033 1.66663 1.66699 5.39996 1.66699 9.99996C1.66699 14.6 5.40033 18.3333 10.0003 18.3333C14.6003 18.3333 18.3337 14.6 18.3337 9.99996C18.3337 5.39996 14.6003 1.66663 10.0003 1.66663ZM10.0003 16.6666C6.32533 16.6666 3.33366 13.675 3.33366 9.99996C3.33366 6.32496 6.32533 3.33329 10.0003 3.33329C13.6753 3.33329 16.667 6.32496 16.667 9.99996C16.667 13.675 13.6753 16.6666 10.0003 16.6666ZM13.8253 6.31663L8.33366 11.8083L6.17533 9.65829L5.00033 10.8333L8.33366 14.1666L15.0003 7.49996L13.8253 6.31663Z"
        fill="#46B760"
        fillOpacity="0.9"
      />
    </svg>
  );
};
export default SuccessIcon;
