const ErrorRedIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00016 1.33337C4.32016 1.33337 1.3335 4.32004 1.3335 8.00004C1.3335 11.68 4.32016 14.6667 8.00016 14.6667C11.6802 14.6667 14.6668 11.68 14.6668 8.00004C14.6668 4.32004 11.6802 1.33337 8.00016 1.33337ZM8.66683 11.3334H7.3335V10H8.66683V11.3334ZM8.66683 8.66671H7.3335V4.66671H8.66683V8.66671Z"
        fill="#C4232B"
      />
    </svg>
  );
};
export default ErrorRedIcon;
