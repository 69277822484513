import { css } from 'styled-components';

import { Space, StyledSpaceUtil } from './types';

/**
 * @deprecated Use `margin()` from `@css/csds-components` instead.
 */
export const margin: StyledSpaceUtil =
  (s) =>
  ({ theme }) =>
    css`
      margin: ${theme.space[s as Space] || s};
    `;
margin.t =
  (s) =>
  ({ theme }) =>
    css`
      margin-top: ${theme.space[s as Space] || s};
    `;
margin.l =
  (s) =>
  ({ theme }) =>
    css`
      margin-left: ${theme.space[s as Space] || s};
    `;
margin.b =
  (s) =>
  ({ theme }) =>
    css`
      margin-bottom: ${theme.space[s as Space] || s};
    `;
margin.r =
  (s) =>
  ({ theme }) =>
    css`
      margin-right: ${theme.space[s as Space] || s};
    `;
margin.x =
  (s) =>
  ({ theme }) =>
    css`
      margin-right: ${theme.space[s as Space] || s};
      margin-left: ${theme.space[s as Space] || s};
    `;
margin.y =
  (s) =>
  ({ theme }) =>
    css`
      margin-top: ${theme.space[s as Space] || s};
      margin-bottom: ${theme.space[s as Space] || s};
    `;
