import { ResizeObserverEntry } from '@juggle/resize-observer';
import React, { useLayoutEffect, useState } from 'react';

import { useResizeObserver } from './useResizeObserver';

/**
 * Some day we may use this hook to track the positioning values exposed by getBoundingClientRect;
 * but until we need it, let's start with just the box size.
 */
export type ClientRectDimensions = Pick<DOMRect, 'height' | 'width'>;

/**
 * Fixes the dimension to 1 digit after the decimal point since that's
 * the most precision that we need. If we allow more precision, the more precise
 * digits will be slightly different on each render which may result in an
 * infinite loop of renders.
 */
function toFixedRect(rect: ClientRectDimensions): ClientRectDimensions {
  return {
    height: Number(rect.height.toFixed(1)),
    width: Number(rect.width.toFixed(1)),
  };
}

/**
 * Returns the DOMRect of a given element. Also adds a ResizeObserver for the element to track
 * changes to the element size.
 *
 * @returns [{ height: number, width: number }, refCallback, ref]
 */
export const useClientRect = <Element extends HTMLElement = HTMLElement>(): [
  ClientRectDimensions | undefined,
  React.RefCallback<Element>,
  Element | null
] => {
  const [rect, setRect] = useState<ClientRectDimensions>();

  const [refCallback, node] = useResizeObserver<Element>((entry: ResizeObserverEntry) => {
    // Since all our elements are `box-sizing: border-box`, the measurement we get from
    // getBoundingClientRect will include padding (i.e. it will honor the box sizing). So we should
    // read the same value here.
    const borderBoxSize = entry.borderBoxSize[0];

    if (!borderBoxSize) return;

    // DO NOT REMOVE THE TOFIXED CALLS HERE. See comment above.
    const { width: fixedWidth, height: fixedHeight } = toFixedRect({
      width: borderBoxSize.inlineSize,
      height: borderBoxSize.blockSize,
    });
    if (
      (fixedHeight !== rect?.height || fixedWidth !== rect?.width) &&
      fixedHeight !== undefined &&
      fixedWidth !== undefined
    ) {
      setRect({ height: fixedHeight, width: fixedWidth });
    }
  });

  // Set width based on element size when the ref changes
  useLayoutEffect(() => {
    if (node) {
      const { width, height } = node.getBoundingClientRect();
      // DO NOT REMOVE THE TOFIXED CALLS HERE. See comment above.
      const { width: fixedWidth, height: fixedHeight } = toFixedRect({ width, height });

      if (fixedHeight !== rect?.height || fixedWidth !== rect?.width) {
        setRect({ height: fixedHeight, width: fixedWidth });
      }
    }
  }, [node, rect?.height, rect?.width]);

  return [rect, refCallback, node];
};
