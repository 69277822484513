import React, { useContext, useReducer } from 'react';

import { Action } from '../common/constants';
import { defaultUtilitiesBalanceAccount, UtilitiesBalanceAccount } from '../models/account';
import { Contract, getDefaultContract } from '../models/contract';
import { Monitor, getDefaultMonitor } from '../models/monitor';

interface FmsState {
  cssUserId: string;
  facilityId: string;
  contract: Contract;
  monitor: Monitor;
  utilitiesBalanceAccount: UtilitiesBalanceAccount;
  utilitiesBalanceAccountList: UtilitiesBalanceAccount[];
}

const reducer = (state: FmsState, action: Action): FmsState => {
  switch (action.type) {
    case CSS_USER_ID:
      return setCssUserId(state, action);
    case FACILITY_ID:
      return setFacilityId(state, action);
    case CONTRACT:
      return setContract(state, action);
    case MONITOR:
      return setMonitor(state, action);
    case UTILITIES_BALANCE_ACCOUNT:
      return setUtilitiesBalanceAccount(state, action);
    case UTILITIES_BALANCE_ACCOUNT_LIST:
      return setUtilitiesBalanceAccountList(state, action);
    default:
      return state;
  }
};

interface FmsContextProps {
  state: FmsState;
  dispatch: (action: Action) => void;
}

const defaultContextValue: FmsContextProps = {
  state: {
    cssUserId: '',
    facilityId: '',
    contract: getDefaultContract,
    monitor: getDefaultMonitor,
    utilitiesBalanceAccount: defaultUtilitiesBalanceAccount,
    utilitiesBalanceAccountList: [],
  },
  dispatch: (): void => {},
};

export const FmsContext = React.createContext<FmsContextProps>(defaultContextValue);

export const FmsContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultContextValue.state);
  return <FmsContext.Provider value={{ state, dispatch }}>{children}</FmsContext.Provider>;
};

export const useFmsSelector = function <T>(selector: (state: FmsState) => T): T {
  const { state } = useContext(FmsContext);
  return selector(state);
};

const CSS_USER_ID = 'CSS_USER_ID';

const setCssUserId = (state: FmsState, action: Action): FmsState => {
  const cssUserId = action.data as string;
  return {
    ...state,
    cssUserId,
  };
};

export const setCssUserIdIdAction = (cssUserId: string): Action => ({
  type: CSS_USER_ID,
  data: cssUserId,
});

const FACILITY_ID = 'FACILITY_ID';

const setFacilityId = (state: FmsState, action: Action): FmsState => {
  const facilityId = action.data as string;
  return {
    ...state,
    facilityId,
  };
};

export const setFacilityIdAction = (facilityId: string): Action => ({
  type: FACILITY_ID,
  data: facilityId,
});

const CONTRACT = 'CONTRACT';

const setContract = (state: FmsState, action: Action): FmsState => {
  const contract = action.data as Contract;
  return {
    ...state,
    contract,
  };
};

export const setContractAction = (contract: Contract): Action => ({
  type: CONTRACT,
  data: contract,
});

const MONITOR = 'MONITOR';

const setMonitor = (state: FmsState, action: Action): FmsState => {
  const monitor = action.data as Monitor;
  return {
    ...state,
    monitor,
  };
};

export const setMonitorAction = (monitor: Monitor): Action => ({
  type: MONITOR,
  data: monitor,
});

const UTILITIES_BALANCE_ACCOUNT = 'UTILITIES_BALANCE_ACCOUNT';

const setUtilitiesBalanceAccount = (state: FmsState, action: Action): FmsState => {
  const utilitiesBalanceAccount = action.data as UtilitiesBalanceAccount;
  return {
    ...state,
    utilitiesBalanceAccount,
  };
};

export const setUtilitiesBalanceAccountAction = (utilitiesBalanceAccount: UtilitiesBalanceAccount): Action => ({
  type: UTILITIES_BALANCE_ACCOUNT,
  data: utilitiesBalanceAccount,
});

const UTILITIES_BALANCE_ACCOUNT_LIST = 'UTILITIES_BALANCE_ACCOUNT_LIST';

const setUtilitiesBalanceAccountList = (state: FmsState, action: Action): FmsState => {
  const utilitiesBalanceAccountList = action.data as UtilitiesBalanceAccount[];
  return {
    ...state,
    utilitiesBalanceAccountList,
  };
};

export const setUtilitiesBalanceAccountListAction = (
  utilitiesBalanceAccountList: UtilitiesBalanceAccount[]
): Action => ({
  type: UTILITIES_BALANCE_ACCOUNT_LIST,
  data: utilitiesBalanceAccountList,
});
