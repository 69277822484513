import { useEffect } from 'react';

type AnyEvent = MouseEvent | TouchEvent;

export const useOnClickOutside = <T extends HTMLElement | null = HTMLElement>(
  refs: Array<React.MutableRefObject<T>>,
  handler: (event: AnyEvent) => void
) => {
  useEffect(
    () => {
      const listener = (event: AnyEvent) => {
        // Do nothing if clicking ref's element or descendent elements
        const isInside = refs.some((ref) => !ref.current || ref.current.contains(event.target as Node));
        if (!isInside) {
          handler(event);
        }
      };

      document.addEventListener('mousedown', listener, { capture: true });
      document.addEventListener('touchstart', listener, { capture: true });

      return () => {
        document.removeEventListener('mousedown', listener, { capture: true });
        document.removeEventListener('touchstart', listener, { capture: true });
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new
    // function on every render that will cause this effect
    // callback/cleanup to run every render. It's not a big deal
    // but to optimize you can wrap handler in useCallback before
    // passing it into this hook.
    [refs, handler]
  );
};
