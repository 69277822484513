import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import { Input } from '../../../components/op-components';
import { Close, Email } from '../../../components/op-icons';

import { BottomButtonGroup } from '../../../components/BottomButtonGroup';
import { Dialog } from '../../../components/Dialog';
import ErrorRedIcon from '../../../components/icon/ErrorRedIcon';
import { sendContractToMail } from '../../../services/contract';

interface SendContractDialogProps {
  contractId: string;
  title: string;
  email?: string;
  onClose: () => void;
}

const emailPattern = /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/;

export const SendContractDialog: React.FC<SendContractDialogProps> = ({ contractId, title, email, onClose }) => {
  const [inputEmail, setInputEmail] = useState<string>(email || '');
  const [error, setError] = useState<string>();

  const submitEmail = useCallback(() => {
    if (!emailPattern.test(inputEmail || '')) {
      setError('请输入正确的邮箱');
      return;
    }
    sendContractToMail(contractId, inputEmail)
      .then(() => {
        onClose();
      })
      .catch((res) => {
        setError(res.returnMsg);
      });
  }, [contractId, inputEmail, setError, onClose]);

  return (
    <Dialog title={title} onClose={onClose}>
      <InputWrapper>
        <EmailInput
          intent={error ? 'error' : undefined}
          value={inputEmail}
          onChange={(e: { target: { value: React.SetStateAction<string> } }) => setInputEmail(e.target.value)}
          placeholder={'请输入邮箱'}
          prefix={<Email />}
          suffix={inputEmail ? <Close onClick={() => setInputEmail('')} /> : undefined}
        />
      </InputWrapper>
      <StyledTipWrapper>
        {error && (
          <>
            <ErrorRedIcon />
            <StyledError>{error}</StyledError>
          </>
        )}
      </StyledTipWrapper>
      <BottomButtonGroup
        primary={{
          text: '确认',
          onClick: submitEmail,
        }}
        secondary={{
          text: '取消',
          onClick: onClose,
        }}
      />
    </Dialog>
  );
};

const InputWrapper = styled.div`
  width: calc(100% - 32px);
  margin-top: 40px;
  margin-left: 16px;
`;

const EmailInput = styled(Input)`
  background: rgba(0, 0, 0, 0.0001);
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  box-sizing: border-box;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

const StyledTipWrapper = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: row;
  height: 40px;
  padding-top: 9px;
`;

const StyledError = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #c4232b;
`;
