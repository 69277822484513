const BellIcon: React.FC = () => {
  return (
    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.1875 1.47913L2.25 0.416626L3.3125 1.47913L4.375 0.416626L5.4375 1.47913L6.5 0.416626L7.5625 1.47913L8.625 0.416626L9.6875 1.47913L10.75 0.416626V10.3333H12.875V12.4583C12.875 13.6341 11.9258 14.5833 10.75 14.5833H2.25C1.07417 14.5833 0.125 13.6341 0.125 12.4583V0.416626L1.1875 1.47913ZM1.54167 12.4583C1.54167 12.8479 1.86042 13.1666 2.25 13.1666C2.63958 13.1666 2.95833 12.8479 2.95833 12.4583V10.3333H9.33333V2.54163H1.54167V12.4583Z"
        fill="white"
      />
      <path d="M4.375 3.95829H8.625V5.37496H4.375V3.95829Z" fill="white" />
      <path d="M2.25 3.95829H3.66667V5.37496H2.25V3.95829Z" fill="white" />
      <path d="M4.375 6.08329H8.625V7.49996H4.375V6.08329Z" fill="white" />
      <path d="M2.25 6.08329H3.66667V7.49996H2.25V6.08329Z" fill="white" />
    </svg>
  );
};
export default BellIcon;
