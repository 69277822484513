export const RadioOnIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#E06E4D" />
      <circle cx="12" cy="12" r="5.5" fill="white" stroke="white" />
    </svg>
  );
};

export const RadioOffIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="black" fillOpacity="0.01" />
      <circle cx="12" cy="12" r="11.5" stroke="black" strokeOpacity="0.16" />
    </svg>
  );
};
