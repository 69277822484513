export interface FmsUser {
  id: string;
  name: string;
  phone: string;
  headImgUrl?: string;
  cssUserId?: string;
  token?: string;
  cssToken?: string;
  serviceToken?: string;
}

export const getDefaultFmsUser: FmsUser = {
  id: 'UUID',
  name: '',
  phone: '',
  headImgUrl: '',
};

export interface CssUser {
  id: string;
}

export const getDefaultCssUser: CssUser = {
  id: '',
};
