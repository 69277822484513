import { useMemo } from 'react';
import styled from 'styled-components';

import { mpTrackAction } from '../common/mixPanel';

interface ButtonProps {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  /**
   * Action name which will be recorded in mix panel
   */
  mpAction?: string | { name: string; params?: { [key: string]: string | boolean | number | undefined } };
}

interface BottomButtonGroupProps {
  primary: ButtonProps;
  secondary?: ButtonProps;
}

const genButOnClickFunc = (props?: ButtonProps) => {
  if (!props || props.disabled) {
    return undefined;
  }
  return () => {
    props.onClick && props.onClick();
    if (props.mpAction) {
      if (typeof props.mpAction === 'string') {
        mpTrackAction(props.mpAction);
      } else {
        mpTrackAction(props.mpAction.name, props.mpAction.params);
      }
    }
  };
};

export const BottomButtonGroup: React.FC<BottomButtonGroupProps> = ({ primary, secondary }) => {
  const primaryOnclick = useMemo(() => genButOnClickFunc(primary), [primary]);
  const secondaryOnclick = useMemo(() => genButOnClickFunc(secondary), [secondary]);
  return (
    <BottomButtonBox>
      {secondary && (
        <SecondaryButton onClick={secondaryOnclick} disabled={secondary.disabled}>
          {secondary.text}
        </SecondaryButton>
      )}
      {secondary && <SpaceBetween />}
      <PrimaryButton onClick={primaryOnclick} disabled={primary.disabled}>
        {primary.text}
      </PrimaryButton>
    </BottomButtonBox>
  );
};

const BottomButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  height: 68px;
  padding: 12px 16px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
`;

const SpaceBetween = styled.div`
  display: block;
  height: 44px;
  width: 10px;
`;

export const PrimaryButton = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  flex: 1 0 auto;
  min-width: 150px;
  height: 44px;
  background: ${({ disabled }) => (disabled ? 'rgba(206, 109, 80, 0.2)' : '#e06e4d')};
  border-radius: 4px;

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'ss06' on;
  color: ${({ disabled }) => (disabled ? 'rgba(206, 109, 80, 0.2)' : '#fafafa')};
`;

export const SecondaryButton = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  flex: 1 0 auto;
  min-width: 150px;
  height: 44px;
  background: rgba(0, 0, 0, 0.0001);
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'ss06' on;
  color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.16)' : '#141414')};
`;
