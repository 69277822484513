import { fmsFetchData, fmsFetchDataList } from '../common/fmsApiRequest';
import { Bill } from '../models/bill';
import { Contract, SimpleContract } from '../models/contract';

export const getContractById = async (contractId: string): Promise<Contract> => {
  return fmsFetchData<Contract>({
    url: `/merchant_css/contract/detail`,
    method: 'GET',
    params: { contractId },
  });
};

export const getContractList = async (facilityId?: string, statusGroup?: string): Promise<SimpleContract[]> => {
  return fmsFetchDataList<SimpleContract>({
    url: `/merchant_css/contract/list`,
    method: 'GET',
    params: {
      facilityId: facilityId ? '' : '',
      statusGroup: statusGroup ? statusGroup : '',
    },
  });
};

export const getContractPdfUrlById = async (contractId: string): Promise<string[]> => {
  return fmsFetchDataList<string>({
    url: `/merchant_css/contract/showFile`,
    method: 'GET',
    params: { contractId },
  });
};

export const getContractSignUrl = async (contractId: string): Promise<string> => {
  return fmsFetchData<string>({
    url: `/merchant_css/contract/jumpToSign`,
    method: 'GET',
    params: { contractId },
  });
};

export const sendContractToMail = async (contractId: string, email: string): Promise<void> => {
  return fmsFetchData<void>({
    url: `/merchant_css/contract/sendContractToMail`,
    method: 'GET',
    params: { contractId: contractId, email: email },
  });
};

export const queryContractBill = async (contractId: string): Promise<Bill> => {
  return fmsFetchData<Bill>({
    url: `/merchant_css/contract/queryContractBill`,
    method: 'GET',
    params: { contractId },
  });
};

export const getSimpleContractList = async (contractIds: string[]): Promise<SimpleContract[]> => {
  return fmsFetchDataList<SimpleContract>({
    url: `/merchant_css/contract/getSimpleContractList`,
    method: 'POST',
    params: { contractIdList: contractIds },
  });
};
