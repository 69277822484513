import { Padding, Placement } from '@popperjs/core';

export const popperConfig = (placement: Placement, arrowElement: Element | null, boundaryPadding?: Padding): any => {
  const modifiers: any[] = [
    { name: 'arrow', options: { element: arrowElement } },
    {
      name: 'offset',
      enabled: true,
      options: {
        offset: [0, 8],
      },
    },
    {
      name: 'computeStyles',
      options: {
        adaptive: false, // true by default
      },
    },
  ];
  if (boundaryPadding !== undefined) {
    modifiers.push({
      name: 'preventOverflow',
      enabled: true,
      options: {
        padding: boundaryPadding,
        tether: false, // keep popover within specified bounds
        altAxis: true, // check both axis
      },
    });
  }
  return {
    placement: placement,
    modifiers,
  };
};
