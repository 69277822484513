const ErrorWhiteIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0003 1.66675C5.40033 1.66675 1.66699 5.40008 1.66699 10.0001C1.66699 14.6001 5.40033 18.3334 10.0003 18.3334C14.6003 18.3334 18.3337 14.6001 18.3337 10.0001C18.3337 5.40008 14.6003 1.66675 10.0003 1.66675ZM10.8337 14.1667H9.16699V12.5001H10.8337V14.1667ZM10.8337 10.8334H9.16699V5.83342H10.8337V10.8334Z"
        fill="white"
      />
    </svg>
  );
};
export default ErrorWhiteIcon;
