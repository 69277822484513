export interface Monitor {
  id: string;
  facilityId?: string;
  facilityName?: string;
  facilityCode?: string;
  kitchenId?: string;
  kitchenCode?: string;
  storeId?: string;
  storeName?: string;
  liveUrlHls?: string;
  liveUrlHlsHd?: string;
  liveUrlRtmp?: string;
  liveUrlRtmpHd?: string;
  deviceSerialNo: string;
  deviceStatus: number;
  channelPicUrl?: string;
  channelId?: string;
  channelName?: string;
  channelNo: number;
  updatedAt?: string;
}

export enum DeviceStatus {
  INACTIVE = 0,
  ACTIVE = 1,
}

export interface EzvizAccountInfo {
  appKey: string;
  token: string;
}

export const getDefaultMonitor: Monitor = {
  id: '',
  channelNo: 1,
  deviceStatus: 1,
  deviceSerialNo: '',
  storeName: 'storeName',
  channelName: 'A01-1',
};
