import * as React from 'react';

function SvgCullinanFilledQuestionMark(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <circle cx={10} cy={10} r={7} fill="#ECECEC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.688 13.3v-1.133H9.412V13.3h1.274zm-.638-6.8C8.641 6.5 7.5 7.514 7.5 8.767h1.275c0-.624.574-1.134 1.275-1.134s1.275.51 1.275 1.134c0 .498-.37.75-.784 1.032-.528.36-1.128.769-1.128 1.801h1.274c0-.62.454-.973.919-1.334.49-.381.994-.772.994-1.5 0-1.252-1.141-2.266-2.55-2.266z"
        fill="#707070"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCullinanFilledQuestionMark);
export default ForwardRef;
