const ActionSuccessIcon: React.FC = () => {
  return (
    <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="36" height="36" rx="18" fill="#46B760" fillOpacity="0.16" />
      <path
        d="M16.0968 20.9062L12.9693 17.7787L11.9043 18.8362L16.0968 23.0287L25.0968 14.0287L24.0393 12.9712L16.0968 20.9062Z"
        fill="#46B760"
      />
    </svg>
  );
};
export default ActionSuccessIcon;
