import styled from 'styled-components';
import React from 'react';
import restaurant from '../../../assets/images/restaurant.png';

interface SignContractHeadProps {
  title: string;
  subtitle: string;
}

const SignContractHead: React.FC<SignContractHeadProps> = ({ title, subtitle }) => {
  return (
    <StyledWrapper picUrl={restaurant}>
      <ShadowWrapper>
        <Slogan>{title}</Slogan>
        <SubTitle>{subtitle}</SubTitle>
      </ShadowWrapper>
    </StyledWrapper>
  );
};

export default SignContractHead;

const StyledWrapper = styled.div<{ picUrl: string }>`
  width: 100%;
  height: 60vh;
  background: url(${({ picUrl }: { picUrl: string }) => (picUrl.length > 0 ? picUrl : '')}) no-repeat 0 0px;
  background-size: 100% 100%;
`;

const ShadowWrapper = styled.div`
  width: 100%;
  background: linear-gradient(170.59deg, rgba(0, 0, 0, 0.48) 24.15%, rgba(0, 0, 0, 0) 55.81%);
`;

const Slogan = styled.div`
  margin-left: 16px;
  padding-top: 15vh;
  box-sizing: border-box;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: #ffffff;
`;

const SubTitle = styled(Slogan)`
  margin-top: 8px;
  padding-top: 0;
  box-sizing: border-box;
  opacity: 0.6;
`;
