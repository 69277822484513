import styled from 'styled-components';
import React from "react";
interface BasicTitleProps {
  title: string;
}

const BasicTitle: React.FC<BasicTitleProps> = ({ title }) => {
  return <StyledWrapper>{title}</StyledWrapper>;
};

export default BasicTitle;

const StyledWrapper = styled.div`
  margin: 24px 16px 16px 16px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
`;
