import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import LocationIcon from '../../../components/icon/LocationIcon';
import MoreBlackIcon from '../../../components/icon/MoreBlackIcon';
import { Contract } from '../../../models/contract';
import kitchen from '../../../assets/images/kitchen.png';
import React from "react";

interface ContractCardProps {
  contract: Contract;
}

const ContractCard: React.FC<ContractCardProps> = ({ contract }) => {
  const history = useHistory();
  return (
    <StyledWrapper>
      <ContractCode>{contract.basic.code}</ContractCode>
      <ContractStatus>{contract.basic.contractStatusName}</ContractStatus>
      <ContentWrapper>
        <LeftWrapper>
          <KitchenImage src={kitchen} alt="kitchen" />
        </LeftWrapper>
        <MiddleWrapper>
          <ContractTitle>
            {contract.basic.kitchenCode} {contract.basic.storeName}
          </ContractTitle>
          <LocationWrapper>
            <LocationIcon />
            <LocationInfo>
              {contract.basic.facilityName} {contract.basic.facilityCode}
            </LocationInfo>
          </LocationWrapper>
          <RentPrice>
            {'付款周期'}: {contract.fee.rentPaymentMonth} {'￥'}
            {contract.fee.rent}/{'月'}
          </RentPrice>
        </MiddleWrapper>
        <RightWrapper onClick={() => history.push(`/contract/basic/${contract.basic.contractId}`)}>
          <MoreBlackIcon />
        </RightWrapper>
      </ContentWrapper>
    </StyledWrapper>
  );
};

export default ContractCard;

const StyledWrapper = styled.div`
  padding: 16px 0 38px 16px;
  background: #ffffff;
`;

const ContractCode = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #a3a3a3;
`;

const ContractTitle = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
`;

const LocationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`;

const LocationInfo = styled.div`
  margin-left: 3px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #8f8f8f;
  align-items: center;
`;

const RentPrice = styled.div`
  margin-top: 8px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #000000;
`;

const ContentWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
`;

const LeftWrapper = styled.div`
  width: auto;
`;

const KitchenImage = styled.img`
  width: 68px;
  height: 68px;
  border-radius: 6px;
`;

const MiddleWrapper = styled.div`
  margin-left: 10px;
  margin-right: auto;
`;

const ContractStatus = styled.div`
  margin-top: 8px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #000000;
`;

const RightWrapper = styled.div`
  margin-right: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
