import styled from 'styled-components';

import MoreBlackIcon from '../../../components/icon/MoreBlackIcon';
import React from "react";

interface DetailCardProps {
  title: string;
  onClick: () => void;
}

const DetailCard: React.FC<DetailCardProps> = ({ title, onClick }) => {
  return (
    <StyledWrapper onClick={onClick}>
      <BoxWrapper>
        <LeftWrapper>{title}</LeftWrapper>
        <RightWrapper>
          <MoreBlackIcon />
        </RightWrapper>
      </BoxWrapper>
    </StyledWrapper>
  );
};

export default DetailCard;

const StyledWrapper = styled.div`
  padding-left: 16px;
  padding-right: 23px;
  background: #ffffff;
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
  border-bottom: 1px solid #f3f5f7;
`;

const LeftWrapper = styled.div`
  margin-right: auto;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'ss06' on;
  color: #000000;
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
`;
