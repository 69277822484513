import { ResizeObserverEntry } from '@juggle/resize-observer';
import { RefCallback, useRef } from 'react';
import { useRefCallback } from '../useRefCallback';
import { GlobalResizeObserver } from './GlobalResizeObserver';

// Performance of ResizeObserver heavily favors a single ResizeObserver instance listening to
// multiple targets rather than separate instances for each target. Therefore, we use a singleton
// to manage the adding and removing of elements as they come and go from the page.
// Ref: https://groups.google.com/a/chromium.org/g/blink-dev/c/z6ienONUb5A/m/F5-VcUZtBAAJ?pli=1
let globalObserver: GlobalResizeObserver | undefined;

export const useResizeObserver = <Element extends HTMLElement = HTMLElement>(
  callback: (entry: ResizeObserverEntry) => void,
  // Web browser default is 'content-box'; but we use 'border-box' everywhere, so this is a more
  // sensible default for us.
  options: ResizeObserverOptions = { box: 'border-box' }
): [RefCallback<Element>, Element | null] => {
  // Instantiate the global observe if it hasn't already been created by some other hook instance
  if (globalObserver === undefined) {
    globalObserver = new GlobalResizeObserver();
  }

  const previousRef = useRef<Element | null>(null);
  return useRefCallback<Element>((node: Element | null) => {
    if (node === null) {
      if (previousRef.current !== null) {
        globalObserver?.removeTarget(previousRef.current);
        previousRef.current = node;
      }
      return;
    }

    if (previousRef.current === null) {
      globalObserver?.addTarget(node, { callback, options });
    } else {
      if (previousRef.current !== null && previousRef.current !== node) {
        globalObserver?.updateTarget(previousRef.current, node);
      }
    }

    // Store the new node for the next pass
    previousRef.current = node;
  });
};
