import React from 'react';
import styled from 'styled-components';

import { LicenseeBalanceAccountHistory, LicenseeBalanceAccountHistoryType } from '../../models/account';
import { formatDate, formatMoney } from '../../utils/utils';

interface AccountCardProps {
  accountHistory: LicenseeBalanceAccountHistory;
}

const AccountCard: React.FC<AccountCardProps> = ({ accountHistory }) => {
  return (
    <StyledWrapper>
      <HistoryWrapper>
        <Title>{accountHistory.remark}</Title>
        <Cycle>{formatDate(accountHistory?.gmtCreated)}</Cycle>
      </HistoryWrapper>
      <Amount>
        {accountHistory.type === LicenseeBalanceAccountHistoryType.DECREASE_AMOUNT && '-'}
        {'￥'}
        {formatMoney(accountHistory.amount)}
      </Amount>
    </StyledWrapper>
  );
};

export default AccountCard;

const StyledWrapper = styled.div`
  width: 100%;
  height: 42px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HistoryWrapper = styled.div``;

const Title = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-feature-settings: 'ss06' on;
  color: #1a1a1a;
`;

const Cycle = styled.div`
  margin-top: 6px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #707070;
`;

const Amount = styled.div`
  margin-left: auto;
  margin-right: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #141414;
`;
