import React, { useContext } from 'react';
import styled from 'styled-components';

import { FmsContext } from '../../context/FmsContext';
import MonitorPlayer from './MonitorPlayer';

export const MonitorReplayPage: React.FC = () => {
  const {
    state: { monitor },
  } = useContext(FmsContext);
  return (
    <StyledWrapper>
      <MonitorPlayer deviceSerial={monitor.deviceSerialNo} channelNo={monitor.channelNo} videoType="rec" />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #f3f5f7;
`;
