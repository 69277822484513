import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { mpTrack, mpTrackError } from '../../common/mixPanel';
import LoadingScreen from '../../components/LoadingScreen';
import OrangeButton from '../../components/OrangeButton';
import WhiteButton from '../../components/WhiteButton';
import ActionSuccessIcon from '../../components/icon/ActionSuccessIcon';
import { sendXcdCampaignCoupon } from '../../services/user';
import { CSS_USER_STORAGE_KEY, getCssUserIdFromStorage } from '../../utils/token';
import { parseQueryString } from '../../utils/urlHelper';
import { PrintPaperDialog } from './PrintPaperDialog';

export const PaySuccessPage: React.FC = () => {
  const [cssUserId, setCssUserId] = useState('');
  const history = useHistory();
  const [isSplit, setIsSplit] = useState<boolean>();
  const [payDetailId, setPayDetailId] = useState<string>('');
  const [adDialogVisible, setAdDialogVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [couponCode, setCouponCode] = useState<string>('');

  useEffect(() => {
    const params = parseQueryString();
    setPayDetailId(params.get('payDetailId') || '');
    setIsSplit('Y' === params.get('isSplit'));
    setAdDialogVisible(true);
  }, []);

  useEffect(() => {
    const data = getCssUserIdFromStorage(CSS_USER_STORAGE_KEY);
    if (data) {
      setCssUserId(data);
    } else {
      mpTrackError('getCssUserIdFromStorage', { error: 'Failed to getCssUserIdFromStorage by localStorage' });
    }
  }, []);

  // useEffect(() => {
  //   setLoading(true);
  //   console.log('cssUserId', cssUserId);
  //   console.log('payDetailId', payDetailId);
  //   if (payDetailId && cssUserId) {
  //     sendXcdCampaignCoupon(cssUserId, payDetailId)
  //       .then((res) => {
  //         console.log('sendXcdCampaignCoupon', JSON.stringify(res));
  //         if (res) {
  //           setCouponCode(res);
  //           setAdDialogVisible(true);
  //         }
  //         mpTrack('xcdCampaign', {
  //           userId: cssUserId,
  //           action: 'dialog',
  //           response: res,
  //         });
  //       })
  //       .catch((res) => {
  //         mpTrack('xcdCampaign', {
  //           userId: cssUserId,
  //           action: 'error',
  //           response: res,
  //         });
  //       })
  //       .finally(() => {
  //         // setXcdDialogVisible(false);
  //         setLoading(false);
  //       });
  //   }
  // }, [setLoading, cssUserId, payDetailId]);

  // if (loading) {
  //   return <LoadingScreen />;
  // }

  return (
    <PageContainer>
      <ActionSuccessIcon />
      <MainMsg>支付成功</MainMsg>
      {isSplit ? (
        <>
          <SubMsg>完成部分支付，请接着去支付剩余账单</SubMsg>
          <GoPayButton>去支付</GoPayButton>
          <ReturnLink onClick={() => history.goBack()}>返回</ReturnLink>
        </>
      ) : (
        <>
          <SubMsg>你已经成功完成支付。</SubMsg>
          <SubMsg>如有问题，请联系门店店长</SubMsg>
          <GoBackButton onClick={() => history.go(-2)}>返回</GoBackButton>
        </>
      )}
      {adDialogVisible && (
        <PrintPaperDialog userId={cssUserId} onClose={() => setAdDialogVisible(false)} />
      )}
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  min-height: 100vh;
  font-family: 'PingFang SC';
`;

const MainMsg = styled.div`
  height: 28px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  font-feature-settings: 'ss06' on;
  color: #000000;
  margin: 16px 0 12px 0;
`;

const SubMsg = styled.div`
  height: 22px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  font-feature-settings: 'ss06' on;
  color: rgba(22, 24, 35, 0.75);
`;

const ReturnLink = styled.div`
  height: 22px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'ss06' on;
  color: #141414;
  flex: none;
  margin: 11px;
`;

const GoPayButton = styled(OrangeButton)`
  width: 191px;
  height: 44px;
  flex: none;
  margin: 12px;
`;

const GoBackButton = styled(WhiteButton)`
  width: 191px;
  height: 44px;
  flex: none;
  margin-top: 28px;
`;
