const Icon: React.FC = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.25 1.91663L13 0.666626L11.75 1.91663L10.5 0.666626L9.25 1.91663L8 0.666626L6.75 1.91663L5.5 0.666626L4.25 1.91663L3 0.666626L1.75 1.91663L0.5 0.666626V17.3333L1.75 16.0833L3 17.3333L4.25 16.0833L5.5 17.3333L6.75 16.0833L8 17.3333L9.25 16.0833L10.5 17.3333L11.75 16.0833L13 17.3333L14.25 16.0833L15.5 17.3333V0.666626L14.25 1.91663ZM13.8333 14.9083H2.16667V3.09163H13.8333V14.9083ZM3 11.5H13V13.1666H3V11.5ZM3 8.16663H13V9.83329H3V8.16663ZM3 4.83329H13V6.49996H3V4.83329Z"
        fill="black"
      />
    </svg>
  );
};
export default Icon;
