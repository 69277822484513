import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';

import { UtilitiesBalanceAccountHistory, UtilitiesBalanceAccountHistoryType } from '../../models/account';
import { formatMoney, getYearMonthString, moneyToNumber } from '../../utils/utils';

interface UtilitiesBalanceHistoryChartProps {
  currentDate: string;
  historyList: UtilitiesBalanceAccountHistory[];
}

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipWrapper>
        {'￥'}
        {payload[0].value}
      </TooltipWrapper>
    );
  }
  return null;
};

interface ChartItem {
  date: string;
  amount: number;
}

const getChartData = (utilitiesBalanceAccountHistory: UtilitiesBalanceAccountHistory[]) => {
  const list: ChartItem[] = [];
  utilitiesBalanceAccountHistory.map((history) => {
    if (history.type === UtilitiesBalanceAccountHistoryType.DAILY_COST) {
      list.push({
        date: moment(history.timestamp).format('D') + '日',
        amount: moneyToNumber(history.value),
      });
    }
  });
  return sortList(list);
};

const sortList = (list: ChartItem[]) => {
  for (let i = 0; i < list.length; i++) {
    for (let j = i + 1; j < list.length; j++) {
      if (Number(list[i].date) < Number(list[j].date)) {
        const tmp = list[i];
        list[i] = list[j];
        list[j] = tmp;
      }
    }
  }
  return list;
};

export const UtilitiesBalanceHistoryChart: React.FC<UtilitiesBalanceHistoryChartProps> = ({
  currentDate,
  historyList,
}) => {
  const [chartData, setChartData] = useState<ChartItem[]>([]);
  const [summary, setSummary] = useState<number>(0);
  useEffect(() => {
    setChartData(getChartData(historyList));
  }, [historyList]);

  useEffect(() => {
    const total = chartData.reduce((previous, current) => {
      return previous + current.amount;
    }, 0);
    setSummary(total);
  }, [chartData]);

  return (
    <ChartWrapper>
      <ChartTitle>
        {getYearMonthString(currentDate)}
        {'累计扣款'}: {'￥'}
        {formatMoney(summary)}
      </ChartTitle>
      <ResponsiveContainer width="100%" height="85%">
        <LineChart
          data={chartData}
          margin={{
            top: 37,
            right: 16,
            left: 16,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="1 0" horizontal={true} vertical={false} stroke="#E0E0E0" opacity="0.2" />
          <XAxis
            axisLine={true}
            tick={{ style: XAxisWrapper }}
            tickLine={false}
            dataKey="date"
            stroke="#D6D6D6"
            padding={{ left: 15, right: 15 }}
            interval={'preserveStartEnd'}
          />
          <Tooltip content={CustomTooltip} />
          <Line dataKey="amount" fill="#8361FD" stroke="#FAFAFA" activeDot={{ r: 8 }} strokeWidth="2" />
        </LineChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};

const ChartWrapper = styled.div`
  height: 292px;
`;

const TooltipWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 72px;
  height: 33px;
  background: #000000;
  border-radius: 4px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
`;

const ChartTitle = styled.div`
  padding: 32px 0 0 16px;
  box-sizing: border-box;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #d6d6d6;
`;

const XAxisWrapper = {
  fontFamily: 'PingFang SC',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '0.01em',
  color: '#D6D6D6',
};
