import React from 'react';
import styled from 'styled-components';

import SelectGrayIcon from './icon/SelectGrayIcon';

interface SelectGroupProps {
  title: string;
  onClick?: () => void;
}

const SelectGroup: React.FC<SelectGroupProps> = ({ title, onClick }) => {
  return (
    <StyledWrapper onClick={onClick}>
      <TitleWrapper>
        <Title>{title}</Title>
      </TitleWrapper>
      <SelectWrapper>
        <SelectGrayIcon />
      </SelectWrapper>
    </StyledWrapper>
  );
};

export default SelectGroup;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 1px solid #e0e0e0;
  border-radius: 99px;
  background: #ffffff;

  min-width: 112px;
  max-width: 200px;
  height: 32px;
`;
const TitleWrapper = styled.div`
  margin-left: 16px;
  margin-right: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Title = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3d3d3d;
`;

const SelectWrapper = styled.div`
  margin-top: -4px;
  margin-right: 12px;
`;
