import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import { Tooltip } from '../../components/op-components';

import ErrorScreen, { Error } from '../../components/ErrorScreen';
import LoadingScreen from '../../components/LoadingScreen';
import SelectGroup from '../../components/SelectGroup';
import SelectBlackIcon from '../../components/icon/SelectBlackIcon';
import { FmsContext } from '../../context/FmsContext';
import {
  UtilitiesBalanceAccount,
  UtilitiesBalanceAccountHistory,
  UtilitiesBalanceAccountHistoryType,
} from '../../models/account';
import { getUtilitiesBalanceChangeLogs } from '../../services/account';
import {
  DATETIME_FORMAT_TZ,
  DATETIME_YEAR_MONTH,
  getHistoryTypeString,
  getYearMonthString,
  reload,
} from '../../utils/utils';
import { SelectAccountDialog } from './SelectAccountDialog';
import { SelectDateDialog } from './SelectDateDialog';
import { SelectHistoryTypeDialog } from './SelectHistoryTypeDialog';
import { UtilitiesBalanceHistoryChart } from './UtilitiesBalanceHistoryChart';
import { UtilitiesHistoryCard } from './UtilitiesHistoryCard';

export const UtilitiesHistoryPage: React.FC = () => {
  const {
    state: { utilitiesBalanceAccount, utilitiesBalanceAccountList },
  } = useContext(FmsContext);

  const [currentAccount, setCurrentAccount] = useState<UtilitiesBalanceAccount>(utilitiesBalanceAccount);
  const [currentDate, setCurrentDate] = useState<string>(moment().format(DATETIME_YEAR_MONTH));
  const [currentHistoryType, setCurrentHistoryType] = useState<UtilitiesBalanceAccountHistoryType>(
    UtilitiesBalanceAccountHistoryType.ALL
  );
  const [currentHistoryList, setCurrentHistoryList] = useState<UtilitiesBalanceAccountHistory[]>();

  const [showSelectAccount, setShowSelectAccount] = useState<boolean>(false);
  const [showSelectDate, setShowSelectDate] = useState<boolean>(false);
  const [showSelectHistoryType, setShowSelectHistoryType] = useState<boolean>(false);

  const [utilitiesBalanceHistoryList, setUtilitiesBalanceHistoryList] = useState<UtilitiesBalanceAccountHistory[]>();
  const [startTime, setStartTime] = useState<string>();
  const [endTime, setEndTime] = useState<string>();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    if (currentDate) {
      const fmt = moment(currentDate, DATETIME_YEAR_MONTH);
      setStartTime(fmt.startOf('month').format(DATETIME_FORMAT_TZ));
      setEndTime(fmt.endOf('month').format(DATETIME_FORMAT_TZ));
    }
  }, [currentDate, setStartTime, setEndTime]);

  useEffect(() => {
    if (currentAccount && currentAccount.id && startTime && endTime) {
      setLoading(true);
      getUtilitiesBalanceChangeLogs(currentAccount.id, startTime, endTime)
        .then((res) => {
          if (res) {
            setUtilitiesBalanceHistoryList(res);
          } else {
            setError({ type: 'EMPTY', message: '当前门店暂无水电费交易记录' });
          }
        })
        .catch((res) => {
          setError({ type: 'UNKNOWN', message: res.message });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [currentAccount, startTime, endTime, setUtilitiesBalanceHistoryList, setLoading, setError]);

  useEffect(() => {
    if (utilitiesBalanceHistoryList && currentHistoryType) {
      if (currentHistoryType === UtilitiesBalanceAccountHistoryType.RECHARGE) {
        setCurrentHistoryList(
          utilitiesBalanceHistoryList.filter((e) => e.type === UtilitiesBalanceAccountHistoryType.RECHARGE)
        );
      } else if (currentHistoryType === UtilitiesBalanceAccountHistoryType.DAILY_COST) {
        setCurrentHistoryList(
          utilitiesBalanceHistoryList.filter((e) => e.type === UtilitiesBalanceAccountHistoryType.DAILY_COST)
        );
      } else {
        setCurrentHistoryList(utilitiesBalanceHistoryList);
      }
    }
  }, [utilitiesBalanceHistoryList, currentHistoryType, setCurrentHistoryList]);

  if (error) {
    return (
      <ErrorScreen
        message={error.message}
        errorType={error.type}
        fullScreen={true}
        retry={error.type === 'UNKNOWN'}
        onRetry={reload}
      />
    );
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <StyledWrapper>
      <HeadWrapper>
        <UtilitiesBalanceHistoryChart historyList={utilitiesBalanceHistoryList || []} currentDate={currentDate} />
      </HeadWrapper>
      <ContextWrapper>
        <FilterWrapper>
          {utilitiesBalanceAccountList && (
            <SelectGroup
              title={`${currentAccount.kitchenCode}${currentAccount.storeName}`}
              onClick={() => setShowSelectAccount(true)}
            />
          )}
          {showSelectAccount && (
            <SelectAccountDialog
              title={'选择厨房'}
              accountId={currentAccount.id}
              selectUtilitiesBalanceAccount={setCurrentAccount}
              utilitiesBalanceAccountList={utilitiesBalanceAccountList}
              onClose={() => setShowSelectAccount(false)}
            />
          )}
          <SelectWrapper>
            <SelectGroup
              title={getHistoryTypeString(currentHistoryType)}
              onClick={() => setShowSelectHistoryType(true)}
            />
          </SelectWrapper>
          {showSelectHistoryType && (
            <SelectHistoryTypeDialog
              title={'选择类型'}
              historyType={currentHistoryType}
              selectHistoryType={setCurrentHistoryType}
              onClose={() => setShowSelectHistoryType(false)}
            />
          )}
        </FilterWrapper>
        <AccountHistoryWrapper>
          <AccountHistoryTitleWrapper onClick={() => setShowSelectDate(true)}>
            <AccountHistoryTitle>{getYearMonthString(currentDate)}</AccountHistoryTitle>
            <SelectBlackIcon />
          </AccountHistoryTitleWrapper>
          <Tooltip
            tooltipText="电费一栏的金额可能包含了损耗、用电服务、电力设施维护费等"
            placement={'auto-start'}
            inverse={true}
          />
        </AccountHistoryWrapper>
        {showSelectDate && (
          <SelectDateDialog
            title={'选择月份'}
            date={currentDate}
            selectDate={setCurrentDate}
            onClose={() => setShowSelectDate(false)}
          />
        )}

        <AccountHistoryCardWrapper>
          {(currentHistoryList || []).map((history: UtilitiesBalanceAccountHistory, index: number) => (
            <UtilitiesHistoryCard key={index} history={history} />
          ))}
        </AccountHistoryCardWrapper>
      </ContextWrapper>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const HeadWrapper = styled.div`
  width: 100%;
  height: 350px;
  background: #8361fd;
`;

const ContextWrapper = styled.div`
  height: ${window.innerHeight - 350}px;
  margin-top: -58px;
  background: #ffffff;
  border-radius: 12px;
  padding: 32px 24px 10px 24px;
  box-sizing: border-box;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 32px;
`;

const SelectWrapper = styled.div`
  margin-left: 12px;
`;

const AccountHistoryCardWrapper = styled.div`
  margin-top: 34px;
`;

const AccountHistoryWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const AccountHistoryTitleWrapper = styled.div`
  width: 120px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const AccountHistoryTitle = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  font-feature-settings: 'ss06' on;
  color: #0f0f0f;
`;
