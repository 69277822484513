import { css } from 'styled-components';

import { Space, StyledSpaceUtil } from './types';

/**
 * @deprecated Use `padding()` from `@css/csds-components` instead.
 */
export const padding: StyledSpaceUtil =
  (s) =>
  ({ theme }) =>
    css`
      padding: ${theme.space[s as Space] || s};
    `;
padding.t =
  (s) =>
  ({ theme }) =>
    css`
      padding-top: ${theme.space[s as Space] || s};
    `;
padding.l =
  (s) =>
  ({ theme }) =>
    css`
      padding-left: ${theme.space[s as Space] || s};
    `;
padding.b =
  (s) =>
  ({ theme }) =>
    css`
      padding-bottom: ${theme.space[s as Space] || s};
    `;
padding.r =
  (s) =>
  ({ theme }) =>
    css`
      padding-right: ${theme.space[s as Space] || s};
    `;
padding.x =
  (s) =>
  ({ theme }) =>
    css`
      padding-right: ${theme.space[s as Space] || s};
      padding-left: ${theme.space[s as Space] || s};
    `;
padding.y =
  (s) =>
  ({ theme }) =>
    css`
      padding-top: ${theme.space[s as Space] || s};
      padding-bottom: ${theme.space[s as Space] || s};
    `;
