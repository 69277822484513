import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import kitchen from '../../assets/images/kitchen.png';
import { SimpleContract } from '../../models/contract';

interface HomeContractCardProps {
  contract: SimpleContract;
  narrow: boolean;
  lastElement: boolean;
}

const HomeContractCard: React.FC<HomeContractCardProps> = ({ contract, narrow, lastElement }) => {
  const history = useHistory();
  return (
    <StyledWrapper narrow={narrow} lastElement={lastElement}>
      <ContractCode>
        {'合同编号'}: {contract.code}
      </ContractCode>
      <ContentWrapper onClick={() => history.push(`/contract/${contract.contractId}`)}>
        <KitchenImageWrapper>
          <KitchenImage src={kitchen} alt="kitchen" />
        </KitchenImageWrapper>
        <DetailWrapper>
          <KitchenCode>{contract.kitchenCode}</KitchenCode>
          <StoreName>{contract.storeName}</StoreName>
          <ContractStatus>{contract.contractStatusName}</ContractStatus>
        </DetailWrapper>
      </ContentWrapper>
    </StyledWrapper>
  );
};

export default HomeContractCard;
const StyledWrapper = styled.div<{ narrow: boolean; lastElement: boolean }>`
  width: ${({ narrow }) => (narrow ? '80%' : 'calc(100% - 32px)')};
  height: 179px;
  background: #ffffff;
  border-radius: 12px;
  margin: 24px ${({ lastElement }) => (lastElement ? 16 : 0)}px 26px 16px;
  padding-left: 16px;
  box-sizing: border-box;
  flex: none;
`;

const ContractCode = styled.div`
  margin-top: 24px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #525252;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;

const KitchenImageWrapper = styled.div`
  width: 90px;
  height: 90px;
`;

const KitchenImage = styled.img`
  border-radius: 8px;
`;

const DetailWrapper = styled.div`
  margin-left: 15px;
`;

const KitchenCode = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #000000;
`;

const StoreName = styled.div`
  margin-top: 7px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #8f8f8f;
`;

const ContractStatus = styled.div`
  box-sizing: border-box;
  padding-left: 18px;
  padding-right: 18px;

  width: fit-content;
  min-width: 73px;

  margin-top: 14px;
  height: 26px;
  background: rgba(206, 109, 80, 0.1);
  border-radius: 12px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #bf5636;
`;
