import React, { createContext } from 'react';

interface LoginContextProps {
  setUrl: (url: string) => void;
  getUrl: () => string;
}

const store = {
  url: '/',
};

const defaultContext = {
  setUrl: (url: string) => {
    store.url = url;
  },
  getUrl: () => {
    return store.url;
  },
};

export const LoginContext = createContext<LoginContextProps>(defaultContext);

export const LoginContextProvider: React.FC = ({ children }) => {
  return <LoginContext.Provider value={defaultContext}>{children}</LoginContext.Provider>;
};
