const BellIcon: React.FC = () => {
  return (
    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.78847 12.875H3.08014L3.78847 7.91667H1.30931C0.685972 7.91667 1.07556 7.38542 1.08972 7.36417C2.00347 5.74917 3.37764 3.34083 5.21222 0.125H5.92056L5.21222 5.08333H7.69847C7.98181 5.08333 8.13764 5.21792 7.98181 5.55083C5.18389 10.4312 3.78847 12.875 3.78847 12.875Z"
        fill="white"
      />
    </svg>
  );
};
export default BellIcon;
