import styled from 'styled-components';

const PurpleButton = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  flex: 1 0 auto;
  min-width: 91px;
  height: 30px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100px;

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'ss06' on;
  color: ${({ selected }) => (selected ? '#FFFFFF' : 'rgba(255, 255, 255, 0.2)')};
`;
export default PurpleButton;
