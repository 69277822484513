export type Money = {
  currencyCode: string;
  units: number;
  nanos: number;
};

export interface LicenseeBalanceAccount {
  id: string;
  memberId: string;
  balance: number;
  status?: number;
  remark?: string;
  gmtCreated?: Date;
  gmtModified?: Date;
}

export interface LicenseeBalanceAccountHistory {
  id: string;
  accountId: string;
  type: number;
  amount: number;
  remark?: string;
  attach?: string;
  operator?: string;
  gmtCreated?: Date;
  gmtModified?: Date;
}

export enum LicenseeBalanceAccountHistoryType {
  CREATE_ACCOUNT = 1,
  FREEZE_ACCOUNT = 2,
  ENABLE_ACCOUNT = 3,
  INCREASE_AMOUNT = 4,
  DECREASE_AMOUNT = 5,
}

export const defaultLicenseeBalanceAccount: LicenseeBalanceAccount = {
  id: '',
  memberId: '',
  balance: 0,
};

export const defaultAccountHistory: LicenseeBalanceAccountHistory = {
  id: '',
  accountId: '',
  type: 1,
  amount: 10000.01,
  remark: '',
};

export interface UtilitiesBalanceAccount {
  id: string;
  contractId: string;
  facilityId?: string;
  kitchenId?: string;
  facilityCode?: string;
  facilityName?: string;
  kitchenCode?: string;
  storeName?: string;
  balance: Money;
  status?: string;
  isEnabled: boolean;
  enabledAt?: string;
}

export interface UtilitiesBalanceAccountHistory {
  type: string;
  value: Money;
  balance: Money;
  timestamp: string;
}

export enum UtilitiesBalanceAccountHistoryType {
  ALL = 'ALL',
  DAILY_COST = 'DAILY_COST',
  RECHARGE = 'RECHARGE',
}

export const defaultUtilitiesBalanceAccount: UtilitiesBalanceAccount = {
  id: '',
  contractId: '',
  balance: {
    currencyCode: 'CNY',
    units: 0,
    nanos: 0,
  },
  isEnabled: true,
};
