import * as React from 'react';

function SvgClose(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.607 4.293a1 1 0 00-1.415 0l-6.242 6.243-6.243-6.243a1 1 0 00-1.414 1.414l6.243 6.243-6.243 6.242a1 1 0 001.414 1.415l6.243-6.243 6.242 6.243a1 1 0 001.415-1.415l-6.243-6.242 6.243-6.243a1 1 0 000-1.414z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgClose);
export default ForwardRef;
