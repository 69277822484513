import { createConfigWithDerivedApiUrl, getEnvFromHostname } from '../components/op-url-config';

interface AppConfig {
  env?: string;
  APP_NAME: string;
  API_URL: string;
  FMS_API_URL: string;
  AUTH_API_URL: string;
  OTTER_APP_ASSETS: string;
  OTTER_MALL: string;
  MIXPANEL_PROJECT_TOKEN: string;
  MIXPANEL_PROJECT_DEBUG: boolean;
  APP_VERSION: string;
}

const envFromHostname = getEnvFromHostname();
export const isProductionEnvironment = envFromHostname === 'production';

let envConfig = {};
// let env = 'dev';
try {
  switch (envFromHostname) {
    case 'production':
      envConfig = require('./config-production.json');
      break;
    case 'staging':
      envConfig = require('./config-staging.json');
      break;
    default:
      envConfig = require('./config-dev.json');
      break;
  }
} catch (err) {
  console.error(err);
}

export const getEnvName = (): string => envFromHostname;

const defaultConfig: AppConfig = {
  // Add any dev or default configs here, but note that they will be overridden
  // by values in `src/config.json` if present.
  APP_NAME: 'star-chef-web',
  API_URL: 'https://api-v2.shuitazhanggui.com',
  AUTH_API_URL: 'https://api-v2.shuitazhanggui.com',
  FMS_API_URL: 'https://fms.csschina.com',
  OTTER_APP_ASSETS: 'https://otterapp-assets.csschina.com',
  OTTER_MALL: 'https://otter-mall.csschina.com',
  MIXPANEL_PROJECT_TOKEN: '5b524cb0cbb07ff07a285b264d142665',
  MIXPANEL_PROJECT_DEBUG: false,
  APP_VERSION: '1.0.0',
};

const getApiUrlByHostname = (env: typeof envFromHostname): { API_URL?: string } => {
  if (env === 'local') {
    //use envConfig
    return {};
  }
  const apiUrlConfig = {
    API_URL: { ...defaultConfig, ...envConfig }.API_URL,
    // in production env, if domain name is bitime.cn use api-v2.shuitazhanggui.com, use api.xxx.com for others domain name
    API_SUBDOMAIN:
      env === 'production'
        ? window.location.hostname.endsWith('.shuitazhanggui.com')
          ? 'api-v2'
          : 'api'
        : 'api-staging',
  };
  const { API_URL } = createConfigWithDerivedApiUrl(apiUrlConfig);
  return {
    API_URL,
  };
};

const apiUrlByHostname = getApiUrlByHostname(envFromHostname);

const appConfig: AppConfig = {
  ...defaultConfig,
  ...envConfig,
  env: envFromHostname,
};

export default appConfig;
