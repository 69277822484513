const LocationIcon: React.FC = () => {
  return (
    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 0.25C2.08125 0.25 0.125 2.20625 0.125 4.625C0.125 5.7125 0.4375 6.73125 1.00625 7.65C1.6 8.6125 2.38125 9.4375 2.98125 10.4C3.275 10.8687 3.4875 11.3062 3.7125 11.8125C3.875 12.1562 4.00625 12.75 4.5 12.75C4.99375 12.75 5.125 12.1562 5.28125 11.8125C5.5125 11.3062 5.71875 10.8687 6.0125 10.4C6.6125 9.44375 7.39375 8.61875 7.9875 7.65C8.5625 6.73125 8.875 5.7125 8.875 4.625C8.875 2.20625 6.91875 0.25 4.5 0.25ZM4.5 6.34375C3.6375 6.34375 2.9375 5.64375 2.9375 4.78125C2.9375 3.91875 3.6375 3.21875 4.5 3.21875C5.3625 3.21875 6.0625 3.91875 6.0625 4.78125C6.0625 5.64375 5.3625 6.34375 4.5 6.34375Z"
        fill="#A3A3A3"
      />
    </svg>
  );
};
export default LocationIcon;
