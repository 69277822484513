import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import WhiteButton from '../../components/WhiteButton';
import ActionFailIcon from '../../components/icon/ActionFail';

export const PayFailPage: React.FC = () => {
  const history = useHistory();
  return (
    <PageContainer>
      <ActionFailIcon />
      <MainMsg>支付失败</MainMsg>
      <SubMsg>好像哪里出现了问题...</SubMsg>
      <SubMsg>如需帮助，请联系门店店长</SubMsg>
      <GoBackButton onClick={() => history.go(-1)}>返回重试</GoBackButton>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  min-height: 100vh;
  font-family: 'PingFang SC';
`;

const MainMsg = styled.div`
  height: 28px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  font-feature-settings: 'ss06' on;
  color: #000000;
  margin: 16px 0 12px 0;
`;

const SubMsg = styled.div`
  height: 22px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  font-feature-settings: 'ss06' on;
  color: rgba(22, 24, 35, 0.75);
`;

const GoBackButton = styled(WhiteButton)`
  width: 191px;
  height: 44px;
  flex: none;
  margin-top: 28px;
`;
