import styled from 'styled-components';
import React from "react";

interface BasicRowProps {
  name: string;
  value: string;
}

const BasicRow: React.FC<BasicRowProps> = ({ name, value }) => {
  return (
    <StyledWrapper>
      <LeftWrapper>{name}</LeftWrapper>
      <RightWrapper>{value}</RightWrapper>
    </StyledWrapper>
  );
};

export default BasicRow;

const StyledWrapper = styled.div`
  margin: 0px 16px 6px 16px;
  display: flex;
  flex-direction: row;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #3d3d3d;
`;

const LeftWrapper = styled.div`
  margin-left: 0px;
`;

const RightWrapper = styled.div`
  margin-left: auto;
  margin-right: 0px;
`;
