import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ChevronRight } from '../../components/op-icons';

import { routingPaths } from '../../common/constants';
import { mpTrack } from '../../common/mixPanel';
import { BlankBox } from '../../components/BlankBox';
import { BottomButtonGroup } from '../../components/BottomButtonGroup';
import ErrorScreen, { Error } from '../../components/ErrorScreen';
import LoadingDialog from '../../components/LoadingDialog';
import LoadingScreen from '../../components/LoadingScreen';
import Toast, { ToastMessage } from '../../components/Toast';
import BillDetailIcon from '../../components/icon/BillDetailIcon';
import WalletIcon from '../../components/icon/WalletIcon';
import { LicenseeBalanceAccount, defaultLicenseeBalanceAccount } from '../../models/account';
import { Bill, ConfirmStatus, PaymentRecord, PayStatus } from '../../models/bill';
import { PayChannelEnum, PayModelEnum } from '../../models/pay';
import { getLicenseeBalanceAccountDetail } from '../../services/account';
import { confirmBillById, getBillDetailById } from '../../services/bill';
import { prePay } from '../../services/pay';
import { getFmsTokenFromStorage } from '../../services/user';
import { parseQueryString } from '../../utils/urlHelper';
import { DATE_ONLY_FORMAT, formatDate, numberFix2, TIME_ONLY_FORMAT } from '../../utils/utils';
import { BillDetailDialog } from './BillDetailDialog';

export const BillWaitPayPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [payLoading, setPayLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [bill, setBill] = useState<Bill>();
  const [currencySymbol, setCurrencySymbol] = useState<string>('');
  const [toastMessage, setToastMessage] = useState<ToastMessage>();
  const [licenseeBalanceAccount, setLicenseeBalanceAccount] =
    useState<LicenseeBalanceAccount>(defaultLicenseeBalanceAccount);
  const [billDetailVisible, setBillDetailVisible] = useState<boolean>(false);
  const history = useHistory();

  const payButtonDisabled = useMemo<boolean>(() => {
    if (!bill || bill.confirmStatus === ConfirmStatus.TO_BE_CONFIRM || bill.confirmStatus === ConfirmStatus.UNKNOWN) {
      return true;
    }
    return false;
  }, [bill]);

  useEffect(() => {
    const params = parseQueryString();

    const billIdInParam = params.get('billId');
    if (!billIdInParam) {
      setError({ type: 'BROKEN', message: 'No bill ID' });
    } else {
      getBillDetailById(billIdInParam)
        .then((billRes) => {
          // if not confirmed, confirm first
          if (billRes.confirmStatus === ConfirmStatus.TO_BE_CONFIRM) {
            return confirmBillById(billIdInParam)
              .then(() => {
                setToastMessage({ type: 'success', message: '账单已确认' });
              })
              .then(() => getBillDetailById(billIdInParam))
              .catch((res) => {
                setToastMessage({ type: 'error', message: res.message });
                return billRes;
              });
          } else {
            return billRes;
          }
        })
        .then((billRes) => {
          getLicenseeBalanceAccountDetail(billRes.contractId)
            .then((res) => {
              setLicenseeBalanceAccount(res);
            })
            .catch((res) => {
              setToastMessage({ type: 'error', message: res.message });
            });
          return billRes;
        })
        .then((billRes) => {
          setLoading(false);
          if (!billRes.isCanPay) {
            setError({ type: 'BROKEN', message: '不能支付' });
            return;
          }
          if (billRes.confirmStatus === ConfirmStatus.TO_BE_CONFIRM) {
            setError({ type: 'BROKEN', message: '订单未确认' });
            return;
          }
          if (billRes.payStatus === PayStatus.FULLY_PAID) {
            setError({ type: 'BROKEN', message: '请勿重复支付' });
            return;
          }
          setBill(billRes);
          setCurrencySymbol(billRes.remainAmount.substring(0, 1));
        })
        .catch(() => {
          setError({ type: 'BROKEN', message: '获取账单信息失败' });
        });
    }
  }, []);

  const deductionAmount = useMemo(() => {
    if (!bill || !licenseeBalanceAccount) {
      return 0;
    }
    const remainAmount = parseFloat(bill.remainAmount.substring(1));
    const balanceAmount = licenseeBalanceAccount.balance;
    if (balanceAmount >= remainAmount) {
      return numberFix2(remainAmount);
    }
    return numberFix2(balanceAmount);
  }, [bill, licenseeBalanceAccount]);

  const needPayAmount = useMemo(() => {
    if (!bill || !licenseeBalanceAccount) {
      return 0;
    }
    const remainAmount = parseFloat(bill.remainAmount.substring(1));
    const balanceAmount = licenseeBalanceAccount.balance;
    if (balanceAmount >= remainAmount) {
      return 0;
    }
    return numberFix2(remainAmount - balanceAmount);
  }, [bill, licenseeBalanceAccount]);

  const onlinePay = useCallback(() => {
    if (!bill) {
      return;
    }
    const remainAmount = parseFloat(bill.remainAmount.substring(1));
    history.push(
      `${routingPaths.pay}?billId=${bill?.id}&needPayAmount=${remainAmount}&currencySymbol=${currencySymbol}`
    );
  }, [history, bill, currencySymbol]);
  const offlinePay = useCallback(() => {
    if (!bill) {
      return;
    }
    const remainAmount = parseFloat(bill.remainAmount.substring(1));
    history.push(
      `${routingPaths.offlinePay}?billId=${bill?.id}&needPayAmount=${remainAmount}&currencySymbol=${currencySymbol}`
    );
  }, [history, bill, currencySymbol]);
  const deductionPay = useCallback(() => {
    if (!bill) {
      return;
    }
    // No need to do online pay
    // but we need to call prepay once
    setPayLoading(true);
    const remainAmount = parseFloat(bill.remainAmount.substring(1));
    prePay({
      billId: bill.id,
      payAmount: `${remainAmount.toFixed(2)}`,
      isSplit: 'N',
      payModel: PayModelEnum.ONLINE,
      payChannel: PayChannelEnum.ALIPAY,
      cssToken: getFmsTokenFromStorage(),
    })
      .then((res) => {
        history.push(`${routingPaths.paySuccess}?payDetailId=${res.payDetailId || ''}&isSplit=N`);
      })
      .catch((e) => {
        history.push(`${routingPaths.payFail}?errorMsg=${e.returnMsg || ''}`);
        mpTrack('error', { name: '支付失败', errorMsg: e.returnMsg || '' });
      });
  }, [history, bill]);

  const goToAccount = useCallback(() => {
    history.push(routingPaths.account);
  }, [history]);

  if (error) {
    return (
      <ErrorScreen message={error.message} errorType={error.type} fullScreen={true} retry={error.type === 'UNKNOWN'} />
    );
  }

  if (loading) {
    return <LoadingScreen />;
  }

  if (payLoading) {
    return <LoadingDialog />;
  }

  return (
    <PageContainer>
      <ScrollableDiv>
        <HeaderBG>
          <HeaderBGElem1 />
          <HeaderBGElem2 />
        </HeaderBG>
        <PageHeader>
          <Toast
            visible={!!toastMessage}
            type={toastMessage?.type || 'error'}
            message={toastMessage?.message || ''}
            onHide={() => setToastMessage(undefined)}
          />
          <PayCard>
            <PayCardLabel>{'等待支付'}</PayCardLabel>
            <PayCardAmount>{bill?.remainAmount}</PayCardAmount>
            <PayCardDesc>
              {bill?.billTypeName || ''}
              {' · '}
              {bill?.kitchenCode || ''} {bill?.storeName || ''}
            </PayCardDesc>
          </PayCard>
        </PageHeader>
        <PageContent>
          {bill?.paymentList && <PaymentHistoryCard paymentList={bill.paymentList} currencySymbol={currencySymbol} />}
          <LinkCard
            icon={<WalletIcon />}
            label={'余额可抵扣'}
            subLabel={<BalanceDeductionLabel currencySymbol={currencySymbol} deductionAmount={deductionAmount} />}
            onClick={goToAccount}
          />
          <LinkCard
            icon={<BillDetailIcon />}
            label={'账单明细'}
            subLabel={<BillTotalLabel>{bill?.amount}</BillTotalLabel>}
            onClick={() => setBillDetailVisible(true)}
          />
          {billDetailVisible && (
            <BillDetailDialog bill={bill} onClose={() => setBillDetailVisible(false)} showPaymentBottom={false} />
          )}
        </PageContent>
        <BlankBox height="116px" width="100%" />
      </ScrollableDiv>

      <BottomBar>
        <BottomBarLeftText>{'需要支付'}</BottomBarLeftText>
        <BottomBarRightText>
          {currencySymbol}
          {needPayAmount}
        </BottomBarRightText>
      </BottomBar>
      {needPayAmount > 0 ? (
        <BottomButtonGroup
          primary={{ text: '去支付', onClick: onlinePay, disabled: payButtonDisabled }}
          secondary={{
            text: '我已线下付款',
            onClick: offlinePay,
            disabled: payButtonDisabled,
          }}
        />
      ) : (
        <BottomButtonGroup primary={{ text: '确认抵扣', onClick: deductionPay, disabled: payButtonDisabled }} />
      )}
    </PageContainer>
  );
};

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow: auto;
`;

const ScrollableDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #efefef;
  height: 100vh;
`;

const HeaderBG = styled.div`
  background-color: #8361fd;
  height: 153px;
  width: 100%;
  flex: none;
`;

const HeaderBGElem1 = styled.div`
  background-image: url(../../assets/images/billHeaderBg1.png);
  background-repeat: no-repeat;
  height: 123px;
  width: 127px;
  position: absolute;
  right: 27px;
  top: 25px;
`;

const HeaderBGElem2 = styled.div`
  background-image: url(../../assets/images/billHeaderBg2.png);
  background-repeat: no-repeat;
  height: 107px;
  width: 100%;
  position: absolute;
  top: 67px;
`;

const PageHeader = styled.div`
  position: fixed;
  left: 0;
  top: 0;
`;

const PayCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 5px 13px rgba(28, 28, 28, 0.1);
  border-radius: 10px;
  margin: 0 16px;
  padding: 32px 0;
  height: 105px;
  width: calc(100vw - 32px);

  position: fixed;
  left: 0;
  top: 70px;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const PayCardLabel = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  font-feature-settings: 'ss06' on;
  color: #000000;
`;

const PayCardAmount = styled.div`
  font-family: 'Inter', 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 22px;
  text-align: center;
  font-feature-settings: 'ss06' on;
  color: #141414;
`;

const PayCardDesc = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  font-feature-settings: 'ss06' on;
  color: #8f8f8f;
  opacity: 0.5;
`;

const BottomBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 13px 16px;
  gap: 10px;
  background: rgba(206, 109, 80, 0.15);
  position: fixed;
  left: 0;
  width: calc(100vw - 32px);
  bottom: 68px;
`;

const BottomBarLeftText = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ad4c33;
`;

const BottomBarRightText = styled.div`
  font-family: 'Inter', 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'ss06' on;
  color: #ad4c33;
`;

const PaymentHistoryCard: React.FC<{ paymentList: PaymentRecord[]; currencySymbol: string }> = ({
  paymentList,
  currencySymbol,
}) => {
  return (
    <PaymentHistoryCardDiv>
      <PaymentHistoryTitle>{'支付历史'}</PaymentHistoryTitle>
      {paymentList.map((paymentRecord, index) => (
        <PaymentHistoryRecord paymentRecord={paymentRecord} currencySymbol={currencySymbol} key={index} />
      ))}
    </PaymentHistoryCardDiv>
  );
};

const PaymentHistoryRecord: React.FC<{ paymentRecord: PaymentRecord; currencySymbol: string }> = ({
  paymentRecord,
  currencySymbol,
}) => {
  return (
    <PaymentRecordDiv>
      <PaymentRecordDate>
        <span>{formatDate(paymentRecord.payTime, DATE_ONLY_FORMAT)}</span>{' '}
        <PaymentRecordTime>{formatDate(paymentRecord.payTime, TIME_ONLY_FORMAT)}</PaymentRecordTime>
      </PaymentRecordDate>
      <PaymentRecordDetail>
        {`${paymentRecord.payDetail} ${currencySymbol}${paymentRecord.payAmount}`}
      </PaymentRecordDetail>
    </PaymentRecordDiv>
  );
};

const PaymentHistoryCardDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 20px;
  margin: 110px 16px 20px 16px;
  width: calc(100vw - 72px);
  gap: 10px;
  background: #ffffff;
  border-radius: 10px;
`;

const PaymentHistoryTitle = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`;

const PaymentRecordDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const PaymentRecordDate = styled.div`
  min-width: 130px;
  height: 14px;

  font-family: 'Inter', 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #000000;
  flex: none;
`;

const PaymentRecordTime = styled.span`
  color: #a3a3a3;
`;

const PaymentRecordDetail = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
`;
interface LinkCardProps {
  icon: ReactNode;
  label: string;
  subLabel: ReactNode;
  onClick: () => void;
}
const LinkCard: React.FC<LinkCardProps> = ({ icon, label, subLabel, onClick }) => {
  return (
    <LinkCardDiv>
      <LinkCardInnerDiv>
        {icon}
        <LinkCardLabelDiv>{label}</LinkCardLabelDiv>
      </LinkCardInnerDiv>
      <LinkCardInnerDiv onClick={onClick}>
        {subLabel}
        <ChevronRight />
      </LinkCardInnerDiv>
    </LinkCardDiv>
  );
};

const LinkCardDiv = styled.div`
  background: #ffffff;
  border-radius: 10px;
  height: 74px;
  margin: 10px 16px;
  padding: 0 16px;
  width: calc(100vw - 64px);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LinkCardInnerDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const LinkCardLabelDiv = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-left: 12px;
`;

const BalanceDeductionLabel: React.FC<{ deductionAmount: number; currencySymbol: string }> = ({
  deductionAmount,
  currencySymbol,
}) => {
  if (deductionAmount <= 0) {
    return <NoDeductionLabel>{'暂无可用余额'}</NoDeductionLabel>;
  }
  return (
    <DeductionAmountLabel>
      {currencySymbol}-{deductionAmount}
    </DeductionAmountLabel>
  );
};

const NoDeductionLabel = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #525252;
`;

const DeductionAmountLabel = styled.div`
  font-family: 'Inter', 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #c4232b;
`;

const BillTotalLabel = styled.div`
  font-family: 'Inter', 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #525252;
`;
