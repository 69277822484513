import styled from 'styled-components';
import React from 'react';
import { Spinner } from './op-components';

const LoadingScreen: React.FC = () => {
  return (
    <StyledWrapper>
      <Spinner size="small" />
    </StyledWrapper>
  );
};

export default LoadingScreen;

const StyledWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
