import styled from 'styled-components';
import Headline from '../../components/Headline';
import React from "react";
import printer from '../../assets/images/printer.png';

interface BillHeadProps {
  title: string;
}

const BillHead: React.FC<BillHeadProps> = ({ title, children }) => {
  return (
    <StyledWrapper picUrl={printer}>
      <HeadWrapper>
        <Headline>{title}</Headline>
        {children}
      </HeadWrapper>
    </StyledWrapper>
  );
};

export default BillHead;

const StyledWrapper = styled.div<{ picUrl: string }>`
  width: 100vw;
  height: 300px;
  background-color: #f3f5f7;
  background-image: url(${({ picUrl }: { picUrl: string }) => (picUrl.length > 0 ? picUrl : '')});
  background-size: 134px 114px;
  background-repeat: no-repeat;
  background-position: ${() => window.innerWidth - 39 - 134}px 33px;

  box-sizing: border-box;
  padding-top: 31px;
`;

const HeadWrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
`;
