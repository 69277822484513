import { fmsFetchData, fmsFetchDataList } from '../common/fmsApiRequest';
import { EzvizAccountInfo, Monitor } from '../models/monitor';

export const getMonitorList = async (facilityId?: string): Promise<Monitor[]> => {
  return fmsFetchDataList<Monitor>({
    url: `/merchant_css/monitor/list`,
    method: 'GET',
    params: { facilityId: facilityId ? facilityId : '' },
  });
};

export const getAccountInfo = async (): Promise<EzvizAccountInfo> => {
  return fmsFetchData<EzvizAccountInfo>({
    url: `/merchant_css/monitor/getAccountInfo`,
    method: 'GET',
  });
};
