import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import { BottomButtonGroup } from '../../components/BottomButtonGroup';
import { Dialog } from '../../components/Dialog';
import { IconRadioGroup } from '../../components/IconRadioGroup';
import { UtilitiesBalanceAccount } from '../../models/account';

interface SelectAccountDialogProps {
  title: string;
  accountId: string;
  utilitiesBalanceAccountList: UtilitiesBalanceAccount[];
  selectUtilitiesBalanceAccount(account: UtilitiesBalanceAccount): void;
  onClose: () => void;
}

interface item {
  id: string;
  content: string;
}

const getItems = (utilitiesBalanceAccountList: UtilitiesBalanceAccount[]): item[] => {
  const list: item[] = [];
  utilitiesBalanceAccountList.map((account: UtilitiesBalanceAccount) => {
    list.push({
      id: account.id,
      content: `${account.kitchenCode} ${account.storeName}`,
    });
  });
  return list;
};

export const SelectAccountDialog: React.FC<SelectAccountDialogProps> = ({
  title,
  accountId,
  utilitiesBalanceAccountList,
  selectUtilitiesBalanceAccount,
  onClose,
}) => {
  const [selectAccountId, setSelectAccountId] = useState<string>(accountId);
  const items: item[] = getItems(utilitiesBalanceAccountList);

  const submitSelected = useCallback(() => {
    const element = utilitiesBalanceAccountList.find((account) => account.id === selectAccountId);
    if (element) {
      selectUtilitiesBalanceAccount(element);
    }
    onClose();
  }, [utilitiesBalanceAccountList, selectAccountId, selectUtilitiesBalanceAccount, onClose]);

  return (
    <Dialog title={title} onClose={onClose}>
      <BodyWrapper>
        <IconRadioGroup items={items} selection={selectAccountId} setSelection={setSelectAccountId} />
      </BodyWrapper>
      {items.length > 3 && <SpaceBetween />}
      <BottomButtonGroup
        primary={{
          text: '确认',
          onClick: submitSelected,
        }}
      />
    </Dialog>
  );
};

const BodyWrapper = styled.div`
  width: 100%;
  height: 308px;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: normal;
`;

const SpaceBetween = styled.div`
  display: block;
  height: 67px;
  width: 10px;
`;
