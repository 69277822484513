export const routingPaths = {
  home: '/',
  login: '/login',
  contractSign: '/contract/sign',
  contractDetail: '/contract/:contractId',
  previewContract: '/contract/preview/:contractId',
  contractBasicDetail: '/contract/basic/:contractId',
  monitorDetail: '/monitor/detail',
  monitorReplay: '/monitor/replay',
  bill: '/bill',
  billDetail: '/bill/detail',
  billWaitPay: '/billwaitpay',
  utilitiesHistory: '/utilities/history/:accountId',
  utilities: '/utilities',
  account: '/account',
  pay: '/pay',
  offlinePay: '/offlinepay',
  paySuccess: '/paysuccess',
  payFail: '/payfail',
};

export interface Action {
  type: string;
  data: unknown;
}

export const noop = (): void => {};
