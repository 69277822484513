import styled from 'styled-components';

import { Tooltip } from '../../components/op-components';
import React from "react";

interface BillDetailRowProps {
  name: string;
  value: string;
  describe?: string;
}

const BillDetailRow: React.FC<BillDetailRowProps> = ({ name, value, describe }) => {
  return (
    <StyledWrapper>
      <RowWrapper>
        <CostTypeWrapper>
          <Name>{name}</Name>
          {name == '电费' && (
            <Tooltip
              tooltipText="电费一栏的金额可能包含了损耗、用电服务、电力设施维护费等"
              placement={'auto-start'}
              inverse={true}
            />
          )}
        </CostTypeWrapper>
        <Value>{value}</Value>
      </RowWrapper>
      {describe && describe.length > 0 && <Describe>{describe}</Describe>}
    </StyledWrapper>
  );
};

export default BillDetailRow;

const StyledWrapper = styled.div`
  width: 100%;
  margin-bottom: 12px;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const CostTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Name = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3d3d3d;
`;

const Value = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #3d3d3d;
`;

const Describe = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #8f8f8f;
`;
