import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { routingPaths } from '../../common/constants';
import ErrorScreen, { Error as PayPageError } from '../../components/ErrorScreen';
import LoadingDialog from '../../components/LoadingDialog';
import Toast, { ToastMessage } from '../../components/Toast';
import BookIcon from '../../components/icon/BookIcon';
import CloseWhiteIcon from '../../components/icon/CloseWhiteIcon';
import { Bill, BillCost, ConfirmStatus, defaultBill, PayStatus } from '../../models/bill';
import { confirmBillById, getBillDetailById } from '../../services/bill';
import { parseQueryString } from '../../utils/urlHelper';
import { getBillCostDescribe } from '../../utils/utils';
import { BillDetailBottomButtonGroup } from './BillDetailBottomButtonGroup';
import BillDetailRow from './BillDetailRow';
import book from '../../assets/images/book.png';

export const BillPageDetail: React.FC = () => {
  const history = useHistory();
  const [billId, setBillId] = useState<string>('');
  const [detailBill, setDetailBill] = useState<Bill>(defaultBill);
  const [loading, setLoading] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<ToastMessage>();
  const [error, setError] = useState<PayPageError | undefined>(undefined);

  useEffect(() => {
    const params = parseQueryString();

    const paramBillId = params.get('billId');
    if (paramBillId) {
      setBillId(paramBillId);
    } else {
      setError({ type: 'BROKEN', message: '页面参数错误' });
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    if (billId) {
      getBillDetailById(billId)
        .then((result) => {
          if (!result) {
            setError({ type: 'BROKEN', message: '无法获取账单数据，请联系客服' });
          } else {
            setDetailBill(result);
          }
        })
        .catch((res) => {
          setError({ type: 'BROKEN', message: res.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [billId, setDetailBill]);

  const goToPayment = useCallback(
    (id: string) => {
      history.push(routingPaths.billWaitPay + `?billId=${id}`);
    },
    [history]
  );

  const confirmBill = useCallback(() => {
    if (detailBill.id) {
      setLoading(true);
      confirmBillById(detailBill.id)
        .then(() => {
          setToastMessage({ type: 'success', message: '账单已确认' });
          goToPayment(detailBill.id);
        })
        .catch((res) => {
          setToastMessage({ type: 'error', message: res.message });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [detailBill, goToPayment, setToastMessage, setLoading]);

  const bottomButtons = useMemo(() => {
    if (detailBill.payStatus !== PayStatus.FULLY_PAID && detailBill.confirmStatus === ConfirmStatus.TO_BE_CONFIRM) {
      return (
        <BillDetailBottomButtonGroup
          primary={{
            text: '确认金额',
            onClick: () => confirmBill(),
          }}
          secondary={{
            text: '返回',
            onClick: () => history.push(routingPaths.bill),
          }}
        />
      );
    } else if (
      detailBill.payStatus !== PayStatus.FULLY_PAID &&
      detailBill.confirmStatus !== ConfirmStatus.TO_BE_CONFIRM
    ) {
      return (
        <BillDetailBottomButtonGroup
          primary={{
            text: '现在支付',
            onClick: () => goToPayment(detailBill.id),
          }}
          secondary={{
            text: '返回',
            onClick: () => history.push(routingPaths.bill),
          }}
        />
      );
    } else {
      return (
        <BillDetailBottomButtonGroup
          primary={{
            text: '返回',
            onClick: () => history.push(routingPaths.bill),
          }}
        />
      );
    }
  }, [detailBill]);

  if (loading) {
    return <LoadingDialog />;
  }
  if (error) {
    return (
      <ErrorScreen message={error.message} errorType={error.type} fullScreen={true} retry={error.type === 'UNKNOWN'} />
    );
  }
  return (
    <div>
      <StyledWrapper>
        <StyledHeadWrapper>
          <HeadLine />
          <HeadBackground />
          <HeadWrapper picUrl={book}>
            <CloseWrapper onClick={() => history.push(routingPaths.bill)}>
              <CloseWhiteIcon />
            </CloseWrapper>
            <NavWrapper>
              <Title>
                {detailBill.billTypeName} {detailBill.facilityName} · {detailBill.kitchenCode}
              </Title>
              <BookWrapper>
                <BookIcon />
              </BookWrapper>
            </NavWrapper>
          </HeadWrapper>
        </StyledHeadWrapper>
        <DialogContentWrapper>
          <ContentNavWrapper>
            <DetailTitle>{'明细'}</DetailTitle>
            <CycleWrapper>
              {detailBill.cycleStartDate} - {detailBill.cycleEndDate}
            </CycleWrapper>
          </ContentNavWrapper>
          {(detailBill.costList || []).map((billCost: BillCost, index: number) => (
            <BillDetailRow
              key={index}
              name={billCost.typeName}
              value={billCost.amount}
              describe={getBillCostDescribe(billCost)}
            />
          ))}
          <DetailLine />
          <ContentBottomWrapper>
            <DetailTitle>{'合计'}</DetailTitle>
            <TotalAmount>{detailBill.amount}</TotalAmount>
          </ContentBottomWrapper>
        </DialogContentWrapper>
        {bottomButtons}
      </StyledWrapper>
      <Toast
        visible={!!toastMessage}
        type={toastMessage?.type || 'error'}
        message={toastMessage?.message || ''}
        onHide={() => setToastMessage(undefined)}
      />
    </div>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 12px;
  box-sizing: border-box;
  background: #8361fd;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const StyledHeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1000;
  overflow: hidden;
`;

const HeadWrapper = styled.div<{ picUrl: string }>`
  width: 100%;
  height: 90px;
  margin-top: 20px;
  background-image: url(${({ picUrl }: { picUrl: string }) => (picUrl.length > 0 ? picUrl : '')});
  background-origin: content-box;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 105px 122px;
`;

const HeadBackground = styled.div`
  width: 120px;
  height: 240px;
  position: absolute;
  background: linear-gradient(180deg, rgba(131, 97, 253, 0) 12.2%, rgba(131, 97, 253, 0.53) 27.11%, #8361fd 43.31%);
  right: 0;
  z-index: 1001;
`;

const HeadLine = styled.div`
  width: 10%;
  height: 0px;
  border: 2px solid rgba(0, 0, 0, 0.12);
`;

const CloseWrapper = styled.div`
  padding-top: 8px;
  box-sizing: border-box;
  margin-left: 28px;
`;

const NavWrapper = styled.div`
  margin: 18px 20px 18px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  font-feature-settings: 'ss06' on;
  color: #ffffff;
  opacity: 0.5;
  z-index: 1002;
`;

const BookWrapper = styled.div`
  width: 32px;
  height: 32px;
  z-index: 1002;
`;

const DialogContentWrapper = styled.div`
  height: 45vh;
  margin: 18px 20px 24px 20px;
  padding: 20px 16px 24px 16px;
  background: #ffffff;
  border-radius: 10px;
  z-index: 1002;

  overflow-x: hidden;
  overflow-y: auto;
`;

const ContentNavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const DetailTitle = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`;

const CycleWrapper = styled.div`
  width: 180px;
  height: 28px;
  background: rgba(131, 97, 253, 0.1);
  border-radius: 100px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #5b3cc9;
`;

const DetailLine = styled.div`
  margin-top: 27px;
  box-sizing: border-box;
  width: 100%;
  height: 0px;
  border: 1px dashed rgba(0, 0, 0, 0.12);
`;

const ContentBottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`;

const TotalAmount = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  font-feature-settings: 'ss06' on;
  color: #141414;
`;
