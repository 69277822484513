const CloseIcon: React.FC = () => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 0.5H2.49998C1.58331 0.5 0.833313 1.25 0.833313 2.16667V12.1667C0.833313 13.0833 1.58331 13.8333 2.49998 13.8333H6.66665V15.5H13.3333V13.8333H17.5C18.4166 13.8333 19.1583 13.0833 19.1583 12.1667L19.1666 2.16667C19.1666 1.25 18.4166 0.5 17.5 0.5ZM17.5 12.1667H2.49998V2.16667H17.5V12.1667Z"
        fill="black"
      />
    </svg>
  );
};
export default CloseIcon;
