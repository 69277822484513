import { useMemo } from 'react';
import styled from 'styled-components';

import { mpTrackAction } from '../../common/mixPanel';

interface BillDetailButtonProps {
  text: string;
  onClick?: () => void;
  /**
   * Action name which will be recorded in mix panel
   */
  mpAction?: string | { name: string; params?: { [key: string]: string | boolean | number | undefined } };
}

interface BillDetailBottomButtonGroupProps {
  primary: BillDetailButtonProps;
  secondary?: BillDetailButtonProps;
}

const genButOnClickFunc = (props?: BillDetailButtonProps) => {
  if (!props) {
    return undefined;
  }
  return () => {
    props.onClick && props.onClick();
    if (props.mpAction) {
      if (typeof props.mpAction === 'string') {
        mpTrackAction(props.mpAction);
      } else {
        mpTrackAction(props.mpAction.name, props.mpAction.params);
      }
    }
  };
};

export const BillDetailBottomButtonGroup: React.FC<BillDetailBottomButtonGroupProps> = ({ primary, secondary }) => {
  const primaryOnclick = useMemo(() => genButOnClickFunc(primary), [primary]);
  const secondaryOnclick = useMemo(() => genButOnClickFunc(secondary), [secondary]);
  return (
    <BottomButtonWrapper>
      <PrimaryButton onClick={primaryOnclick}>{primary.text}</PrimaryButton>
      {secondary && (
        <>
          <SpaceBetween />
          <SecondaryButton onClick={secondaryOnclick}>{secondary.text}</SecondaryButton>
        </>
      )}
    </BottomButtonWrapper>
  );
};

const BottomButtonWrapper = styled.div`
  margin: 24px 20px 24px 20px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'ss06' on;
`;

const PrimaryButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  min-width: 150px;
  height: 44px;
  background: #fafafa;
  border-radius: 4px;
  color: #000000;
`;

const SpaceBetween = styled.div`
  display: block;
  height: 10px;
  width: 10px;
`;

const SecondaryButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  min-width: 150px;
  height: 44px;
  background: rgba(0, 0, 0, 0.0001);
  border-radius: 4px;
  color: #ffffff;
`;
