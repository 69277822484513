import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { routingPaths } from '../../common/constants';
import MoreBlackIcon from '../../components/icon/MoreBlackIcon';
import TvIcon from '../../components/icon/TvIcon';
import { FmsContext } from '../../context/FmsContext';
import MonitorPlayer from './MonitorPlayer';

export const MonitorDetailPage: React.FC = () => {
  const history = useHistory();
  const {
    state: { monitor },
  } = useContext(FmsContext);

  return (
    <StyledWrapper>
      <MonitorPlayer deviceSerial={monitor.deviceSerialNo} channelNo={monitor.channelNo} videoType="live" />
      <MonitorWrapper>
        <TvIcon />
        <MonitorTitle>
          {monitor.storeName} {monitor.channelName}
        </MonitorTitle>
        <RightWrapper onClick={() => history.push(routingPaths.monitorReplay)}>
          <Replay>{'回放'}</Replay>
          <MoreBlackIcon />
        </RightWrapper>
      </MonitorWrapper>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #f3f5f7;
`;

const MonitorWrapper = styled.div`
  margin: 18px 16px 0 16px;
  padding-right: 10px;
  height: 62px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const MonitorTitle = styled.div`
  margin-left: 10px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`;
const RightWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;
const Replay = styled.div`
  margin-right: 10px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: #525252;
`;
