import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Input } from '../../components/op-components';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import zhCN from 'date-fns/locale/zh-CN';

import "react-datepicker/dist/react-datepicker.css";

import { noop, routingPaths } from '../../common/constants';
import { mpTrack } from '../../common/mixPanel';
import { BottomButtonGroup } from '../../components/BottomButtonGroup';
import ErrorScreen, { Error } from '../../components/ErrorScreen';
import LoadingDialog from '../../components/LoadingDialog';
import { MobOverlayDialog } from '../../components/MobOverlayDialog';
import CameraIcon from '../../components/icon/CameraIcon';
import CloseBlackIcon from '../../components/icon/CloseBlackIcon';
import MoreBlackIcon from '../../components/icon/MoreBlackIcon';
import { PayChannelEnum, PayModelEnum, PrePayRequest } from '../../models/pay';
import { prePay } from '../../services/pay';
import { getFmsTokenFromStorage } from '../../services/user';
import { FileUploadResult, uploadFile } from '../../utils/fileUpload';
import { parseQueryString } from '../../utils/urlHelper';

export const OfflinePayPage: React.FC = () => {
  registerLocale('zh-CN', zhCN);
  const [billId, setBillId] = useState<string>('');
  const [amount, setAmount] = useState<number | undefined>();
  const [needPayAmount, setNeedPayAmount] = useState<number>(0);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [transferDate, setTransferDate] = useState<Date>(new Date());
  const [popupDialog, setPopupDialog] = useState<boolean>(false);
  const [picUploading, setPicUploading] = useState<boolean>(false);
  const [uploadedPics, setUploadedPics] = useState<FileUploadResult[]>([]);
  const [payLoading, setPayLoading] = useState<boolean>(false);
  const [utilityPrepay, setUtilityPrepay] = useState<boolean>(false);
  const [currencySymbol, setCurrencySymbol] = useState<string>('');

  const history = useHistory();

  useEffect(() => {
    const params = parseQueryString();

    const paramNeedPayAmount = params.get('needPayAmount');
    if (paramNeedPayAmount) {
      try {
        setNeedPayAmount(parseFloat(paramNeedPayAmount));
      } catch (e) {
        setError({ type: 'BROKEN', message: '页面参数错误' });
      }
    } else {
      setError({ type: 'BROKEN', message: '页面参数错误' });
    }

    const paramBillId = params.get('billId');
    if (paramBillId) {
      setBillId(paramBillId);
    } else {
      setError({ type: 'BROKEN', message: '页面参数错误' });
    }

    // optional params
    setUtilityPrepay('true' === params.get('utilityPrepay'));
    setCurrencySymbol(decodeURIComponent(params.get('currencySymbol') || '') || '￥');
  }, []);

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStrValue = e.target.value;
    if (!newStrValue) {
      setAmount(undefined);
      return;
    }
    if (newStrValue.startsWith('-') || newStrValue.startsWith(' ')) {
      setAmount(undefined);
      return;
    }
    const newValue = parseFloat(newStrValue);
    if (newValue < 0) {
      setAmount(undefined);
      return;
    }
    setAmount(Math.floor(newValue * 100) / 100);
  };
  const showDateSel = useCallback(() => setPopupDialog(true), []);
  const hidePopupDialog = useCallback(() => setPopupDialog(false), []);
  const deletePic = (pic: FileUploadResult) =>
    setUploadedPics(uploadedPics.filter((uploadedPic) => uploadedPic.fileId !== pic.fileId));
  const submitDisabled = useMemo(() => !(amount && amount > 0 && uploadedPics.length), [amount, uploadedPics]);
  const submit = useCallback(() => {
    if (!needPayAmount || !amount || amount <= 0 || !uploadedPics.length) {
      return;
    }
    setPayLoading(true);

    const isSplit = needPayAmount > amount ? 'Y' : 'N';

    const req: PrePayRequest = {
      billId,
      payAmount: `${amount.toFixed(2)}`,
      isSplit: isSplit,
      payModel: PayModelEnum.BANK_TRANSFER,
      payChannel: PayChannelEnum.OFFLINE,
      cssToken: getFmsTokenFromStorage(),
      transferImgUrls: uploadedPics.map((pic) => pic.uploadUrl),
      transferTimeLg: transferDate.getTime(), // TODO TBC
      utilityPrepay,
    };
    prePay(req)
      .then((res) => {
        history.push(`${routingPaths.paySuccess}?payDetailId=${res.payDetailId || ''}&isSplit=${isSplit}`);
      })
      .catch((e) => {
        history.push(`${routingPaths.payFail}?errorMsg=${e.returnMsg || ''}`);
        mpTrack('error', { name: '提交线下支付失败', errorMsg: e.returnMsg || '' });
      });
  }, [amount, billId, needPayAmount, history, transferDate, uploadedPics]);

  if (error) {
    return (
      <ErrorScreen message={error.message} errorType={error.type} fullScreen={true} retry={error.type === 'UNKNOWN'} />
    );
  }

  if (payLoading || picUploading) {
    return <LoadingDialog />;
  }

  return (
    <PageContainer>
      <PageInfo>{'请提交线下付款凭证'}</PageInfo>
      <AmountInput>
        <Input
          prefix={<SplitInputPrefixDiv>{currencySymbol}</SplitInputPrefixDiv>}
          value={amount}
          type="number"
          onChange={onAmountChange}
          placeholder={'请输入转账金额'}
        />
      </AmountInput>
      <DateSel>
        <DateSelLabel>{'转账日期'}</DateSelLabel>
        <DateSelValue onClick={showDateSel}>
          <div>{formatDate(transferDate)}</div>
          <EnterIconDiv>
            <MoreBlackIcon />
          </EnterIconDiv>
        </DateSelValue>
      </DateSel>
      <PicUploadDiv>
        <PicUploadTitle>{'添加凭证'}</PicUploadTitle>
        <UploadPics>
          {uploadedPics.map((pic) => (
            <UploadPic picUrl={pic.imgUrl} key={pic.fileId}>
              <ClosePicButton onClick={() => deletePic(pic)}>
                <CloseBlackIcon />
              </ClosePicButton>
            </UploadPic>
          ))}
          <AddUpload>
            <CameraIcon />
            <UploadText>{'上传图片'}</UploadText>
            <StyledFileInput
              type="file"
              accept="image/*"
              onChange={(e) => {
                setPicUploading(true);
                if (!e.target.files || !e.target.files.length) {
                  return;
                }
                const file = e.target.files[0];
                uploadFile(file)
                  .then((res) => setUploadedPics(uploadedPics.concat([res])))
                  .then(() => setPicUploading(false));
              }}
            />
          </AddUpload>
        </UploadPics>
      </PicUploadDiv>
      <BottomButtonGroup
        primary={{
          text: '提交凭证',
          onClick: submit,
          disabled: submitDisabled,
          mpAction: 'offlinePay',
        }}
      />
      {popupDialog && (
        <MobOverlayDialog onClose={hidePopupDialog}>
          {popupDialog && (
            <DatePickerDiv>
              <DatePicker
                  locale="zh-CN"
                  inline
                  selected={transferDate}
                  onChange={(date) => {
                    if (date) {
                      setTransferDate(date);
                      hidePopupDialog();
                    }
                  }}
              />
            </DatePickerDiv>
          )}
        </MobOverlayDialog>
      )}
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #ffffff;
  min-height: 100vh;
`;

const PageInfo = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #000000;
  margin: 32px 16px;
`;

const AmountInput = styled.div`
  margin: 0 16px;
`;

const SplitInputPrefixDiv = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  height: 20px;
  color: #8f8f8f;
`;

const DateSel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 32px 16px 0 16px;
  padding: 21px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const DateSelLabel = styled.div`
  height: 20px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`;

const DateSelValue = styled.div`
  flex: none;
  height: 20px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #525252;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const EnterIconDiv = styled.div`
  margin-left: 10px;
`;

const DatePickerDiv = styled.div`
  height: 310px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const PicUploadDiv = styled.div``;

const PicUploadTitle = styled.div`
  height: 20px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin: 21px 16px;
`;

const UploadPics = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 10px;
`;

const UploadPic = styled.div<{ picUrl: string }>`
  margin: 6px;
  height: 72px;
  width: 72px;
  border-radius: 4px;
  flex: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: #f3f5f7;
  ${(props) => `background-image: url(${props.picUrl});`}
`;

const ClosePicButton = styled.div`
  transform: scale(0.7);
`;

const AddUpload = styled.div`
  margin: 6px;
  height: 72px;
  width: 72px;
  background-color: #f3f5f7;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UploadText = styled.div`
  margin-top: 6px;
  height: 12px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.02em;
  color: #000000;
`;

const StyledFileInput = styled.input`
  display: block;
  height: 72px;
  width: 72px;
  opacity: 0;
  position: absolute;
`;

const formatDate = (date: Date): string => {
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  return `${y}.${m < 10 ? '0' + m : m}.${d < 10 ? '0' + d : d}`;
};
