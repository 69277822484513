import styled, { css } from 'styled-components';

import { Close } from '../../../../op-icons';

import { borderRadius, color } from '../../../theme';
import { zIndex } from '../../../theme/utilities';
import { DisplayVariant } from './types';

export const PopoverWrapper = styled.div<{
  hasContentPadding: boolean;
  displayVariant?: DisplayVariant;
  fadeIn?: boolean;
}>`
  background: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fontFamily.sans};
  font-size: ${(props) => props.theme.fontSizes.micro};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  line-height: 16px;
  color: ${(props) => props.theme.colors.grey};
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: ${(props) => props.theme.radii.default};

  ${(props) =>
    props.hasContentPadding &&
    css`
      padding: 20px;
      max-width: 240px;
    `}

  ${(props) =>
    props.displayVariant === 'inverse' &&
    css`
      ${color.bg('greyDark')}
      ${borderRadius('lg')}
    `}

  /* TODO: maybe replace with constants from theme? */
  z-index: ${zIndex('popOvers')};
  white-space: normal;

  ${(props) => {
    if (props.fadeIn) {
      return `
        animation: fadeInPopover 0.5s;

        @keyframes fadeInPopover {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      `;
    }
  }}

  position: relative;
  &[data-popper-placement^='top'] > [data-popper-arrow] {
    bottom: -4px;
  }

  &[data-popper-placement^='right'] > [data-popper-arrow] {
    left: -4px;
  }

  &[data-popper-placement^='bottom'] > [data-popper-arrow] {
    top: -4px;
  }

  &[data-popper-placement^='left'] > [data-popper-arrow] {
    right: -4px;
  }
`;

export const PopoverClose = styled(Close)`
  color: ${(props) => props.theme.colors.grey};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.greyDark};
  }
`;

export const PopoverArrow = styled.div<{ displayVariant?: DisplayVariant }>`
  &,
  &::before {
    width: 10px;
    height: 10px;
    position: absolute;
    z-index: -1;
  }

  &::before {
    content: '';
    transform: rotate(45deg);
    background: #fff;
    top: 0;
    left: 0;
  }

  &::before {
    transition: transform 0.2s ease-out, visibility 0.2s ease-out;
    transform: translateX(0px) rotate(45deg);
    transform-origin: center;
  }

  ${(props) =>
    props.displayVariant === 'inverse' &&
    css`
      &::before {
        ${color.bg('greyDark')}
      }
    `}
`;

export const PopoverCloseWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  max-width: 240px;
`;

export const IconContainer = styled.div`
  margin-left: ${(props) => props.theme.space.$6};
`;
