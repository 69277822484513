const LightIcon: React.FC = () => {
  return (
    <svg width="74" height="97" viewBox="0 0 74 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.3694 96C42.5702 96 46.7862 91.7839 46.7862 86.5832C46.7862 81.3824 42.5702 77.1664 37.3694 77.1664C32.1687 77.1664 27.9526 81.3824 27.9526 86.5832C27.9526 91.7839 32.1687 96 37.3694 96Z"
        fill="#FFE800"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M39.039 44.799L39.0052 44.6503C38.4577 44.6503 37.9101 44.6705 37.3625 44.6908C36.8149 44.6705 36.2741 44.6503 35.7198 44.6503L35.686 44.799C16.3251 46.4619 1 62.6929 1 89.1587H73.7115C73.7115 62.6997 58.3864 46.4619 39.0255 44.799H39.039Z"
        fill="#5F47B1"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path d="M37.3696 44.6842V0" stroke="black" strokeWidth="0.5" strokeMiterlimit="10" />
    </svg>
  );
};
export default LightIcon;
