import styled from 'styled-components';
import React, { ReactNode } from 'react';

interface PurpleButtonProps {
  title: string;
  onClick?: () => void;
  suffix?: ReactNode;
}

const PurpleIconButton: React.FC<PurpleButtonProps> = ({ title, onClick, suffix }) => {
  return (
    <StyledWrapper onClick={onClick}>
      {suffix}
      <StyledTitle>{title}</StyledTitle>
    </StyledWrapper>
  );
};

export default PurpleIconButton;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.27);
  border-radius: 8px;

  width: 103px;
  height: 32px;
`;

const StyledTitle = styled.div`
  margin-left: 8px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;
