import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { routingPaths } from '../../common/constants';
import LoadingDialog from '../../components/LoadingDialog';
import Toast, { ToastMessage } from '../../components/Toast';
import { Bill, ConfirmStatus, PayStatus } from '../../models/bill';
import { confirmBillById } from '../../services/bill';

interface BillCardProps {
  bill: Bill;
  showBillDetail: () => void;
}

const BillCard: React.FC<BillCardProps> = ({ bill, showBillDetail }) => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<ToastMessage>();

  const buttons = useMemo(() => {
    const confirmBill = () => {
      if (bill.id) {
        setLoading(true);
        confirmBillById(bill.id)
          .then(() => {
            setToastMessage({ type: 'success', message: '账单已确认' });
          })
          .catch((res) => {
            setToastMessage({ type: 'error', message: res.message });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    };

    const toPay = () => {
      if (bill.id) {
        history.push(routingPaths.billWaitPay + `?billId=${bill.id}`);
      }
    };

    if (bill.confirmStatus === ConfirmStatus.TO_BE_CONFIRM) {
      return <ConfirmButton onClick={confirmBill}>{'确认'}</ConfirmButton>;
    } else if (bill.payStatus !== PayStatus.FULLY_PAID) {
      return <PayButton onClick={toPay}>{'支付'}</PayButton>;
    }
  }, [bill, history]);

  return (
    <StyledWrapper>
      <Toast
        visible={!!toastMessage}
        type={toastMessage?.type || 'error'}
        message={toastMessage?.message || ''}
        onHide={() => setToastMessage(undefined)}
      />
      {loading && <LoadingDialog />}
      <BillTitle onClick={showBillDetail}>
        {bill.billTypeName} {bill.facilityName} · {bill.kitchenCode}
      </BillTitle>
      <BillCycle>
        {'账单周期'}：{bill.cycleStartDate} - {bill.cycleEndDate}
      </BillCycle>
      {bill.remainAmount !== bill.amount && <BillTotalAmount>账单总金额：{bill.amount}</BillTotalAmount>}
      <Line />
      <BottomWrapper>
        <BillRemainAmount>{bill.remainAmount}</BillRemainAmount>
        {buttons}
      </BottomWrapper>
    </StyledWrapper>
  );
};

export default BillCard;

const StyledWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 24px 24px 8px 24px;
  background: #ffffff;
  box-shadow: 0px 5px 13px rgba(28, 28, 28, 0.1);
  border-radius: 12px;
`;

const BillTitle = styled.div`
  height: 32px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
`;

const BillCycle = styled.div`
  margin-top: 2px;
  height: 21px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8c8c8c;
`;

const BillTotalAmount = styled.div`
  margin-top: 14px;
  height: 20px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #525252;
`;

const Line = styled.div`
  margin-top: 15px;
  width: 100%;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.12);
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
`;

const BillRemainAmount = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`;

const ConfirmButton = styled.div`
  width: 64px;
  height: 32px;
  background: rgba(224, 110, 77, 0.0001);
  border: 1px solid rgba(224, 110, 77, 0.73);
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #e06e4d;
`;

const PayButton = styled.div`
  width: 64px;
  height: 32px;
  background: #e06e4d;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #fafafa;
`;
