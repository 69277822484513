import appConfig from '../config/config';
import { getFmsTokenFromStorage } from '../services/user';

interface Request {
  url: string;
  method: methodType;
  params?: { [key: string]: unknown };
  withToken?: boolean;
}

interface Response<T> {
  success: boolean;
  returnMsg: string;
  returnCode: number;
  obj?: T;
  rows?: T[];
  count?: number;
  nextToken?: string;
  size?: number;
  start?: number;
  message: string;
}

export type methodType = 'POST' | 'PUT' | 'GET' | 'DELETE' | 'PATCH';

const fmsApiRequest = <T>({ url, method, params, withToken = true }: Request): Promise<Response<T>> => {
  const requestOptions: RequestInit = {
    method: method,
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'client-type': '5',
      'x-requested-with': '',
    },
  };
  if (withToken) {
    const fmsToken = getFmsTokenFromStorage();
    if (fmsToken) {
      requestOptions.headers = {
        ...requestOptions.headers,
        'x-requested-with': `{"accessKey":"${fmsToken}"}`,
      };
    }
  }
  if (method === 'POST' || method === 'PUT') {
    requestOptions.body = JSON.stringify(params);
  }
  if (method === 'GET' && params) {
    const paramsArray: string[] = [];
    Object.keys(params).forEach((key) => paramsArray.push(key + '=' + params[key]));
    if (url.search(/\?/) === -1) {
      url += '?' + paramsArray.join('&');
    } else {
      url += '&' + paramsArray.join('&');
    }
  }
  return fetch(`${appConfig.FMS_API_URL}${url}`, requestOptions).then((response) => {
    if (response.ok) {
      // @ts-ignore
      return response.json<Response<T>>().then((value) => {
        const { returnCode, returnMsg } = value;
        if (returnCode >= 200 && returnCode < 300) {
          return value;
        } else if (returnCode === 401 || returnCode === 403) {
          // token expire
          return buildRejectResponse<Response<T>>({
            success: false,
            returnCode: 401,
            returnMsg: returnMsg,
            message: returnMsg,
          });
        } else {
          return buildRejectResponse<Response<T>>({
            success: false,
            returnCode: returnCode,
            returnMsg: returnMsg,
            message: returnMsg,
          });
        }
      });
    } else {
      return buildRejectResponse<Response<T>>({
        success: false,
        returnCode: response.status,
        returnMsg: '',
        message: '',
      });
    }
  });
};

const buildRejectResponse = <T>(value: T): Promise<T> => {
  return new Promise((_, reject) => {
    reject(value);
  });
};

export interface FmsPageInfo {
  nextToken?: string;
}

export const fmsFetchData = <T>(request: Request): Promise<T> => {
  return fmsApiRequest<T>(request).then((response) => {
    if (response.success) {
      return response.obj as T;
    } else {
      throw new Error(`Request failed ${response.returnCode}, ${response.returnMsg}`);
    }
  });
};

export const fmsFetchDataList = <T>(request: Request): Promise<T[]> => {
  return fmsApiRequest<T>(request).then((response) => {
    if (response.success) {
      return response.rows as T[];
    } else {
      throw new Error(`Request failed ${response.returnCode}, ${response.returnMsg}`);
    }
  });
};

export const utilitiesApiRequest = <T>({ url, method, params, withToken = true }: Request): Promise<T> => {
  const requestOptions: RequestInit = {
    method: method,
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'client-type': '5',
      'x-requested-with': '',
    },
  };
  if (withToken) {
    const fmsToken = getFmsTokenFromStorage();
    if (fmsToken) {
      requestOptions.headers = {
        ...requestOptions.headers,
        'x-requested-with': `{"accessKey":"${fmsToken}"}`,
      };
    }
  }
  if (method === 'POST' || method === 'PUT') {
    requestOptions.body = JSON.stringify(params);
  }
  if (method === 'GET' && params) {
    const paramsArray: string[] = [];
    Object.keys(params).forEach((key) => paramsArray.push(key + '=' + params[key]));
    if (url.search(/\?/) === -1) {
      url += '?' + paramsArray.join('&');
    } else {
      url += '&' + paramsArray.join('&');
    }
  }
  return fetch(`${appConfig.FMS_API_URL}${url}`, requestOptions).then((response) => {
    if (response.ok) {
      // @ts-ignore
      return response.json<T>().then((value) => {
        return value;
      });
    } else {
      return new Promise((res, reject) => {
        reject(res);
      });
    }
  });
};
