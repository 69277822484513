import { css } from 'styled-components';

import { Radii, StyledBorderRadiusUtil } from './types';

/**
 * @deprecated Use `borderRadius()` from `@css/csds-components` instead.
 */
export const borderRadius: StyledBorderRadiusUtil =
  (r) =>
  ({ theme }) =>
    css`
      border-radius: ${theme.radii[r as Radii] || r};
    `;
borderRadius.t =
  (r) =>
  ({ theme }) =>
    css`
      border-top-left-radius: ${theme.radii[r as Radii] || r};
      border-top-right-radius: ${theme.radii[r as Radii] || r};
    `;
borderRadius.b =
  (r) =>
  ({ theme }) =>
    css`
      border-bottom-left-radius: ${theme.radii[r as Radii] || r};
      border-bottom-right-radius: ${theme.radii[r as Radii] || r};
    `;
borderRadius.l =
  (r) =>
  ({ theme }) =>
    css`
      border-top-left-radius: ${theme.radii[r as Radii] || r};
      border-bottom-left-radius: ${theme.radii[r as Radii] || r};
    `;
borderRadius.r =
  (r) =>
  ({ theme }) =>
    css`
      border-top-right-radius: ${theme.radii[r as Radii] || r};
      border-bottom-right-radius: ${theme.radii[r as Radii] || r};
    `;
