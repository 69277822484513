import React, { useState, useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';

import ErrorScreen, { Error } from '../../components/ErrorScreen';
import Headline from '../../components/Headline';
import LoadingScreen from '../../components/LoadingScreen';
import { FmsContext } from '../../context/FmsContext';
import { Bill, defaultFacilityBillDetail, FacilityBillDetail } from '../../models/bill';
import { getFacilityBillDetail } from '../../services/bill';
import { reload } from '../../utils/utils';
import BillCard from './BillCard';
import { BillDetailDialog } from './BillDetailDialog';
import BillHead from './BillHead';
import BillHeadCard from './BillHeadCard';
import BillHistoryCard from './BillHistoryCard';

export const BillPage: React.FC = () => {
  const {
    state: { facilityId },
  } = useContext(FmsContext);
  const [facilityBillDetail, setFacilityBillDetail] = useState<FacilityBillDetail>(defaultFacilityBillDetail);
  const [loading, setLoading] = useState<boolean>(false);
  const [detailBillId, setDetailBillId] = useState<string>('');
  const [billDetailVisible, setBillDetailVisible] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    setLoading(true);
    getFacilityBillDetail(facilityId)
      .then((result) => {
        if (!result?.billList && !result?.historyBillList) {
          setError({ type: 'EMPTY', message: '当前门店暂未出账单' });
        } else {
          setFacilityBillDetail(result);
        }
      })
      .catch((res) => {
        setError({ type: 'UNKNOWN', message: res.message });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [facilityId, setFacilityBillDetail, setLoading, setError]);

  const showBillDetail = useCallback(
    (id: string) => {
      setDetailBillId(id);
      setBillDetailVisible(true);
    },
    [setDetailBillId, setBillDetailVisible]
  );

  if (error) {
    return (
      <ErrorScreen
        message={error.message}
        errorType={error.type}
        fullScreen={true}
        retry={error.type === 'UNKNOWN'}
        onRetry={reload}
      />
    );
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <StyledWrapper>
      <BillHead title={'我的厨房账单'}>
        <BillHeadCard
          totalUnpaidAmount={facilityBillDetail.totalUnpaidAmount}
          totalBalanceAmount={facilityBillDetail.totalBalanceAmount}
        />
      </BillHead>
      {facilityBillDetail.billList && (
        <BillCardWrapper>
          {facilityBillDetail.billList.map((bill: Bill, index: number) => (
            <BillCard bill={bill} key={index} showBillDetail={() => showBillDetail(bill.id)} />
          ))}
        </BillCardWrapper>
      )}

      {facilityBillDetail.historyBillList && (
        <BillHistoryCordWrapper>
          <HistoryTitle>{'历史'}</HistoryTitle>
          {facilityBillDetail.historyBillList.map((bill: Bill, index: number) => (
            <BillHistoryCard bill={bill} key={index} showBillDetail={() => showBillDetail(bill.id)} />
          ))}
        </BillHistoryCordWrapper>
      )}
      {billDetailVisible && <BillDetailDialog billId={detailBillId} onClose={() => setBillDetailVisible(false)} />}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f3f5f7;
`;

const BillCardWrapper = styled.div`
  margin: -55px 24px 40px 24px;
`;

const BillHistoryCordWrapper = styled.div`
  margin: 40px 24px 0 24px;
  padding-bottom: 40px;
  box-sizing: border-box;
`;

const HistoryTitle = styled(Headline)`
  margin-bottom: 32px;
`;
