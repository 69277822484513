import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import { BottomButtonGroup } from '../../components/BottomButtonGroup';
import { Dialog } from '../../components/Dialog';
import { IconRadioGroup } from '../../components/IconRadioGroup';
import { UtilitiesBalanceAccountHistoryType } from '../../models/account';
import { getHistoryTypeString } from '../../utils/utils';

interface SelectHistoryTypeDialogProps {
  title: string;
  historyType: UtilitiesBalanceAccountHistoryType;
  selectHistoryType(historyType: UtilitiesBalanceAccountHistoryType): void;
  onClose: () => void;
}

export const SelectHistoryTypeDialog: React.FC<SelectHistoryTypeDialogProps> = ({
  title,
  historyType,
  selectHistoryType,
  onClose,
}) => {
  const [currentHistoryType, setCurrentHistoryType] = useState<UtilitiesBalanceAccountHistoryType>(historyType);

  const submitSelected = useCallback(() => {
    selectHistoryType(currentHistoryType);
    onClose();
  }, [currentHistoryType, selectHistoryType, onClose]);

  return (
    <Dialog title={title} onClose={onClose}>
      <BodyWrapper>
        <IconRadioGroup
          items={[
            {
              id: UtilitiesBalanceAccountHistoryType.ALL,
              content: getHistoryTypeString(UtilitiesBalanceAccountHistoryType.ALL),
            },
            {
              id: UtilitiesBalanceAccountHistoryType.RECHARGE,
              content: getHistoryTypeString(UtilitiesBalanceAccountHistoryType.RECHARGE),
            },
            {
              id: UtilitiesBalanceAccountHistoryType.DAILY_COST,
              content: getHistoryTypeString(UtilitiesBalanceAccountHistoryType.DAILY_COST),
            },
          ]}
          selection={currentHistoryType}
          setSelection={(v) => {
            switch (v) {
              case UtilitiesBalanceAccountHistoryType.RECHARGE:
                setCurrentHistoryType(UtilitiesBalanceAccountHistoryType.RECHARGE);
                break;
              case UtilitiesBalanceAccountHistoryType.DAILY_COST:
                setCurrentHistoryType(UtilitiesBalanceAccountHistoryType.DAILY_COST);
                break;
              default:
                setCurrentHistoryType(UtilitiesBalanceAccountHistoryType.ALL);
            }
          }}
        />
      </BodyWrapper>
      <BottomButtonGroup
        primary={{
          text: '确认',
          onClick: submitSelected,
        }}
      />
    </Dialog>
  );
};

const BodyWrapper = styled.div`
  width: 100%;
  height: 308px;
`;
