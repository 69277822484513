import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { routingPaths } from '../../common/constants';
import PurpleIconButton from '../../components/PurpleIconButton';
import BellIcon from '../../components/icon/BellIcon';
import BillIcon from '../../components/icon/BillIcon';
import FlashIcon from '../../components/icon/FlashIcon';
import LightIcon from '../../components/icon/LightIcon';

interface HomeHeadProps {
  title: string;
  content: string;
}

const HomeHead: React.FC<HomeHeadProps> = ({ title, content }) => {
  const history = useHistory();
  return (
    <StyledWrapper>
      <HeadWrapper>
        <LeftWrapper>
          <Title>{title}</Title>
          <Describe>{content}</Describe>
        </LeftWrapper>
        <MiddleWrapper>
          <LightIcon />
        </MiddleWrapper>
        <RightWrapper>
          <BellIcon />
        </RightWrapper>
      </HeadWrapper>
      <NavWrapper>
        <PurpleIconButton suffix={<BillIcon />} title={'我的账单'} onClick={() => history.push(routingPaths.bill)} />
        <ButtonWrapper>
          <PurpleIconButton
            suffix={<FlashIcon />}
            title={'水电充值'}
            onClick={() => history.push(routingPaths.utilities)}
          />
        </ButtonWrapper>
      </NavWrapper>
    </StyledWrapper>
  );
};

export default HomeHead;

const StyledWrapper = styled.div`
  width: 100%;
  height: 226px;
  background: #8361fd;
  z-index: 0;
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 40px;
  color: #ffffff;
`;

const Describe = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  color: #ffffff;
`;

const LeftWrapper = styled.div`
  padding-left: 16px;
  padding-top: 40px;
  box-sizing: border-box;
`;

const MiddleWrapper = styled.div`
  width: 73px;
  height: 96px;
  margin-left: auto;
`;

const RightWrapper = styled.div`
  margin: 42px 22px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  backdrop-filter: blur(10.5px);
  border-radius: 67.5px;
  background: rgba(0, 0, 0, 0.3);
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px 0 0 16px;
`;

const ButtonWrapper = styled.div`
  margin-left: 16px;
`;
