const WalletIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.083 4.06667V2.16667C15.083 1.25 14.333 0.5 13.4163 0.5H1.74967C0.824674 0.5 0.0830078 1.25 0.0830078 2.16667V13.8333C0.0830078 14.75 0.824674 15.5 1.74967 15.5H13.4163C14.333 15.5 15.083 14.75 15.083 13.8333V11.9333C15.5747 11.6417 15.9163 11.1167 15.9163 10.5V5.5C15.9163 4.88333 15.5747 4.35833 15.083 4.06667ZM14.2497 5.5V10.5H8.41634V5.5H14.2497ZM1.74967 13.8333V2.16667H13.4163V3.83333H8.41634C7.49967 3.83333 6.74967 4.58333 6.74967 5.5V10.5C6.74967 11.4167 7.49967 12.1667 8.41634 12.1667H13.4163V13.8333H1.74967Z"
        fill="black"
      />
      <path
        d="M10.9163 9.25C11.6067 9.25 12.1663 8.69036 12.1663 8C12.1663 7.30964 11.6067 6.75 10.9163 6.75C10.226 6.75 9.66634 7.30964 9.66634 8C9.66634 8.69036 10.226 9.25 10.9163 9.25Z"
        fill="black"
      />
    </svg>
  );
};
export default WalletIcon;
