import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import ErrorScreen, { Error } from '../../../components/ErrorScreen';
import LoadingScreen from '../../../components/LoadingScreen';
import { FmsContext, setContractAction } from '../../../context/FmsContext';
import { Contract, getDefaultContract } from '../../../models/contract';
import { getContractById } from '../../../services/contract';
import { reload } from '../../../utils/utils';
import { BillDetailDialog } from '../../bill/BillDetailDialog';
import ContractCard from './ContractCard';
import DetailCard from './DetailCard';

export const ContractDetailPage: React.FC = () => {
  const history = useHistory();
  const { dispatch } = useContext(FmsContext);
  const { contractId } = useParams<{ contractId: string }>();
  const [contract, setContract] = useState<Contract>(getDefaultContract);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [billDetailVisible, setBillDetailVisible] = useState<boolean>(false);

  useEffect(() => {
    if (contractId) {
      setLoading(true);
      getContractById(contractId)
        .then((res) => {
          setContract(res);
        })
        .catch((res) => {
          setError({ type: 'UNKNOWN', message: res.message });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [contractId, setContract, setLoading, setError]);

  useEffect(() => {
    if (contract && contract.basic.contractId) {
      dispatch(setContractAction(contract));
    }
  }, [contract, dispatch]);

  if (error) {
    return (
      <ErrorScreen message={error.message} errorType={error.type} fullScreen={true} retry={true} onRetry={reload} />
    );
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <StyledWrapper>
      <ContractCard contract={contract} />
      <DetailCard title={'电子合同'} onClick={() => history.push(`/contract/preview/${contract.basic.contractId}`)} />
      <DetailCard title={'首期合同账单'} onClick={() => setBillDetailVisible(true)} />
      {/*<DetailCard title={'申请退租'} onClick={() => history.push(`/termination/${contract.basic.contractId}`)}  />*/}
      {billDetailVisible && (
        <BillDetailDialog
          contractId={contractId}
          onClose={() => setBillDetailVisible(false)}
          showContractDetailBottom={false}
        />
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #f3f5f7;
`;
