import { RefObject, useEffect, useRef, useState } from 'react';

export interface UseHoverResult<T> {
  isHovered: boolean;
  hoverRef: RefObject<T>;
}

export function useHover<T extends HTMLElement>(element: HTMLElement | null | undefined = null): UseHoverResult<T> {
  const [isHovered, setIsHovered] = useState(false);

  const innerRef = useRef(null);
  const handleMouseEnter = (): void => setIsHovered(true);
  const handleMouseLeave = (): void => setIsHovered(false);

  useEffect(() => {
    const node = element || innerRef.current;
    node?.addEventListener('mouseenter', handleMouseEnter);
    node?.addEventListener('mouseleave', handleMouseLeave);
    return (): void => {
      node?.removeEventListener('mouseenter', handleMouseEnter);
      node?.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [innerRef, element]);

  return { hoverRef: innerRef, isHovered };
}
