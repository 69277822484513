import React from 'react';
import styled from 'styled-components';

import { Box } from '../../layouts/Box';

export interface HoverableIconProps {
  className?: string;
  primary: React.ReactNode;
  hovered: React.ReactNode;
  dataTest?: string;
}

const HoverContainer = styled(Box)`
  display: inline-block;

  > :first-child {
    display: block;
  }
  > :last-child {
    display: none;
  }

  :active,
  :focus,
  :hover {
    > :first-child {
      display: none;
    }
    > :last-child {
      display: block;
    }
  }
`;

/**
 * Simple component to display a different icon when hovered or focused.
 * */
export const HoverableIcon: React.FC<HoverableIconProps> = ({
  className,
  primary,
  hovered,
  dataTest = 'op-hoverable-icon',
}) => {
  return (
    <HoverContainer data-testid={dataTest} className={className}>
      {primary}
      {hovered}
    </HoverContainer>
  );
};
