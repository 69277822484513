import React from 'react';
import styled from 'styled-components';

import { v4 as uuidv4 } from 'uuid';
import { MobOverlayDialog } from '../../components/MobOverlayDialog';
import { OrangeBottomButtonGroup } from '../../components/OrangeBottomButtonGroup';
import appConfig from '../../config/config';
import paper from '../../assets/images/paper.png';

interface PrintPaperDialogProps {
  userId: string;
  onClose: () => void;
}

export const PrintPaperDialog: React.FC<PrintPaperDialogProps> = ({ userId, onClose }) => {
  const traceId = uuidv4();
  const goToMall = () => {
    window.open(
      `${appConfig.OTTER_MALL}/product?id=CSSprintpaper&utm_source=starChefWeb&trace_id=${traceId}`,
      '_self'
    );
  };

  return (
    <MobOverlayDialog onClose={onClose}>
      <DialogWrapper>
        <HeadWrapper picUrl={paper} />
        <DialogContentWrapper>
          <Title>水獭商城，囤货必入</Title>
          <Content>
            点击“水獭商城”，即可在App内优惠购买加厚超清打印纸
          </Content>
        </DialogContentWrapper>
        <OrangeBottomButtonGroup
          primary={{
            text: '去看看',
            onClick: () => goToMall(),
            mpTrack: { name: 'printPaper', params: { userId: userId, action: 'purpose' } },
          }}
          secondary={{
            text: '暂时跳过',
            onClick: onClose,
            mpTrack: { name: 'printPaper', params: { userId: userId, action: 'ignore' } },
          }}
        />
      </DialogWrapper>
    </MobOverlayDialog>
  );
};

const DialogWrapper = styled.div`
  width: 100%;
  height: 560px;
`;

const HeadWrapper = styled.div<{ picUrl: string }>`
  width: 100%;
  box-sizing: border-box;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 252px;
  background-image: url(${({ picUrl }: { picUrl: string }) => (picUrl.length > 0 ? picUrl : '')});
  background-size: 100% 100%;
`;

const DialogContentWrapper = styled.div`
  padding: 16px;
`;

const Title = styled.div`
  font-family: 'PingFang SC';
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  color: #000000;
`;

const Content = styled.div`
  margin-top: 16px;
  font-family: 'PingFang SC';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #000000;
`;
