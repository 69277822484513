import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { BottomButtonGroup } from '../../../components/BottomButtonGroup';
import ErrorScreen, { Error } from '../../../components/ErrorScreen';
import LoadingScreen from '../../../components/LoadingScreen';
import { PDFViewer } from '../../../components/PDFViewer';
import { Contract, getDefaultContract } from '../../../models/contract';
import { getContractById, getContractPdfUrlById, getContractSignUrl } from '../../../services/contract';
import { isSigned, reload, showToSignButton } from '../../../utils/utils';
import { SendContractDialog } from './SendContractDialog';

export const ContractPreviewPage: React.FC = () => {
  const history = useHistory();
  const { contractId } = useParams<{ contractId: string }>();
  const [contractPdfUrl, setContractPdfUrl] = useState<string>('');
  const [contract, setContract] = useState<Contract>(getDefaultContract);
  const [contractSignUrl, setContractSignUrl] = useState<string>('');
  const [sendContractOpen, setSendContractOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [buttonLoaded, setButtonLoaded] = useState<boolean>(false);

  useEffect(() => {
    setButtonLoaded(false);
    if (contractId) {
      setLoading(true);
      getContractById(contractId)
        .then((res) => {
          setContract(res);
          if (showToSignButton(res.basic.contractStatus, res.basic.entityType)) {
            getContractSignUrl(res.basic.contractId).then((url) => {
              setContractSignUrl(url);
            });
          } else {
            setButtonLoaded(true);
          }
        })
        .then(() => getContractPdfUrlById(contractId))
        .then((pdfUrls) => {
          if (pdfUrls.length > 0) {
            setContractPdfUrl(pdfUrls[0]);
          }
        })
        .catch((res) => {
          setError({ type: 'UNKNOWN', message: res.message });
        })
        .finally(() => {
          setLoading(false);
          setButtonLoaded(true);
        });
    }
  }, [contractId, setContract, setContractPdfUrl, setContractSignUrl]);

  const buttons = useMemo(() => {
    if (contractSignUrl && showToSignButton(contract.basic.contractStatus, contract.basic.entityType)) {
      return (
        <BottomButtonGroup
          primary={{
            text: '我确认合同无误, 同意签署',
            onClick: () => (window.location.href = contractSignUrl),
          }}
        />
      );
    } else if (isSigned(contract.basic.contractStatus)) {
      return (
        <BottomButtonGroup
          primary={{
            text: '发送合同',
            onClick: () => setSendContractOpen(true),
          }}
          secondary={{
            text: '返回',
            onClick: () => history.push(`/contract/${contract.basic.contractId}`),
          }}
        />
      );
    } else {
      return (
        <BottomButtonGroup
          primary={{
            text: '返回',
            onClick: () => history.push(`/contract/${contract.basic.contractId}`),
          }}
        />
      );
    }
  }, [contract, history, contractSignUrl]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <ErrorScreen
        message={error.message}
        errorType={error.type}
        fullScreen={true}
        retry={error.type === 'UNKNOWN'}
        onRetry={reload}
      />
    );
  }

  return (
    <StyledWrapper>
      <PDFViewer file={contractPdfUrl} />
      {buttonLoaded && buttons}
      {sendContractOpen && (
        <SendContractDialog
          contractId={contractId}
          title={'发送合同至邮箱'}
          email={contract.linkman?.email}
          onClose={() => setSendContractOpen(false)}
        />
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
