import styled from 'styled-components';

const BlackButton = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  flex: 1 0 auto;
  min-width: 92px;
  height: 32px;
  background: ${({ selected }) => (selected ? '#000000' : '#FFFFFF')};
  border: 1px solid #e0e0e0;
  border-radius: 99px;

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'ss06' on;
  color: ${({ selected }) => (selected ? '#FFFFFF' : '#3D3D3D')};
`;
export default BlackButton;
