import React, { useContext, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { routingPaths } from '../../common/constants';
import { mpTrackEnterPage } from '../../common/mixPanel';
import LoadingScreen from '../../components/LoadingScreen';
import { LoginContext } from './LoginContext';

interface PrivateRouteProps {
  authenticated: boolean;
  fmsAuthenticated: boolean;
  path: string;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ authenticated, fmsAuthenticated, path, children }) => {
  const { setUrl } = useContext(LoginContext);
  useEffect(() => {
    switch (path) {
      case routingPaths.home:
        document.title = '共享厨房';
        break;
      case routingPaths.login:
        document.title = '登录';
        break;
      case routingPaths.contractSign:
        document.title = '合同签约';
        break;
      case routingPaths.contractDetail:
        document.title = '合同详情';
        break;
      case routingPaths.previewContract:
        document.title = '合同预览';
        break;
      case routingPaths.contractBasicDetail:
        document.title = '合同详情';
        break;
      case routingPaths.monitorDetail:
        document.title = '我的监控';
        break;
      case routingPaths.monitorReplay:
        document.title = '监控回放';
        break;
      case routingPaths.bill:
        document.title = '我的账单';
        break;
      case routingPaths.utilitiesHistory:
        document.title = '电费记录';
        break;
      case routingPaths.utilities:
        document.title = '电费充值';
        break;
      case routingPaths.account:
        document.title = '我的余额';
        break;
      case routingPaths.pay:
        document.title = '付款';
        break;
      case routingPaths.offlinePay:
        document.title = '线下付款';
        break;
      case routingPaths.paySuccess:
        document.title = '支付成功';
        break;
      case routingPaths.payFail:
        document.title = '支付失败';
        break;
      default:
        document.title = '共享厨房';
        break;
    }
    mpTrackEnterPage(path.replace(/\/:\w+/, '').replace(/\//, '_'));
  }, [path]);

  if (!authenticated) {
    const url = window.location.pathname + window.location.search;
    setUrl(url);
    return <Redirect to={routingPaths.login} />;
  } else if (!fmsAuthenticated) {
    return <LoadingScreen />;
  } else {
    return <Route path={path}>{children}</Route>;
  }
};
