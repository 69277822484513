import React from 'react';
import styled from 'styled-components';

import { Overlay } from '../components/op-components';

interface MobOverlayDialogProps {
  onClose: () => void;
}

export const MobOverlayDialog: React.FC<MobOverlayDialogProps> = ({ onClose, children }) => {
  return (
    <Overlay>
      <WrapperDiv>
        <NonContentDiv onClick={onClose} />
        <ContentDiv>{children}</ContentDiv>
      </WrapperDiv>
    </Overlay>
  );
};

const WrapperDiv = styled.div`
  width: 100vw;
  height: ${() => window.innerHeight}px;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
`;

const NonContentDiv = styled.div`
  min-height: 1px;
  width: 100%;
  flex: auto;
`;

const ContentDiv = styled.div`
  width: 100%;
  background: #ffffff;
  font-family: 'PingFang SC';
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

export const MobOverlayDialogHeaderBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const MobOverlayDialogBody = styled.div`
  overflow: hidden;
`;

export const YScrollableContainer = styled.div`
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 10px 0;
`;
