import React from 'react';
import styled from 'styled-components';

import { Spinner, zIndex, Overlay } from './op-components';

const LoadingDialog: React.FC = () => {
  return (
    <Overlay>
      <StyledWrapper>
        <SpinnerWrapper>
          <Spinner size="small" />
        </SpinnerWrapper>
      </StyledWrapper>
    </Overlay>
  );
};

export default LoadingDialog;

const StyledWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${zIndex('notifications')};
`;

const SpinnerWrapper = styled.div`
  width: 50%;
  height: 0;
  margin: 0 auto;
  padding: 22% 0 22% 0;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
