const CloseWhiteIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_997_19357)">
        <path
          d="M15.8332 5.34163L14.6582 4.16663L9.99984 8.82496L5.3415 4.16663L4.1665 5.34163L8.82484 9.99996L4.1665 14.6583L5.3415 15.8333L9.99984 11.175L14.6582 15.8333L15.8332 14.6583L11.1748 9.99996L15.8332 5.34163Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_997_19357">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CloseWhiteIcon;
