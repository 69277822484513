import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import { SimpleContract } from '../../../models/contract';
import { BillDetailDialog } from '../../bill/BillDetailDialog';
import SignContractCard from './SignContractCard';
import SignContractHead from './SignContractHead';

interface ContractSignPageProps {
  contractList: SimpleContract[];
}

const ContractSignPage: React.FC<ContractSignPageProps> = ({ contractList }) => {
  const [contractId, setContractId] = useState<string>('');
  const [billDetailVisible, setBillDetailVisible] = useState<boolean>(false);

  const showBillDetail = useCallback(
    (id: string) => {
      setContractId(id);
      setBillDetailVisible(true);
    },
    [setContractId, setBillDetailVisible]
  );

  return (
    <StyledWrapper>
      <SignContractHead title={'“人间烟火'} subtitle={'入驻餐饮新旅程'} />
      <ContractListWrapper>
        <ContractList>
          {contractList.map((contract: SimpleContract, index: number) => (
            <SignContractCard
              key={index}
              contract={contract}
              narrow={contractList.length > 1}
              lastElement={contractList.length === index + 1}
              showBillDetail={() => showBillDetail(contract.contractId)}
            />
          ))}
        </ContractList>
      </ContractListWrapper>
      {billDetailVisible && <BillDetailDialog contractId={contractId} onClose={() => setBillDetailVisible(false)} />}
    </StyledWrapper>
  );
};

export default ContractSignPage;

const StyledWrapper = styled.div`
  width: 100vw;
  height: 100%;
`;

const ContractListWrapper = styled.div`
  width: 100%;
  margin-top: -80px;
`;

const ContractList = styled.div`
  width: 100%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
`;
