import React from 'react';
import styled from 'styled-components';

import { CullinanFilledQuestionMark, CullinanFilledQuestionMarkHover } from '../../../../op-icons';

import { HoverableIcon } from '../../atoms/HoverableIcon';
import { PopoverProps } from '../Popover';
import { TooltipContainer, TooltipPopover, TooltipText } from './styles';

export interface TooltipProps {
  children?: React.ReactNode;
  className?: string;
  tooltipText?: string | string[] | JSX.Element;
  width?: number;
  height?: number;
  dataTest?: string;
  // Allows styling of the Popover Trigger
  triggerStyle?: PopoverProps['triggerStyle'];
  placement?: PopoverProps['placement'];
  // whether to inverse the background
  inverse?: boolean;
}

// Popover sets 'inline-block'; but that causes undesirable alignment
const TooltipIndicator = styled(HoverableIcon)`
  display: block;
`;

/**
 * A simple tooltip with a (?) icon which shows a tooltip text on hover.
 *
 */
export const Tooltip: React.FC<TooltipProps> = ({
  className,
  tooltipText,
  width = 20,
  height = 20,
  triggerStyle,
  dataTest = 'op-tooltip',
  placement = 'auto',
  children,
  inverse = false,
}) => {
  if (!tooltipText) {
    return <>{children}</>;
  }
  return (
    <TooltipPopover
      dataTest={dataTest}
      placement={placement}
      triggerStyle={triggerStyle}
      content={
        <TooltipContainer align={Array.isArray(tooltipText) ? 'left' : 'center'} inverse={inverse}>
          {Array.isArray(tooltipText) ? (
            tooltipText.map((text) => (
              <TooltipText inverse={inverse} key={text}>
                {text}
              </TooltipText>
            ))
          ) : (
            <TooltipText inverse={inverse}>{tooltipText}</TooltipText>
          )}
        </TooltipContainer>
      }
    >
      {children ?? (
        <TooltipIndicator
          className={className}
          primary={<CullinanFilledQuestionMark height={height} width={width} />}
          hovered={<CullinanFilledQuestionMarkHover height={height} width={width} />}
        />
      )}
    </TooltipPopover>
  );
};
