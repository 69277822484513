import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { BottomButtonGroup } from '../../../components/BottomButtonGroup';
import { FmsContext } from '../../../context/FmsContext';
import { ContractEntityType, Pair } from '../../../models/contract';
import BasicRow from './BasicRow';
import BasicTitle from './BasicTitle';

export const ContractBasicPage: React.FC = () => {
  const history = useHistory();
  const {
    state: { contract },
  } = useContext(FmsContext);
  return (
    <StyledWrapper>
      <BasicTitle title={'基本信息'} />
      <BasicRow name={'合同编号'} value={contract.basic.code} />
      <BasicRow name={'合同类型'} value={contract.basic.signType || ''} />
      <BasicRow name={'门店名称'} value={contract.basic.facilityName} />
      <BasicRow name={'门店编号'} value={contract.basic.facilityCode} />
      <BasicRow name={'档口编号'} value={contract.basic.kitchenCode} />
      <BasicRow name={'签约主体类型'} value={contract.basic.entityTypeName} />
      {contract.basic.entityType === ContractEntityType.PERSONAL ? (
        <>
          <BasicRow name={'签约客户主体'} value={contract.basic.entity || ''} />
          <BasicRow name={'签约客户身份证号'} value={contract.basic.idCard || ''} />
        </>
      ) : (
        <>
          <BasicRow name={'签约客户主体'} value={contract.basic.entity || ''} />
          <BasicRow name={'签约企业公司地址'} value={contract.basic.comAddr || ''} />
          <BasicRow name={'联系地址'} value={contract.basic?.linkAddr || ''} />
        </>
      )}
      <BasicTitle title={'联系人信息'} />
      <BasicRow name={'联系人姓名'} value={contract.linkman?.name || ''} />
      <BasicRow name={'联系人电话'} value={contract.linkman?.phone || ''} />
      <BasicRow name={'联系电子邮箱'} value={contract.linkman?.email || ''} />
      <BasicTitle title={'收款信息'} />
      <BasicRow name={'账户名称'} value={contract.bank?.name || ''} />
      <BasicRow name={'开户行'} value={contract.bank?.branch || ''} />
      <BasicRow name={'账号'} value={contract.bank?.account || ''} />
      <BasicTitle title={'合同信息'} />
      <BasicRow name={'服务期限'} value={contract.contract?.servicePeriod || ''} />
      <BasicRow name={'计费开始日'} value={contract.contract?.actualRentalDate || ''} />
      <BasicTitle title={'场地信息'} />
      <BasicRow name={'场地区域'} value={contract.kitchen?.address || ''} />
      <BasicRow name={'建筑面积'} value={contract.kitchen?.totalArea || ''} />
      <BasicRow name={'档口电量'} value={contract.kitchen?.electricity || ''} />
      <BasicRow name={'档口排烟量'} value={contract.kitchen?.exhaust || ''} />
      <BasicRow name={'档口燃气量'} value={contract.kitchen?.gas || ''} />
      <BasicTitle title={'经营信息'} />
      <BasicRow name={'经营类型'} value={contract.operation?.kitchenTypeName || ''} />
      <BasicRow name={'拟经营品牌'} value={contract.operation?.brandName || ''} />
      <BasicRow name={'拟经营品类'} value={contract.operation?.cuisineTypeName || ''} />
      <BasicRow name={'拟店铺名称'} value={contract.operation?.storeName || ''} />
      <BasicTitle title={'费用信息'} />
      <BasicRow name={'入会费'} value={contract.fee?.entry || ''} />
      <BasicRow name={'基础服务费'} value={contract.fee?.rent || ''} />
      <BasicRow name={'场地使用服务费'} value={contract.fee?.siteUseServiceFee || ''} />
      <BasicRow name={'品类保护费'} value={contract.fee?.protect || ''} />
      <BasicRow name={'履约保证金'} value={contract.fee?.deposit || ''} />
      <BasicRow name={'质量保证金'} value={contract.fee?.quality || ''} />
      <BasicRow name={'证照注销保证金'} value={contract.fee?.cardCancelDeposit || ''} />
      <BasicRow name={'档口燃气量'} value={contract.fee?.cardRegFee || ''} />
      <BasicTitle title={'公共服务'} />
      {contract.pubCost?.map((cost: Pair, index: number) => (
        <BasicRow name={cost.key} key={index} value={cost.value || ''} />
      ))}
      <BasicTitle title={'自选服务'} />
      {contract.custom?.map((cost: Pair, index: number) => (
        <BasicRow name={cost.key} key={index} value={cost.value || ''} />
      ))}
      <BasicGapWrapper />
      <BottomButtonGroup
        primary={{ text: '返回', onClick: () => history.push(`/contract/${contract.basic.contractId}`) }}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const BasicGapWrapper = styled.div`
  height: 100px;
`;
