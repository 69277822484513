import { mpTrack } from '../common/mixPanel';
import { CssUser } from '../models/user';

export const CSS_TOKEN_STORAGE_KEY = 'css.authtoken';

export const CSS_USER_STORAGE_KEY = 'css.user';

export function getCssTokenFromLocalStorage(key: string = CSS_TOKEN_STORAGE_KEY): any {
  // Get from local storage by key
  const item = localStorage.getItem(key);
  try {
    // Parse stored json or if none return initialValue
    return item?.startsWith('"') ? JSON.parse(item) : item;
  } catch (error) {
    mpTrack('error', { name: 'getCssTokenFromLocalStorage', error: error });
    return null;
  }
}

export function clearCssTokenInLocalStorage(): void {
  localStorage.removeItem(CSS_TOKEN_STORAGE_KEY);
}

export function setCssTokenInLocalStorage(token: string): void {
  localStorage.setItem(CSS_TOKEN_STORAGE_KEY, JSON.stringify(token));
}

export const getCssUserIdFromStorage = (key: string = CSS_USER_STORAGE_KEY): string => {
  const value = localStorage.getItem(key);
  if (!value) {
    return '';
  }
  try {
    const data: CssUser = JSON.parse(value);
    return data.id;
  } catch (error) {
    mpTrack('error', { name: 'getCssUserIdFromStorage', error: error });
    return '';
  }
};
