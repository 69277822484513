import { system } from 'styled-system';

export const columnGap = system({
  columnGap: { property: 'columnGap', scale: 'space' },
});

export const gap = system({
  gap: { property: 'gap', scale: 'space' },
});

export const rowGap = system({
  rowGap: { property: 'rowGap', scale: 'space' },
});
