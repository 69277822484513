import { useMemo } from 'react';
import styled from 'styled-components';

import { mpTrack } from '../common/mixPanel';

interface ButtonProps {
  text: string;
  onClick?: () => void;
  /**
   * Action name which will be recorded in mix panel
   */
  mpTrack?: string | { name: string; params?: { [key: string]: string | boolean | number | undefined } };
}

interface OrangeBottomButtonGroupProps {
  primary: ButtonProps;
  secondary?: ButtonProps;
}

const genButOnClickFunc = (props?: ButtonProps) => {
  if (!props) {
    return undefined;
  }
  console.log(props);
  return () => {
    props.onClick && props.onClick();
    if (props.mpTrack) {
      if (typeof props.mpTrack === 'string') {
        mpTrack(props.mpTrack);
      } else {
        mpTrack(props.mpTrack.name, props.mpTrack.params);
      }
    }
  };
};

export const OrangeBottomButtonGroup: React.FC<OrangeBottomButtonGroupProps> = ({ primary, secondary }) => {
  const primaryOnclick = useMemo(() => genButOnClickFunc(primary), [primary]);
  const secondaryOnclick = useMemo(() => genButOnClickFunc(secondary), [secondary]);
  return (
    <BottomButtonWrapper>
      <PrimaryButton onClick={primaryOnclick}>{primary.text}</PrimaryButton>
      {secondary && (
        <>
          <SpaceBetween />
          <SecondaryButton onClick={secondaryOnclick}>{secondary.text}</SecondaryButton>
        </>
      )}
    </BottomButtonWrapper>
  );
};

const BottomButtonWrapper = styled.div`
  margin: 24px 20px 24px 20px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'ss06' on;
`;

const PrimaryButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  min-width: 150px;
  height: 44px;
  background: #e06e4d;
  border-radius: 4px;
  color: #ffffff;
`;

const SpaceBetween = styled.div`
  display: block;
  height: 10px;
  width: 10px;
`;

const SecondaryButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  min-width: 150px;
  height: 44px;
  background: rgba(0, 0, 0, 0.0001);
  border-radius: 4px;
  color: #000000;
`;
