const RestaurantIcon: React.FC = () => {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.1376 7.50125L21.9563 2.585C21.7088 1.5725 20.8313 0.875 19.8076 0.875H4.18132C3.16882 0.875 2.28007 1.58375 2.04382 2.585L0.862568 7.50125C0.592568 8.64875 0.840069 9.81875 1.56007 10.7413C1.65007 10.865 1.77382 10.955 1.87507 11.0675V18.875C1.87507 20.1125 2.88757 21.125 4.12507 21.125H19.8751C21.1126 21.125 22.1251 20.1125 22.1251 18.875V11.0675C22.2263 10.9663 22.3501 10.865 22.4401 10.7525C23.1601 9.83 23.4188 8.64875 23.1376 7.50125ZM19.7738 3.11375L20.9551 8.03C21.0676 8.5025 20.9663 8.975 20.6738 9.34625C20.5163 9.54875 20.1788 9.875 19.6163 9.875C18.9301 9.875 18.3338 9.32375 18.2551 8.5925L17.6026 3.125L19.7738 3.11375ZM13.1251 3.125H15.3301L15.9376 8.21C15.9938 8.64875 15.8588 9.0875 15.5663 9.41375C15.3188 9.70625 14.9588 9.875 14.4976 9.875C13.7438 9.875 13.1251 9.21125 13.1251 8.40125V3.125ZM8.05132 8.21L8.67007 3.125H10.8751V8.40125C10.8751 9.21125 10.2563 9.875 9.42382 9.875C9.04132 9.875 8.69257 9.70625 8.42257 9.41375C8.14132 9.0875 8.00632 8.64875 8.05132 8.21ZM3.04507 8.03L4.18132 3.125H6.39757L5.74507 8.5925C5.65507 9.32375 5.07007 9.875 4.38382 9.875C3.83257 9.875 3.48382 9.54875 3.33757 9.34625C3.03382 8.98625 2.93257 8.5025 3.04507 8.03ZM4.12507 18.875V12.0913C4.21507 12.1025 4.29382 12.125 4.38382 12.125C5.36257 12.125 6.25132 11.72 6.90382 11.0563C7.57882 11.7312 8.47882 12.125 9.50257 12.125C10.4813 12.125 11.3588 11.72 12.0113 11.0787C12.6751 11.72 13.5751 12.125 14.5876 12.125C15.5326 12.125 16.4326 11.7312 17.1076 11.0563C17.7601 11.72 18.6488 12.125 19.6276 12.125C19.7176 12.125 19.7963 12.1025 19.8863 12.0913V18.875H4.12507Z"
        fill="white"
      />
    </svg>
  );
};

export default RestaurantIcon;
