export const AliPayIcon: React.FC = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill="#1677FF"
      />
      <mask
        id="mask0_545_61062"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
          fill="#EE9502"
        />
      </mask>
      <g mask="url(#mask0_545_61062)">
        <path
          d="M24.7235 22.5234C26.6203 19.1362 27.2977 15.8846 27.2977 15.8846H26.9591H24.0462H20.9979V13.5815H28.4494V12.5653H20.9979V8.97498H17.6107V12.5653H10.8365V13.5815H17.6107V15.9524H11.7849V16.9685H23.5042C23.5042 17.1717 23.5042 17.1717 23.301 17.3071C23.301 18.5266 22.4205 20.2201 21.743 21.575C13.072 18.1878 10.5655 20.2202 9.88808 20.5588C4.13008 24.6234 9.54944 29.704 10.4301 29.5686C16.5269 30.9233 20.4558 28.3491 23.1655 25.1653C23.3688 25.3686 23.5042 25.3686 23.7074 25.3686C25.6042 26.3847 34.5462 30.6523 34.5462 30.6523V25.5718C33.1913 25.4362 28.2461 23.7426 24.7235 22.5234ZM20.3204 24.0813C16.0527 29.5007 10.972 27.8072 10.1592 27.4685C8.12689 26.9265 7.44944 23.2008 9.95595 22.0491C14.2236 20.6943 17.9495 22.2523 20.6591 23.6072C20.4558 23.8781 20.3204 24.0813 20.3204 24.0813Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export const WechatPayIcon: React.FC = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill="#09BB07"
      />
      <mask
        id="mask0_598_67252"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
          fill="#EE9502"
        />
      </mask>
      <g mask="url(#mask0_598_67252)">
        <path
          d="M16.3841 22.866C16.269 22.9241 16.1512 22.9519 16.0082 22.9519C15.6884 22.9519 15.4275 22.7802 15.2816 22.5227L15.2228 22.4094L12.9311 17.4827C12.903 17.4246 12.903 17.3693 12.903 17.3112C12.903 17.0815 13.077 16.9098 13.3098 16.9098C13.3968 16.9098 13.4837 16.9375 13.5707 16.9956L16.269 18.886C16.471 18.9994 16.7038 19.0853 16.9646 19.0853C17.1105 19.0853 17.2536 19.0575 17.3995 18.9994L30.0528 13.4446C27.7892 10.8097 24.0472 9.09094 19.8089 9.09094C12.903 9.09094 7.27344 13.702 7.27344 19.3981C7.27344 22.4896 8.95644 25.296 11.596 27.1865C11.7978 27.3304 11.9437 27.5878 11.9437 27.8451C11.9437 27.931 11.9156 28.0168 11.8849 28.1026C11.683 28.8748 11.3323 30.1369 11.3323 30.1923C11.3042 30.278 11.2733 30.3916 11.2733 30.5077C11.2733 30.7375 11.4472 30.9091 11.6801 30.9091C11.767 30.9091 11.854 30.8814 11.9129 30.8233L14.6393 29.2485C14.8413 29.1349 15.0741 29.0491 15.307 29.0491C15.4219 29.0491 15.5678 29.0768 15.6828 29.1072C16.959 29.4782 18.3532 29.6803 19.7752 29.6803C26.6812 29.6803 32.3107 25.0692 32.3107 19.3731C32.3107 17.6543 31.789 16.0241 30.8886 14.5905L16.471 22.8079L16.3841 22.866V22.866Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
