import styled from 'styled-components';
import React, { useMemo, useEffect } from 'react';
import ErrorWhiteIcon from './icon/ErrorWhiteIcon';
import SuccessIcon from './icon/SuccessIcon';
import { zIndex } from './op-components';

export type ToastType = 'success' | 'error';

export interface ToastMessage {
  type: ToastType;
  message?: string;
}

interface ToastProps {
  type: ToastType;
  visible: boolean;
  message: string;
  onHide?: () => void;
  duration?: number;
}

const Toast: React.FC<ToastProps> = ({ type, visible, message, duration = 3000, onHide = () => {} }) => {
  const icon = useMemo(() => {
    if (type === 'success') {
      return <SuccessIcon />;
    } else if (type === 'error') {
      return <ErrorWhiteIcon />;
    }
  }, [type]);

  useEffect(() => {
    const timerId = setTimeout(onHide, duration);
    return () => {
      clearTimeout(timerId);
    };
  }, [onHide, duration]);

  return visible ? (
    <MaskWrapper>
      <StyledWrapper type={type}>
        <IconWrapper>{icon}</IconWrapper>
        <Message type={type}>{message}</Message>
      </StyledWrapper>
    </MaskWrapper>
  ) : null;
};

export default Toast;

const MaskWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: ${zIndex('notifications')};
`;

const StyledWrapper = styled.div<{ type: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 16px 20px 16px;
  height: 48px;
  border-radius: 4px;

  ${({ type }) => {
    switch (type) {
      case 'success':
        return `
 background: #FFFFFF;
 box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.05);
        `;
      case 'error':
        return `
  background: rgba(218, 37, 47, 0.9);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.05);
        `;
      default:
        return ``;
    }
  }}
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 17px;
`;

const Message = styled.div<{ type: string }>`
  margin-left: 14px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  ${({ type }) => {
    switch (type) {
      case 'success':
        return `
  color: #525252;
        `;
      case 'error':
        return `
  color: #FFFFFF;
        `;
      default:
        return ``;
    }
  }}
`;
