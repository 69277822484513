import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import { Input } from '../../components/op-components';
import { Close, Email } from '../../components/op-icons';

import { BottomButtonGroup } from '../../components/BottomButtonGroup';
import { Dialog } from '../../components/Dialog';
import ErrorRedIcon from '../../components/icon/ErrorRedIcon';

interface CustomRechargeDialogProps {
  title: string;
  amount: number;
  modifyCustomRechargeAmount(amount: number): void;
  onClose: () => void;
}

const amountPattern = /^(\d+\.?)?\d{0,2}$/;

export const CustomRechargeDialog: React.FC<CustomRechargeDialogProps> = ({
  title,
  amount,
  modifyCustomRechargeAmount,
  onClose,
}) => {
  const [customRechargeAmount, setCustomRechargeAmount] = useState<string>(amount.toString());
  const [error, setError] = useState<string>();

  const submitAmount = useCallback(() => {
    if (!amountPattern.test(customRechargeAmount)) {
      setError('请输入正确的金额');
      return;
    }
    if (!customRechargeAmount) {
      modifyCustomRechargeAmount(0);
    } else {
      modifyCustomRechargeAmount(Number.parseFloat(customRechargeAmount));
    }
    setError('');
    onClose();
  }, [modifyCustomRechargeAmount, customRechargeAmount, setError, onClose]);

  return (
    <Dialog title={title} onClose={onClose}>
      <InputWrapper>
        <AmountInput
          intent={error ? 'error' : undefined}
          value={customRechargeAmount}
          onChange={(e: { target: { value: React.SetStateAction<string> } }) => setCustomRechargeAmount(e.target.value)}
          placeholder={'请输入充值金额'}
          prefix={<Email />}
          suffix={customRechargeAmount ? <Close onClick={() => setCustomRechargeAmount('')} /> : undefined}
        />
      </InputWrapper>
      <StyledTipWrapper>
        {error && (
          <>
            <ErrorRedIcon />
            <StyledError>{error}</StyledError>
          </>
        )}
      </StyledTipWrapper>
      <BottomButtonGroup
        primary={{
          text: '确认',
          onClick: submitAmount,
        }}
        secondary={{
          text: '取消',
          onClick: onClose,
        }}
      />
    </Dialog>
  );
};

const InputWrapper = styled.div`
  width: calc(100% - 32px);
  margin-top: 40px;
  margin-left: 16px;
`;

const AmountInput = styled(Input)`
  background: rgba(0, 0, 0, 0.0001);
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  box-sizing: border-box;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

const StyledTipWrapper = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: row;
  height: 40px;
  padding-top: 9px;
`;

const StyledError = styled.div`
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #c4232b;
`;
