import styled, { CSSProperties } from 'styled-components';

import { borderRadius, color, margin, padding } from '../../../theme';
import { textColor, fontSize, fontWeight, lineHeight } from '../../atoms/Text';
import { Popover } from '../Popover';

export const TooltipPopover = styled(Popover).attrs({ triggerMode: 'hover', isArrowHidden: true })``;

export const TooltipContainer = styled.div<{ align: CSSProperties['textAlign']; inverse: boolean }>`
  ${padding.x('$3')}
  ${padding.y('$2')}
  ${borderRadius('lg')}
  ${({ inverse }) => (inverse ? color.bg('white') : color.bg('greyDark'))}
  max-width: ${(props) => props.theme.space.$64};
  text-align: ${(props) => props.align};
  margin: 0;
`;

export const TooltipText = styled.p<{ inverse: boolean }>`
  ${fontSize('micro')}
  ${fontWeight('normal')}
  ${lineHeight('snug')}
  ${({ inverse }) => (inverse ? textColor('primary') : textColor('inverse'))}

  & + & {
    ${margin.t('$2')}
  }
`;
