import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import OrangeButton from '../../../components/OrangeButton';
import WhiteButton from '../../../components/WhiteButton';
import FlashLogo from '../../../components/icon/FlashLogo';
import LocationIcon from '../../../components/icon/LocationIcon';
import RestaurantIcon from '../../../components/icon/RestaurantIcon';
import { SimpleContract } from '../../../models/contract';

interface SignContractCardProps {
  contract: SimpleContract;
  narrow: boolean;
  lastElement: boolean;
  showBillDetail: () => void;
}

const SignContractCard: React.FC<SignContractCardProps> = ({ contract, narrow, lastElement, showBillDetail }) => {
  const history = useHistory();
  return (
    <StyledWrapper narrow={narrow} lastElement={lastElement}>
      <BoxWrapper>
        <LogoWrapper>
          <RestaurantWrapper>
            <RestaurantIcon />
          </RestaurantWrapper>
          <FlashWrapper>
            <FlashLogo />
          </FlashWrapper>
        </LogoWrapper>
        <StoreName>
          {contract.kitchenCode} {contract.storeName}
        </StoreName>
        <ContractCode>{contract.code}</ContractCode>
        <LocationWrapper>
          <LocationIcon />
          <Location>
            {contract.facilityName} {contract.facilityCode}
          </Location>
        </LocationWrapper>
      </BoxWrapper>
      <ButtonWrapper>
        <PaymentButton onClick={showBillDetail}>{'现在支付'}</PaymentButton>
        <SignButton onClick={() => history.push(`/contract/${contract.contractId}`)}>{'现在签约'}</SignButton>
      </ButtonWrapper>
    </StyledWrapper>
  );
};

export default SignContractCard;

const StyledWrapper = styled.div<{ narrow: boolean; lastElement: boolean }>`
  width: ${({ narrow }) => (narrow ? 80 : 100)}%;
  height: 313px;
  margin: 0px ${({ lastElement }) => (lastElement ? 16 : 0)}px 26px 16px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 2px 2px rgba(0, 0, 0, 0.1),
    0px 0px 8px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
`;

const BoxWrapper = styled.div`
  margin: 24px 20px 28px 20px;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const RestaurantWrapper = styled.div`
  display: flex;
  width: 54px;
  height: 54px;
  background: #141414;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
`;

const FlashWrapper = styled.div`
  margin-top: -5px;
`;

const ContractCode = styled.div`
  margin-top: 8px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8f8f8f;
`;

const StoreName = styled.div`
  margin-top: 23px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: #000000;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const LocationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
`;

const Location = styled.div`
  margin-left: 6px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #525252;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 28px 16px 0px 16px;
`;

const PaymentButton = styled(WhiteButton)``;

const SignButton = styled(OrangeButton)`
  margin-left: 10px;
`;
