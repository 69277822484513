import { useContext } from 'react';

import { BreakpointMap, BreakpointVariant } from '../components/types';
import { BreakpointMinWidths } from '../theme/constants';
import { UIContext } from '../theme/context/ThemeProvider';

// const validBreakpointsById: Record<BreakpointVariant, BreakpointVariant[]> = {
//   $12: ['$12', '$11', '$10', '$9', '$8', '$7', '$6', '$5', '$4', '$3', '$2', '$1', '$0'],
//   $11: ['$11', '$10', '$9', '$8', '$7', '$6', '$5', '$4', '$3', '$2', '$1', '$0'],
//   $10: ['$10', '$9', '$8', '$7', '$6', '$5', '$4', '$3', '$2', '$1', '$0'],
//   $9: ['$9', '$8', '$7', '$6', '$5', '$4', '$3', '$2', '$1', '$0'],
//   $8: ['$8', '$7', '$6', '$5', '$4', '$3', '$2', '$1', '$0'],
//   $7: ['$7', '$6', '$5', '$4', '$3', '$2', '$1', '$0'],
//   $6: ['$6', '$5', '$4', '$3', '$2', '$1', '$0'],
//   $5: ['$5', '$4', '$3', '$2', '$1', '$0'],
//   $4: ['$4', '$3', '$2', '$1', '$0'],
//   $3: ['$3', '$2', '$1', '$0'],
//   $2: ['$2', '$1', '$0'],
//   $1: ['$1', '$0'],
//   $0: ['$0'],
// };
const reverseOrderedBreakpoints = Object.entries(BreakpointMinWidths)
  .sort(([, bpSize1], [, bpSize2]) => bpSize2 - bpSize1)
  .map((entry) => entry[0] as BreakpointVariant);
const validBreakpointsById = reverseOrderedBreakpoints.reduce((map, bp, index) => {
  map[bp] = reverseOrderedBreakpoints.slice(index);
  return map;
}, {} as Record<BreakpointVariant, BreakpointVariant[]>);

/**
 * @deprecated Use `useResponsive` from `@css/csds-components`
 */
export const useBreakpointValue = <T>(defaultValue: T | undefined, breakpointValues: Partial<BreakpointMap<T>>) => {
  const { breakpoint: currentBreakpoint } = useContext(UIContext);

  if (currentBreakpoint === undefined) {
    return defaultValue;
  }

  for (const validBp of validBreakpointsById[currentBreakpoint]) {
    if (breakpointValues[validBp] !== undefined) {
      return breakpointValues[validBp];
    }
  }

  return defaultValue;
};
