const ActionFailIcon: React.FC = () => {
  return (
    <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="36" height="36" rx="18" fill="#DA252F" fillOpacity="0.16" />
      <path
        d="M23.75 13.8075L22.6925 12.75L18.5 16.9425L14.3075 12.75L13.25 13.8075L17.4425 18L13.25 22.1925L14.3075 23.25L18.5 19.0575L22.6925 23.25L23.75 22.1925L19.5575 18L23.75 13.8075Z"
        fill="#DA252F"
      />
    </svg>
  );
};
export default ActionFailIcon;
