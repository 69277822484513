import { useEffect, useRef } from 'react';

export const useTimeout = (callback: () => void, delay?: number) => {
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    timer.current = setTimeout(() => savedCallback.current(), delay);

    return () => {
      if (timer.current !== undefined) {
        clearTimeout(timer.current);
      }
    };
  }, [delay]);

  return timer.current;
};
