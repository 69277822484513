import { fmsFetchData, fmsFetchDataList, utilitiesApiRequest } from '../common/fmsApiRequest';
import {
  LicenseeBalanceAccount,
  LicenseeBalanceAccountHistory,
  UtilitiesBalanceAccount,
  UtilitiesBalanceAccountHistory,
} from '../models/account';
import { SimpleContract } from '../models/contract';

export const getLicenseeBalanceAccountList = async (facilityId?: string): Promise<SimpleContract[]> => {
  return fmsFetchDataList<SimpleContract>({
    url: `/merchant_css/balance/contract/list`,
    method: 'GET',
    params: { facilityId: facilityId ? facilityId : '' },
  });
};

export const getLicenseeBalanceAccountDetail = async (contractId: string): Promise<LicenseeBalanceAccount> => {
  return fmsFetchData<LicenseeBalanceAccount>({
    url: `/merchant_css/balance/getAccountDetail`,
    method: 'GET',
    params: { contractId },
  });
};

export const getLicenseeBalanceAccountHistoryList = async (
  contractId: string
): Promise<LicenseeBalanceAccountHistory[]> => {
  return fmsFetchDataList<LicenseeBalanceAccountHistory>({
    url: `/merchant_css/balance/getAccountOperateHistory`,
    method: 'GET',
    params: { contractId: contractId, page: 0, rows: 1000 },
  });
};

export const getUtilitiesBalanceAccountList = async (
  ids: string[],
  type: string
): Promise<UtilitiesBalanceAccount[]> => {
  return utilitiesApiRequest<{ accounts: UtilitiesBalanceAccount[] }>({
    url: `/fms_css/utilities/prepay/balance/account`,
    method: 'GET',
    params: { ids: ids, type: type },
  }).then((res: { accounts: UtilitiesBalanceAccount[] }) => res.accounts);
};

export const getUtilitiesBalanceChangeLogs = async (
  accountId: string,
  startTime: string,
  endTime: string
): Promise<UtilitiesBalanceAccountHistory[]> => {
  return utilitiesApiRequest<{ logs: UtilitiesBalanceAccountHistory[] }>({
    url: `/fms_css/utilities/prepay/balance/changelogs`,
    method: 'GET',
    params: {
      account_id: accountId,
      start_time: startTime,
      end_time: endTime,
      page: 1,
      page_size: 10000,
    },
  }).then((res: { logs: UtilitiesBalanceAccountHistory[] }) => res.logs);
};
