const PlayIcon: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.9998 0.666687C11.1198 0.666687 0.666504 11.12 0.666504 24C0.666504 36.88 11.1198 47.3334 23.9998 47.3334C36.8798 47.3334 47.3332 36.88 47.3332 24C47.3332 11.12 36.8798 0.666687 23.9998 0.666687ZM19.3332 34.5V13.5L33.3332 24L19.3332 34.5Z"
        fill="white"
      />
    </svg>
  );
};
export default PlayIcon;
