import React from 'react';
import styled from 'styled-components';

import { MobOverlayDialog, MobOverlayDialogHeaderBar, MobOverlayDialogBody } from './MobOverlayDialog';
import CloseBlackIcon from './icon/CloseBlackIcon';

interface DialogProps {
  title: string;
  onClose: () => void;
}

export const Dialog: React.FC<DialogProps> = ({ title, onClose, children }) => {
  return (
    <MobOverlayDialog onClose={onClose}>
      <DialogHead>
        <HeadLine />
        <HeadWrapper>
          <HeadTitle>{title}</HeadTitle>
          <CloseWrapper onClick={onClose}>
            <CloseBlackIcon />
          </CloseWrapper>
        </HeadWrapper>
      </DialogHead>
      <DialogBody>{children}</DialogBody>
    </MobOverlayDialog>
  );
};

const HeadLine = styled.div`
  margin-top: 12px;
  width: 10%;
  height: 0px;
  border: 2px solid rgba(0, 0, 0, 0.12);
`;

const CloseWrapper = styled.div`
  margin-left: auto;
  margin-right: 20px;
`;

const HeadWrapper = styled.div`
  width: 100%;
  margin: 16px 20px 16px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const HeadTitle = styled.div`
  margin-left: 16px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  font-feature-settings: 'ss06' on;
  color: #000000;
`;

const DialogBody = styled(MobOverlayDialogBody)`
  width: 100%;
  min-height: 200px;
`;

const DialogHead = styled(MobOverlayDialogHeaderBar)``;
