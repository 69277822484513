const CameraIcon: React.FC = () => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.125 3.875H13.7475L12.375 2.375H7.875V3.875H11.715L13.0875 5.375H16.125V14.375H4.125V7.625H2.625V14.375C2.625 15.2 3.3 15.875 4.125 15.875H16.125C16.95 15.875 17.625 15.2 17.625 14.375V5.375C17.625 4.55 16.95 3.875 16.125 3.875ZM6.375 9.875C6.375 11.945 8.055 13.625 10.125 13.625C12.195 13.625 13.875 11.945 13.875 9.875C13.875 7.805 12.195 6.125 10.125 6.125C8.055 6.125 6.375 7.805 6.375 9.875ZM10.125 7.625C11.3625 7.625 12.375 8.6375 12.375 9.875C12.375 11.1125 11.3625 12.125 10.125 12.125C8.8875 12.125 7.875 11.1125 7.875 9.875C7.875 8.6375 8.8875 7.625 10.125 7.625ZM4.125 3.875H6.375V2.375H4.125V0.125H2.625V2.375H0.375V3.875H2.625V6.125H4.125V3.875Z"
        fill="#E06E4D"
      />
    </svg>
  );
};
export default CameraIcon;
